import { Base } from "./base.class";
import { Product } from "./product.class";
import { QuotationFeeRowEnum } from "./quotation.class";

export class Fee extends Base {
    
    
    // product: Product;

    // product_id: string;

    // year: number;

    // value: number;

    // qta: number;

    // selected: boolean = false;

    // name : string

    // description : string

    // default: boolean = false;

    name : string;

    description: string;

    year: number;

    multiply_by_qty : boolean = false;

}

export class CustomFee extends Base {
    
    name : string;

    description: string;
    
    note: string;

    discount: number;

    value: number;

    type: QuotationFeeRowEnum;

    selected: boolean = false;
}