import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot, 
        state: RouterStateSnapshot) {

        // console.log("AuthGuard");        

        const user = this.authService.currentUserValue;

        if (user) {
            
            
            // console.log("route",route);
            // console.log("state",state);
            // console.log("quotationWizardService",this.quotationWizardService.currentQuotation.logistic_row);
            
            // if(state.url == '/quotation-wizard/logistics-select' && this.quotationWizardService.currentQuotation.logistic_row == undefined) {
            //     this.quotationWizardService.goToFeeSelect();
            //     return false;
            // }
            // else {

                return true;
            // }
        } else {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }

    }
}