import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'quotation-footer',
  templateUrl: './quotation-footer.component.html',
  styleUrls: ['./quotation-footer.component.scss'],
})
export class QuotationFooterComponent  implements OnInit {

  // @Input()
  // public totalBuyNet : number | null = 0;

  @Input()
  public totalGross: number | null = 0;

  @Input()
  public totalNet: number | null = 0;

  @Input()
  public totalTax: number | null = 0;

  constructor() { }

  ngOnInit() {}

}
