// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.total {
  display: flex;
  flex-direction: column;
}
div.total div.total-row {
  display: flex;
  justify-content: flex-end;
}
div.total div.total-row span.little {
  font-size: 20px !important;
  min-width: 70px;
}
div.total div.total-row span.price {
  font-size: 28px;
  min-width: 190px;
}
div.total div.total-row span.price.little {
  min-width: 190px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/quotation-footer/quotation-footer.component.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;AADJ;AAGI;EACI,aAAA;EACA,yBAAA;AADR;AAGQ;EACI,0BAAA;EACA,eAAA;AADZ;AAIQ;EACI,eAAA;EACA,gBAAA;AAFZ;AAKQ;EACI,gBAAA;AAHZ","sourcesContent":["\r\n\r\ndiv.total{\r\n    display: flex;\r\n    flex-direction: column;\r\n\r\n    div.total-row {\r\n        display: flex;\r\n        justify-content: flex-end;\r\n\r\n        span.little {\r\n            font-size: 20px !important;\r\n            min-width: 70px;\r\n        }\r\n\r\n        span.price {\r\n            font-size: 28px;\r\n            min-width: 190px;\r\n        }\r\n\r\n        span.price.little {\r\n            min-width: 190px;\r\n        }\r\n\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
