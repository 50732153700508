import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, from, Observable, throwError } from 'rxjs';
import { map, catchError, switchMap, filter, take } from 'rxjs/operators';
import {
  Router
} from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { TokenService } from '../services/token.service';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private router: Router,
    private tokenService: TokenService,
    private authService: AuthService,
    public toastController: ToastController) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // console.warn("TokenInterceptor");

    const currentUser = this.authService.currentUser;
    // console.log("currentUser", currentUser);
    let token = this.tokenService.getToken();
    // console.log("token", token);   
    let tokenIsValid = this.tokenService.isTokenValid();
    // console.warn("tokenIsValid", tokenIsValid);

    const isLoggedIn = currentUser && token;

    const isApiUrl = req.url.startsWith(environment.api_site);
    // add token only to request to own server
    if (isLoggedIn && isApiUrl && tokenIsValid) {
      // console.log("add token to request");

      return next.handle(this.addToken(req, token));
     
    }
 

    return next.handle(req).pipe(
      catchError((error) => {
        // console.error(error);
        // console.log(error instanceof HttpErrorResponse);   
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(req, next);
        } else {
          return throwError(error);
        }

      })
    );



  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    
    // console.log("handle401Error", this.isRefreshing);
    
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.tokenService.sendRefreshTokenRequest().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          
          // console.log("sendRefreshTokenResponse", token);  

          if(!token){
            this.authService.logout();
            return next.handle(request);
          }

          
          this.refreshTokenSubject.next(token.accessToken);
          return next.handle(this.addToken(request, token.accessToken));
        }),
        catchError((error) => { 
          this.isRefreshing = false;
          // console.error(error);          

          if(error.status == 401){
            this.authService.logout();
          }

          return throwError(() => error);
        })
      );

    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          // console.log(jwt);
          
          return next.handle(this.addToken(request, jwt));
        }),
        catchError(err => {
          // console.log(err);
          
          return throwError(err);
        })
      );

    }
  }


  // private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
  //   // console.log("handle401Error", this.isRefreshing);
  //   if (!this.isRefreshing) {
  //     this.isRefreshing = true;
  //     this.refreshTokenSubject.next(null);

  //     return this.tokenService.sendRefreshTokenRequest().pipe(
  //       switchMap((token: any) => {
  //         // console.log("sendRefreshTokenResponse", token);  
  //         if(!token) this.authService.logout();

  //         this.isRefreshing = false;
  //         this.refreshTokenSubject.next(token.accessToken);
  //         return next.handle(this.addToken(request, token.accessToken));
  //       })
  //     );

  //   } else {

  //     return this.refreshTokenSubject.pipe(
  //       filter((token) => token != null),
  //       take(1),
  //       switchMap((jwt) => {
  //         return next.handle(this.addToken(request, jwt));
  //       })
  //     );

  //   }
  // }


}