import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { News } from '../class/news.class';
import { Product } from '../class/product.class';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private http: HttpClient) { 

  }

  getAll()  {
    // return this.http.get<News[]>(`${environment.apiUrl}/api/${environment.apiVersion}/news`, httpOptions)

    let params = new HttpParams()
    
    return this.http.get<Array<News>>(`${environment.api_site}/news/app`, { params });
    
  }

  setReaded(news_Id : string)  {
    // return this.http.get<News[]>(`${environment.apiUrl}/api/${environment.apiVersion}/news`, httpOptions)

    
    
    return this.http.post(`${environment.api_site}/news/${news_Id}/readed`, {});
    
  }

}
