import { Component, ChangeDetectionStrategy, Output, Input, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, FormArray, Validators } from "@angular/forms";
import { AlertController } from "@ionic/angular";
import { ProductService } from "../../../../../services/product.service";
import { QuotationService } from "../../../../../services/quotation.service";
import { QuotationFeeRowEnum } from "../../../../../class/quotation.class";
import { QuotationWizardService } from "../../../../../services/quotation-wizard.service";

@Component({
  selector: 'quotation-fee',
  templateUrl: './quotation-fee.component.html',
  styleUrls: ['./quotation-fee.component.scss'],
})
export class QuotationFeeComponent {

  @Output()
  selectFee = new EventEmitter<FormGroup>();

  @Input()
  public formGroup: FormGroup;

  get f() { return this.formGroup.controls; }

  get selected_type(){
    return this.quotationWizardService.quotationFeeSelectedType().value;
  }

  get first_year_value(){
    return this.quotationWizardService.quotationFeesForm.value.fees.find(x => x.type == QuotationFeeRowEnum.FIRST_YEAR).value;
  }

  constructor(
    private formBuilder: FormBuilder,
    private quotationService: QuotationService,
    private alertController: AlertController,
    private productService: ProductService,
    public quotationWizardService: QuotationWizardService) {

  }

  getFeeType(type: string): string {
    let r = '';
    

    switch(type) {
      case QuotationFeeRowEnum.FIRST_YEAR:
        r = 'Canone 1 anno';
        break;

      case QuotationFeeRowEnum.FIVE_YEARS:
        r = 'Canone 5 anni (1+4)';
        break;
    }

    return r;
  }

  applyDiscountFiveYears(){    

    let feeRowFirstYear = this.quotationWizardService.quotationFeesForm.value.fees.find(x => x.type == QuotationFeeRowEnum.FIRST_YEAR);
    let feeRowFiveYears = this.quotationWizardService.quotationFeesForm.value.fees.find(x => x.type == QuotationFeeRowEnum.FIVE_YEARS);

    // console.log("feeRowFirstYear",feeRowFirstYear);
    // console.log("feeRowFiveYears",feeRowFiveYears);
    feeRowFiveYears.discount = feeRowFirstYear.value;
    feeRowFiveYears.value = feeRowFiveYears.value - feeRowFirstYear.value;
    feeRowFiveYears.apply_discount = true;

    this.formGroup.value.apply_discount = !this.formGroup.value.apply_discount;
    this.formGroup.value.value = feeRowFiveYears.value;

    this.quotationWizardService.updateTotalAmount();
  }

  removeDiscountFiveYears(){    

    let feeRowFirstYear = this.quotationWizardService.quotationFeesForm.value.fees.find(x => x.type == QuotationFeeRowEnum.FIRST_YEAR);
    let feeRowFiveYears = this.quotationWizardService.quotationFeesForm.value.fees.find(x => x.type == QuotationFeeRowEnum.FIVE_YEARS);

    // console.log("feeRowFirstYear",feeRowFirstYear);
    // console.log("feeRowFiveYears",feeRowFiveYears);

    feeRowFiveYears.discount = 0;
    feeRowFiveYears.value = feeRowFiveYears.value + feeRowFirstYear.value;
    feeRowFiveYears.apply_discount = false;

    this.formGroup.value.apply_discount = !this.formGroup.value.apply_discount;
    this.formGroup.value.value = feeRowFiveYears.value;

    this.quotationWizardService.updateTotalAmount();
  }

}
