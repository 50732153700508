import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Company } from '../class/company.class';
import { DiscountList } from '../class/Discount/discount-list.class';
import { PaymentCondition } from '../class/Payment/payment-condition.class';
import { DiscountCampaign } from '../class/discount-campaign.class';
import { CustomFee, Fee } from '../class/fee.class';
import { Product } from '../class/product.class';
import { ShopType } from '../class/shop-type.class';
import { Geoposition } from '@awesome-cordova-plugins/geolocation';
import { Quotation, QuotationFeeRow, QuotationFeeRowEnum, QuotationLogisticRow, QuotationPaymentRow } from '../class/quotation.class';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { QuotationService } from './quotation.service';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { CompanyDocumentListComponent } from '../components/modal/company-document-list/company-document-list.component';
import { DocumentService } from './document.service';
import { LocationService } from './location.service';
import { TaxBreakService } from './tax-break.service';
import { CompanyService } from './company.service';
import { PriceList } from '../class/price-list.class';
import { ProductPriceListService } from './product-price-list.service';
import { PriceHelper } from '../utility/price-helper.class';
import { ProductPriceList } from '../class/product-price-list.class';
import { ProductFeeAssociation } from '../class/product-fee-association.class';
import { FeeService } from './fee.service';
import { GeneralSettingService } from './general-setting.service';
import { AddressService } from './address.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class QuotationWizardService {

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private companyService: CompanyService,
    private quotationService: QuotationService,
    private documentService: DocumentService,
    private locationService: LocationService,
    private taxBreakService: TaxBreakService,
    private loadingCtrl: LoadingController,
    private productPriceListService: ProductPriceListService,
    private feeService: FeeService,
    private generalSettingService: GeneralSettingService,
    private addressService: AddressService
  ) {

    // this.showLoading("Caricamento dati in corso...");

    this.locationService.getCurrentPosition().then(data => {
      this.currentPostion = data
    })

    this.taxBreakService.getTaxBreakList();

    this.currentQuotation = new Quotation();
    if (JSON.parse(localStorage.getItem("quotation"))) {
      this.currentQuotation = JSON.parse(localStorage.getItem("quotation"))
    }


    // let shopTypeListPromise = this.quotationService.getShopTypes().toPromise().then(data => {
    //   // console.log("productService.getShopTypes", data);      
    //   this.shopTypeList = data;
    // })

    this.shopTypeForm = this.formBuilder.group({
      shop_type_id: [null, Validators.required]
    });

    this.customerForm = this.formBuilder.group({
      company_id: [null, Validators.required],
      user_id: [null, Validators.required]
    });

    // this.customerForm = this.formBuilder.group({
    //   company_id: [null, Validators.required],
    //   business_name: ['', Validators.required],
    //   vat_code: ['', [Validators.required, Validators.pattern(/^[0-9]{11}$/)]],
    //   fiscal_code: ['', Validators.required],
    //   email: ['', [Validators.required, Validators.email]],
    //   email_pec: ['', [Validators.required, Validators.email]],
    //   sdi : [null],
    //   phone: ['', Validators.required],
    //   mobile_phone: [''],
    //   description: [''],
    //   website: [''],
    //   address_operational_headquarters_id : [null],
    //   address_registered_office_id : [null],
    //   contact_name:  ['', Validators.required],
    //   contact_surname:  ['', Validators.required],
    //   contact_phone:  ['', Validators.required],
    //   price_list_id:  [null],
    //   user_id: [null,Validators.required]
    // });





    this.quotatioPriceListForm = this.formBuilder.group({
      price_list_id: [null, Validators.required]
    });


    this.productPriceListService.getPriceList().then(data => {
      this.priceList = data
    })


    this.quotationForm = this.formBuilder.group({
      products: this.formBuilder.array([])
    });






    this.quotationPaymentForm = this.formBuilder.group({
      payment_method_id: [null, Validators.required],
      payment_rows: this.formBuilder.array([]),
      note: []
    });



    this.quotationFeesForm = this.formBuilder.group({
      fees: this.formBuilder.array([]),
      selected_type: []
    });



    this.quotationLogisticForm = this.formBuilder.group({
      extimated_delivery_date: [],
      extimated_delivery_days: [],
      trainingChecked: [true],
      include_training_hours: [],
      include_training_hours_cost: [],
      installationChecked: [true],
      include_installation_hours: [],
      include_installation_hours_cost: [],
      shipmentChecked: [true],
      shipment_cost_euros: [],
      installationByNightChecked: [false],
      installationPreHolidayChecked: [false],
      installationHolidayChecked: [false],
      // payment_method_id: [null, Validators.required],
      // note: [],
      // fees: this.formBuilder.array([]),
      // payment_rows: this.formBuilder.array([])
    });


    this.quotationDiscountForm = this.formBuilder.group({
      discount: [0, [Validators.min(0)]]
    });


    this.previewConfirmForm = this.formBuilder.group({

    });

    this.customerForm.valueChanges.subscribe(value => {
      // console.log("customerForm.valueChanges", value);

      this.updateTotalAmount()
    })



    this.quotationDiscountForm.valueChanges.subscribe(value => {
      // console.log("quotationDiscountForm.valueChanges", value);
      this.updateTotalAmount()
    })



    this.quotationForm.valueChanges.subscribe(value => {
      // console.log("quotationForm.valueChanges", value);
      this.updateTotalAmount()
    })


    this.quotationFeesForm.valueChanges.subscribe(value => {
      // console.log("quotationFeesForm.valueChanges", value, this.quotationFeesForm);
      this.updateTotalAmount();

    })

    this.quotationPaymentForm.valueChanges.subscribe(value => {
      // console.log("quotationPaymentForm.valueChanges", value, this.quotationPaymentForm);
      this.updateTotalAmount();

    })

    this.quotationLogisticForm.valueChanges.subscribe(value => {
      // console.log("quotationLogisticForm.valueChanges", value, this.quotationLogisticForm);
      this.updateTotalAmount();

    })

    // this.updateTotalAmount();




    // let payPromise = this.quotationService.getAvailablePaymentMethods().toPromise().then(data => {
    //   // console.log("quotationService.getAvailablePaymentMethods", data);   
    //   this.availablePaymentMethods = data;
    // });

    // let discountPromise = this.quotationService.getAvailableDiscounts().toPromise().then(data => {
    //   // console.log("getAvailableDiscounts", data);          
    //   this.availabeDiscountList = data;
    // });

    // let campDiscPromise = this.quotationService.getAvailableDiscountCampaing().toPromise().then(data => {
    //   // console.log("getAvailableDiscountCampaing", data);           
    //   this.availabeDiscountCampaing = data;
    // });


    // Promise.all([
    //   shopTypeListPromise,
    //   payPromise, 
    //   discountPromise, 
    //   campDiscPromise]).then(async data => {

    //   this.hideLoading();

    //   if(this.id){

    //     this.currentQuotation = await this.quotationService.getById(this.id).toPromise()
    //     localStorage.setItem("quotation", JSON.stringify(this.currentQuotation))

    //     console.log("load quotation", this.currentQuotation);        

    //   }


    // }).catch(err => {
    //   console.error(err);
    //   this.hideLoading();
    // })



  }

  //#region base methods

  async loadController() {

    console.log("loadController");

    this.showLoading("Caricamento dati in corso...");

    
    let shopTypeListPromise = this.quotationService.getShopTypes().toPromise().then(data => {
      // console.log("productService.getShopTypes", data);      
      this.shopTypeList = data;
    })
    
    let payPromise = this.quotationService.getAvailablePaymentMethods().toPromise().then(data => {
      // console.log("quotationService.getAvailablePaymentMethods", data);   
      this.availablePaymentMethods = data;
    });
    
    let discountPromise = this.quotationService.getAvailableDiscounts().toPromise().then(data => {
      // console.log("getAvailableDiscounts", data);          
      this.availabeDiscountList = data;
    });
    
    let campDiscPromise = this.quotationService.getAvailableDiscountCampaing().toPromise().then(data => {
      // console.log("getAvailableDiscountCampaing", data);           
      this.availabeDiscountCampaing = data;
    });
    
    await this.setGeneralSettings();
    
    
    
    await Promise.all([
      shopTypeListPromise,
      payPromise,
      discountPromise,
      campDiscPromise]).then(async data => {
        // console.log("hide");
        
        // console.log("id",this.id);        
        
        // if(this.id){
          
        //   this.currentQuotation = await this.quotationService.getById(this.id).toPromise()
        //   localStorage.setItem("quotation", JSON.stringify(this.currentQuotation))
          
        //   console.log("load quotation", this.currentQuotation);      
          
        // }
        await this.restoreSavedQuotation();
        // else {
          
        //   await this.restoreLocalQuotation();
        //   // this.goToActivityTypeSelect();

        //   // await this.restoreSavedQuotation();

        // }
          

        this.hideLoading();


      }).catch(err => {
        console.error(err);
        this.hideLoading();
      })

    // this.hideLoading();

  }

  async restoreSavedQuotation() {

    // var redirect = () => {this.router.navigate(['/quotation'])};
    var redirect = () => {
      if(this.router.url != '/quotation-wizard/activity-type-select' && this.router.url != '/quotation-wizard') {
        this.goToActivityTypeSelect()
      }
    };
    // var redirect = () => {};

    if (await this.loadFormValue('shopTypeForm', this.shopTypeForm)) {
      await this.selectShop(this.shopTypeForm.value.shop_type_id)

      redirect = () => this.goToCompanyEssential();
    }

    if (await this.loadFormValue('customerForm', this.customerForm)) {
      // console.log(this.customerForm);
      // console.log(this.customerForm.valid);      
      if(this.customerForm.value.company_id){

        this.selectedCompany = await this.companyService.getById(this.customerForm.value.company_id).toPromise()
      }
      else {
        this.selectedCompany = this.currentQuotation.company;
      }

      // console.log("this.customerForm.value.company_id",this.customerForm.value.company_id);
      // console.log("this.selectedCompany",this.selectedCompany);

      await this.setShipmentDistance();

      redirect = () => this.goToPriceListSelect();

    }


    if (await this.loadFormValue('quotatioPriceListForm', this.quotatioPriceListForm)) {
      if (this.quotatioPriceListForm.value.price_list_id) {
        await this.productPriceListService.getProductPriceListByPriceListId(this.quotatioPriceListForm.value.price_list_id);
        await this.productPriceListService.getProductBasePriceListByPriceListId();
      }

      redirect = () => this.goToProductsSelect();


    }

    if (await this.loadFormValue('quotationForm', this.quotationForm)) {
      this.restoreProductForm()

      redirect = () => this.goToProductsSelect();

    }

    if (await this.loadFormValue('quotationFeesForm', this.quotationFeesForm)) {
      // console.error("loadFormValue quotationFeesForm", this.quotationFeesForm);          
      // await this.generateFeesTable();
      await this.restoreFeesTable();
      
      redirect = () => this.goToLogisticSelect();
    }


    if (await this.loadFormValue('quotationLogisticForm', this.quotationLogisticForm)) {
      // await this.generateLogisticTable();
      redirect = () => this.goToDiscountSelect();
    }
    else if(this.router.url == '/quotation-wizard/logistics-select') {
      // console.log("this.router.url",this.router.url);
      await this.generateLogisticTable();
    }

    if(await this.loadFormValue('quotationDiscountForm', this.quotationDiscountForm)){

      redirect = () => this.goToSummary();

    }

    if (await this.loadFormValue('quotationPaymentForm', this.quotationPaymentForm)) {
      // console.error("loadFormValue quotationPaymentForm", this.quotationPaymentForm);      
      // this.selectPayment(this.quotationPaymentForm.value.payment_method_id)
      this.selectedPaymentMethod = this.availablePaymentMethods.find(p => p.id == this.quotationPaymentForm.value.payment_method_id);
      this.generatePaymentTable();

      // redirect = () => this.goToPreviewSelect();
      redirect = () => this.goToCompanySelect();

      if(this.currentQuotation.company_id) {

        redirect = () => this.goToPreviewSelect();

      }
    }

    redirect();

    // console.log("fine restore");

  }

  async setGeneralSettings() {

    // prendo il parametro del costo orario di formazione e installazione
    this.join_training_and_installation_hours_in_quotation = Boolean((await this.generalSettingService.getByCode('join_training_and_installation_hours_in_quotation') || 'true') === 'true');

    // prendo il parametro del costo orario di formazione e installazione
    this.training_and_installation_cost_per_hour = Number(await this.generalSettingService.getByCode('training_and_installation_cost_per_hour') || 0);

    // prendo il parametro del costo di spedizione al kg
    this.kg_shipment_cost = Number(await this.generalSettingService.getByCode('kg_shipment_cost') || 0);

    // prendo il parametro del costo di spedizione al km
    this.km_shipment_cost = Number(await this.generalSettingService.getByCode('km_shipment_cost') || 0);

    // prendo il parametro del fattore moltiplicativo
    this.multiplier_factor_shipment_cost = Number(await this.generalSettingService.getByCode('multiplier_factor_shipment_cost') || 0);

    // prendo il parametro della percentuale di assicurazione
    this.percentage_insurance_shipment_cost = Number(await this.generalSettingService.getByCode('percentage_insurance_shipment_cost') || 0);

    // prendo il parametro della percentuale di installazione notturna
    this.percentage_installation_by_night = Number(await this.generalSettingService.getByCode('percentage_installation_by_night') || 0);

    // prendo il parametro della percentuale di installazione prefestiva
    this.percentage_installation_preholiday = Number(await this.generalSettingService.getByCode('percentage_installation_preholiday') || 0);
    
    // prendo il parametro della percentuale di installazione festiva
    this.percentage_installation_holiday = Number(await this.generalSettingService.getByCode('percentage_installation_holiday') || 0);
    

  }

  async setShipmentDistance() {
    // console.log("setShipmentDistance");


    //distanza in linea d'aria in km
    if (this.selectedCompany) {

      // console.log(this.selectedCompany);

      if (!this.selectedCompany.address_operational_headquarters && this.selectedCompany.address_operational_headquarters_id) {

        this.selectedCompany.address_operational_headquarters = await this.addressService.getById(this.selectedCompany.address_operational_headquarters_id).toPromise();

      }

      let address = this.selectedCompany.address_operational_headquarters;

      if (address) {

        if (!address.lat_lon) {

          let latlng: any = await this.addressService.geoCode(this.selectedCompany.address_operational_headquarters.street + " " + this.selectedCompany.address_operational_headquarters.city);

          // console.log("latlng", latlng);

          address.lat_lon = latlng;

        }

        this.shipment_kilometers_distance = this.locationService.getKilometersDistanceBetweenTwoCoordinates({ lat: address.lat_lon?.lat, lng: address.lat_lon?.lng });

        this.shipment_kilometers_distance = Math.round(this.shipment_kilometers_distance);

        // console.log("this.shipment_kilometers_distance",this.shipment_kilometers_distance);


      }



    }
  }

  async restoreLocalQuotation() {
    let tempQuotation = localStorage.getItem("quotation")

    if (tempQuotation) {

      const alert = await this.alertController.create({
        header: "E' stato trovato un preventivo precedentemente avviato. Continuare o iniziarne uno nuovo?",
        buttons: [
          {
            text: 'Continua',
            handler: (value: any) => {
              this.restoreSavedQuotation()

              // //riportare all'ultima pagina <--------------------------

              // if(this.currentQuotation.payment_method_id) {
                
              //   this.goToPreviewSelect()

              // }
            }
          },
          {
            text: 'Nuovo preventivo',
            handler: (value: any) => {
              //rimuovere tutti i form
              this.removeStoredQuotation();
              // this.goToActivityTypeSelect();
              window.location.reload();
              //bisogna resettare tutto
              // this.customerForm.reset();
              // this.selectedCompany = new Company();
              // this.quotatioPriceListForm.reset();
              // this.quotationForm.reset();
              // this.loadController();
            }
          }
        ],
        backdropDismiss: false
      });

      await alert.present();
    }
    // else {
    //   this.goToActivityTypeSelect();
    // }
  }

  removeStoredQuotation() {
    localStorage.removeItem("quotation")
    localStorage.removeItem("shopTypeForm")
    localStorage.removeItem("customerForm")
    localStorage.removeItem("quotatioPriceListForm")
    localStorage.removeItem("quotationForm")
    localStorage.removeItem("quotationPaymentForm")
    localStorage.removeItem("quotationFeesForm")
    localStorage.removeItem("quotationLogisticForm")
    localStorage.removeItem("quotationDiscountForm")
    localStorage.removeItem("previewConfirmForm")

    // this.goToActivityTypeSelect();
    // this.router.navigate(['/quotation-wizard']);
  }

  // localStorage.setItem('customerForm', JSON.stringify(this.customerForm.value));
  saveFormValue(formName: string, form: FormGroup): void {

    localStorage.setItem(formName, JSON.stringify(form.value));

    localStorage.setItem("quotation", JSON.stringify(this.currentQuotation))
  }

  async loadFormValue(formName: string, form: FormGroup): Promise<boolean> {
    // console.error("loadFormValue",formName,form);
    // console.error(localStorage.getItem(formName));




    if (JSON.parse(localStorage.getItem(formName))) {

      let storedObject: any = JSON.parse(localStorage.getItem(formName))

      // console.log("storedObject", storedObject);
      

      for (const property in storedObject) {

        // console.error(property,storedObject[property]);
        // console.log(formName, [property] , storedObject[property]);

        await this[formName].patchValue({ [property]: storedObject[property] }, { emitEvent: true })

        
        // this[formName].get(property).setValue(storedObject[property], { emitEvent : true })

        // this[formName].updateValueAndValidity();

      }

      // this.updateTotalAmount();

      return true
    }

    return false

  }

  //  Calcolo del totale quotazione
  updateTotalAmount(): void {

    // console.log("updateTotalAmount");

    this.totalAmountBuyNet = 0
    this.totalAmountTax = 0
    this.totalAmountGross = 0
    this.totalAmountNet = 0

    this.extimated_delivery_date = null;
    this.extimated_delivery_days = 0;
    this.include_installation_hours = 0;
    this.include_installation_hours_cost = 0;
    this.include_training_hours = 0;
    this.include_training_hours_cost = 0;
    this.shipment_cost_euros = 0;

    let totalAmount: number = 0

    let selectedQuotation = <Quotation>this.quotationForm.value;

    // console.log("selectedQuotation", selectedQuotation.products);

    if (!selectedQuotation) totalAmount = 0

    if (!selectedQuotation.products) totalAmount = 0

    // console.log("this.available_products",this.availableProducts);
    

    let quotationTotal = selectedQuotation.products.reduce((total, item) => {

      // console.log("item", item);     
      let rowBuyTotal = 0

      let rowTotal = 0

      let product: Product = this.availableProducts.find(p => p.id === item.product_id);

      if (!product) {
        //return rowTotal
        return total + rowTotal
      }

      // console.log("product", product);

      //calcolo giorni stimati di consegna (associato al prodotto)
      // if(product?.supplier?.extimated_delivery_days > this.extimated_delivery_days)
      //   this.extimated_delivery_days += product?.supplier?.extimated_delivery_days || 0;

      if(product?.extimated_delivery_days > this.extimated_delivery_days)
        this.extimated_delivery_days += product?.extimated_delivery_days || 0;


      // calcolo ore di installazione da moltiplicare per qtà
      this.include_installation_hours += Number(product.include_installation_hours * item.qty || 0);

      // calcolo ore di formazione da raggruppare
      this.include_training_hours += Number(product.include_training_hours || 0);

      // calcolo costo di trasporto
      this.shipment_cost_euros += this.getShipmentTotal(product, item.qty);

      // console.log("this.shipment_cost_euros",this.shipment_cost_euros);


      let productPriceList = this.productPriceListService.productPriceList.find(l => l.product_id === item.product_id)
      // console.log("productPriceList", productPriceList);

      let price = Number(productPriceList?.price || 0) || Number(item?.price || 0) || 0

      // calc discount
      price = PriceHelper.SubtractDiscount(price, Number(item.discount || 0))

      // console.log("price", price);

      rowTotal += item.qty * price;

      rowBuyTotal += item.qty * product.price_buy

      // console.log("rowTotal",rowTotal);

      let accessoriesTotal = 0

      let allSelectedAccessories = item.accessories.concat(item.accessories_required);
      // console.log("allSelectedAccessories", allSelectedAccessories);      

      // prezzo accessori
      for (let index = 0; index < allSelectedAccessories.length; index++) {
        const acc = allSelectedAccessories[index];

        let accessory: Product;

        // console.log("product.accessories_associated",product.accessories_associated, acc);
        

        for(let i=0;i<product.accessories_associated.length;i++) { 

          let group = product.accessories_associated[i];

          // console.log("group",group);

          accessory = group.product?.find(p => p.id == acc.accessory_id)

          if(accessory) break;
          
        };

        // console.log("accessory",accessory);

        if (!accessory) {
          //return rowTotal
          return total + rowTotal
        }

        //calcolo giorni stimati di consegna (associati all'accessorio)
        // if(accessory?.supplier?.extimated_delivery_days > this.extimated_delivery_days)
        //   this.extimated_delivery_days += accessory?.supplier?.extimated_delivery_days || 0;
        if(accessory?.extimated_delivery_days > this.extimated_delivery_days)
          this.extimated_delivery_days += accessory?.extimated_delivery_days || 0;

        // calcolo ore di installazione da moltiplicare per qtà
        this.include_installation_hours += Number(accessory.include_installation_hours * item.qty || 0);

        // calcolo ore di formazione da raggruppare
        this.include_training_hours += Number(accessory.include_training_hours || 0);

        // calcolo costo di trasporto
        this.shipment_cost_euros += this.getShipmentTotal(accessory, item.qty);

        // let accessoryPriceList : ProductPriceList = accessory.product_price_lists.find(l => l.price_list.price_list_type === 'sale')
        let accessoryPriceList: ProductPriceList = this.productPriceListService.productPriceList.find(l => l.product_id === acc.accessory_id)
        // console.log("accessoryPriceList", accessoryPriceList);


        let accPrice = Number(accessoryPriceList?.price) || Number(accessory?.price) || 0

        accPrice = PriceHelper.SubtractDiscount(Number(accPrice), Number(acc.discount || 0))


        rowTotal += acc.qty * accPrice;
        // console.log("rowTotal",rowTotal);


        rowBuyTotal += acc.qty * accessory.price_buy
        

      }


      // sommo i totali acquisto
      this.totalAmountBuyNet += rowBuyTotal



      return total + rowTotal

    }, 0)

    // console.log("this.currentQuotation.logistic_row?.extimated_delivery_date?.toDateString()",this.currentQuotation.logistic_row?.extimated_delivery_date, moment(this.currentQuotation.logistic_row?.extimated_delivery_date)?.format("YYYY-MM-DD").toString());

    this.extimated_delivery_date = this.currentQuotation.logistic_row?.extimated_delivery_date ? moment(this.currentQuotation.logistic_row?.extimated_delivery_date)?.format("YYYY-MM-DD").toString() : this.getMinDeliveryDate(this.extimated_delivery_days);

    // console.log("extimated_delivery_days",this.extimated_delivery_days);
    // console.log("extimated_delivery_date",this.extimated_delivery_date);
    

    let totalFee = this.getFeeTotal() || 0;

    // costo ore di formazione
    this.include_training_hours_cost = Math.round(((this.include_training_hours * this.training_and_installation_cost_per_hour) + Number.EPSILON) * 100) / 100;
    
    // costo ore di installazione
    this.include_installation_hours_cost = Math.round(((this.include_installation_hours * this.training_and_installation_cost_per_hour) + Number.EPSILON) * 100) / 100;

    if (this.quotationLogisticForm.value.installationByNightChecked){      
      //se l'installazione è notturna
      this.include_installation_hours_cost += Math.floor(Number(this.include_installation_hours_cost * this.percentage_installation_by_night * 0.01));

    }
    if (this.quotationLogisticForm.value.installationPreHolidayChecked){
      //se l'installazione è prefestiva
      this.include_installation_hours_cost += Math.floor(Number(this.include_installation_hours_cost * this.percentage_installation_preholiday * 0.01));
    }

    if (this.quotationLogisticForm.value.installationHolidayChecked){
      //se l'installazione è festiva
      this.include_installation_hours_cost += Math.floor(Number(this.include_installation_hours_cost * this.percentage_installation_holiday * 0.01));
    }
    // console.log("quotationTotal",quotationTotal);
    // console.log("totalFee",totalFee);

    totalAmount = quotationTotal + totalFee;

    this.totalAmountNet = Math.round((totalAmount + Number.EPSILON) * 100) / 100;




    // console.log("this.shipment_cost_euros PRE",this.shipment_cost_euros);

    //calcolo assicurazione sul totale di acquisto dei prodotti (no canoni)
    let insurance_cost = Math.floor(Number(this.totalAmountBuyNet * this.percentage_insurance_shipment_cost * 0.01));

    // console.log("assicurazione",this.percentage_insurance_shipment_cost,insurance_cost);

    //aggiungo assicurazione
    this.shipment_cost_euros += Math.round((insurance_cost + Number.EPSILON) * 100) / 100;

    //approssimo
    this.shipment_cost_euros = Math.floor(this.shipment_cost_euros);

    // console.log("this.shipment_cost_euros finale",this.shipment_cost_euros);
    // console.log("this.quotationLogisticForm.value",this.quotationLogisticForm.value);
    // console.log("this.quotationLogisticForm.controls",this.quotationLogisticForm.controls);
    

    //aggiungo trasporto se è checkato
    if (this.quotationLogisticForm.value.shipmentChecked && this.quotationLogisticForm.value.shipment_cost_euros) {
      this.totalAmountNet += this.shipment_cost_euros;
    }

    //aggiungo formazione se è checkato
    if (this.quotationLogisticForm.value.trainingChecked && this.quotationLogisticForm.value.include_training_hours_cost > 0) {
      this.totalAmountNet += this.include_training_hours_cost;
    }

    //aggiungo installazione se è checkato
    if (this.quotationLogisticForm.value.installationChecked && this.quotationLogisticForm.value.include_installation_hours_cost > 0) {
      this.totalAmountNet += this.include_installation_hours_cost;
    }

    this.totalAmountGross = Math.round(((this.totalAmountNet * 1.22) + Number.EPSILON) * 100) / 100;

    this.totalAmountTax = Math.round(((this.totalAmountGross - this.totalAmountNet) + Number.EPSILON) * 100) / 100;


    // console.error("totals", this.totalAmountGross, this.totalAmountNet, this.totalAmountTax);

    // console.log("this.installation",this.include_installation_hours_cost);

    // this.quotationLogisticForm.controls["include_installation_hours_cost"].setValue(this.include_installation_hours_cost);
  

    // this.generateLogisticTable();

  }

  public getMinDeliveryDate(min_days: number = 0): string{

    let date = moment().add({'days':min_days}).startOf('day');

    date = date.add({'weeks':1}).startOf('isoWeek');

    return date.format("YYYY-MM-DD");

  }

  getShipmentTotal(product: Product, product_qty): number {

    // console.log("product",product);
    

    let shipment_cost_euros: number = 0;

    let totalEurosByKgShipment: number = 0;
    let totalEurosByKmShipment: number = 0;

    if(product.approximate_weight != null && product.approximate_weight > 0) {

      // euro al kg
      totalEurosByKgShipment = Number(product.approximate_weight * product_qty * this.kg_shipment_cost || 0)

      totalEurosByKgShipment = Math.round((totalEurosByKgShipment + Number.EPSILON) * 100) / 100;

      // console.log("totalEurosByKgShipment",totalEurosByKgShipment,product.approximate_weight,this.kg_shipment_cost);

      // euro al km
      totalEurosByKmShipment = Number(this.shipment_kilometers_distance * this.km_shipment_cost || 0);

      totalEurosByKmShipment = Math.round((totalEurosByKmShipment + Number.EPSILON) * 100) / 100;

      // console.log("totalEurosByKmShipment",totalEurosByKmShipment,this.shipment_kilometers_distance,this.km_shipment_cost);

      // calcolo costo di trasporto
      shipment_cost_euros += totalEurosByKgShipment + Math.round(((totalEurosByKmShipment * this.multiplier_factor_shipment_cost) + Number.EPSILON) * 100) / 100;

    
    }
  
    // console.log("shipment_cost_euros",shipment_cost_euros);

    return shipment_cost_euros;
  }

  //verifico se il cliente ha documenti in sospeso
  async checkCompanyDocumentOnGoingStatus(): Promise<Array<Quotation>> {

    return this.documentService.getCompanyStatsOnGoing(this.selectedCompany.vat_code).toPromise()


  }

  //#endregion

  //#region declarations

  public id: string;

  public currentQuotation: Quotation;

  public availableProducts: Array<Product> = [];
  public availablePaymentMethods: Array<PaymentCondition> = [];
  public availabeDiscountList: Array<DiscountList> = [];
  public availabeDiscountCampaing: Array<DiscountCampaign> = [];

  public selectedCompany: Company | null;
  public selectedPaymentMethod: PaymentCondition = null;

  // public quotationFeesList: Array<Fee> = [];
  public quotationFeesList: Array<CustomFee> = [];
  public priceList: Array<PriceList> = [];
  public shopTypeList: Array<ShopType> = [];

  public totalAmountBuyNet: number = 0;
  public totalAmountGross: number = 0;
  public totalAmountNet: number = 0;
  public totalAmountTax: number = 0;

  public shipment_kilometers_distance: number = 0;

  public extimated_delivery_date: string = null;
  public extimated_delivery_days: number = 0;
  public include_training_hours: number = 0;
  public include_training_hours_cost: number = 0;
  public include_installation_hours: number = 0;
  public include_installation_hours_cost: number = 0;
  public shipment_cost_euros: number = 0;

  // general_settings
  public join_training_and_installation_hours_in_quotation: boolean = true;
  public training_and_installation_cost_per_hour: number = 0;
  public kg_shipment_cost: number = 0;
  public km_shipment_cost: number = 0;
  public multiplier_factor_shipment_cost: number = 0;
  public percentage_insurance_shipment_cost: number = 0;
  public percentage_installation_by_night: number = 0;
  public percentage_installation_preholiday: number = 0;
  public percentage_installation_holiday: number = 0;

  currentPostion: Geoposition;
  state: RouterStateSnapshot;

  // form

  public shopTypeForm: FormGroup;
  public customerForm: FormGroup;
  public quotatioPriceListForm: FormGroup;
  public quotationForm: FormGroup;
  public quotationFeesForm: FormGroup;
  public quotationDiscountForm: FormGroup;
  public quotationPaymentForm: FormGroup;
  public quotationLogisticForm: FormGroup;
  public previewConfirmForm: FormGroup;

  //#endregion

  //#region product

  quotationProducts(): FormArray {
    return this.quotationForm.get("products") as FormArray
  }

  newProductToQuotation(): FormGroup {

    return this.formBuilder.group({
      product_id: [null, Validators.required],
      description: [null],
      qty: [1, [Validators.required, Validators.min(1)]],
      accessories: this.formBuilder.array([]),
      accessories_required: this.formBuilder.array([]),
      price: [null],
      note: [null],
      discount: [null, [Validators.min(0)]]
    })

  }

  addProductToQuotation() {
    this.quotationProducts().push(this.newProductToQuotation());

  }

  get qf() { return this.quotationForm.controls; }

  quotationFormIsValid(): Boolean {
    return this.quotationForm.valid && this.totalAmountNet > 0
  }

  restoreProductForm() {

    this.quotationProducts().clear();

    // console.log("restoreProductForm", this.currentQuotation);

    if (this.currentQuotation?.products && this.currentQuotation.products.length > 0) {

      // console.log(this.currentQuotation.products);

      for (const prod of this.currentQuotation.products) {


        let formAccessories: Array<any> = []

        for (const acc of prod.accessories) {


          let accControl: FormGroup = this.formBuilder.group({
            accessory_id: [acc.accessory_id, Validators.required],
            description: [acc.description],
            qty: [acc.qty, [Validators.required, Validators.min(1)]],
            price: [acc.price],
            note: [acc.note],
            discount: [acc.discount, [Validators.min(0)]],
            max_discount  : [acc.max_discount]
          });

          //   accControl.controls["accessory_id"].setValue(acc.accessory_id);
          //   accControl.controls["description"].setValue(acc.description);
          //   accControl.controls["qty"].setValue(acc.qty);
          //   accControl.controls["price"].setValue(acc.price);
          //   // accControl.controls["note"].setValue(acc.note);
          //   accControl.controls["discount"].setValue(acc.discount);

          formAccessories.push(accControl)

        }

        let formAccessoriesRequired: Array<any> = []

        for (const acc of prod.accessories_required) {


          let accControl: FormGroup = this.formBuilder.group({
            accessory_id: [acc.accessory_id, Validators.required],
            description: [acc.description],
            qty: [acc.qty, [Validators.required, Validators.min(1)]],
            price: [acc.price],
            note: [acc.note],
            discount: [acc.discount, [Validators.min(0)]],
            max_discount  : [acc.max_discount]
          });

          //   accControl.controls["accessory_id"].setValue(acc.accessory_id);
          //   accControl.controls["description"].setValue(acc.description);
          //   accControl.controls["qty"].setValue(acc.qty);
          //   accControl.controls["price"].setValue(acc.price);
          //   // accControl.controls["note"].setValue(acc.note);
          //   accControl.controls["discount"].setValue(acc.discount);


          formAccessoriesRequired.push(accControl)

        }

        let productControl: FormGroup = this.formBuilder.group({
          product_id: [prod.product_id, Validators.required],
          description: [prod.description],
          qty: [prod.qty, [Validators.required, Validators.min(1)]],
          accessories: this.formBuilder.array(formAccessories),
          accessories_required: this.formBuilder.array(formAccessoriesRequired),
          price: [prod.price],
          note: [prod.note],
          discount: [prod.discount, [Validators.min(0)]]
        })

        // productControl.controls["product_id"].setValue(prod.product_id);
        // productControl.controls["description"].setValue(prod.description);
        // productControl.controls["qty"].setValue(prod.qty);

        // productControl.controls["accessories"].setValue(prod.accessories);
        // productControl.controls["accessories_required"].setValue(prod.accessories_required);
        // productControl.controls["price"].setValue(prod.price);
        // productControl.controls["note"].setValue(prod.);
        // productControl.controls["discount"].setValue(prod.discount);
        // fb.controls["accessory_id"].disable()

        // console.log("add control",prod, prod.product_id, productControl);



        // console.log(productControl);


        this.quotationProducts().push(productControl);

      }

      // this.quotationProducts().patchValue(this.currentQuotation.products)


      // this.quotationForm.setValue({
      //   products : this.currentQuotation.products
      // });

      // console.log(this.quotationForm.value.products);


    }
  }

  async onQuotationSubmit() {

    this.quotationForm.markAllAsTouched();

    // console.log(this.quotationForm);

    if (this.quotationForm.invalid) {
      console.warn("form not valid");

      return;
    }

    this.currentQuotation.products = this.quotationForm.value.products;


    // console.log("this.currentQuotation", this.currentQuotation);

    this.saveFormValue('quotationForm', this.quotationForm)

    this.goToFeeSelect()

    await this.generateFeesTable();


  }

  //#endregion product

  //#region pagamenti
  // righe pagamenti

  public quotationPaymentRows(): FormArray {
    return this.quotationPaymentForm.get("payment_rows") as FormArray
  }

  newQuotationPaymentRow(): FormGroup {

    return this.formBuilder.group({
      payment_condition_id: [null, Validators.required],
      payment_condition_item_id: [null],
      payment_condition_type_id: [null, Validators.required],
      description: [null],
      note: [null],
      days: [null, [Validators.required, Validators.min(0)]],
      value: [null, [Validators.required, Validators.min(0)]],
      net_total: [null, [Validators.required, Validators.min(0)]],
      // vat_total: [null, [Validators.required, Validators.min(0)]],
      vat_total: [null],
    })

  }

  addQuotationPaymentRow(fg?: FormGroup) {
    if(fg) {
      this.quotationPaymentRows().push(fg);
    }
    else {
      this.quotationPaymentRows().push(this.newQuotationPaymentRow());
    }
  }

  selectPayment(selectedPaymentId: string){

    //metodo per nuovo metodo di pagamento

    this.selectedPaymentMethod = this.availablePaymentMethods.find(p => p.id == selectedPaymentId);

    let payment_rows: Array<QuotationPaymentRow> = [];

    let payment_rate_count = this.selectedPaymentMethod.payment_condition_items.length;

    let first_payment_splitted = this.totalAmountBuyNet / 2

    let other_payment_splitter = (this.totalAmountNet - this.totalAmountBuyNet) / (payment_rate_count - 2)

    for (let index = 0; index < payment_rate_count; index++) {

      const payment_condition_item = this.selectedPaymentMethod?.payment_condition_items[index];

      // console.log("payment_condition_item",payment_condition_item);

      let paymentRow: QuotationPaymentRow = new QuotationPaymentRow()

      // paymentRow.description = `rata ${index + 1}`
      paymentRow.description = payment_condition_item.description
      // imposto il metodo di pagamento selezionato
      paymentRow.payment_condition_id = this.selectedPaymentMethod.id;
      // imposto il pagamento della rata selezionata
      paymentRow.payment_condition_item_id = payment_condition_item.id;

      paymentRow.payment_condition_type_id = null;

      paymentRow.days = payment_condition_item?.days;

      let amount = 0

      if (this.selectedPaymentMethod?.dynamic_acq_active && this.totalAmountBuyNet > 0) {

        // prime due rate
        if (index == 0 || index == 1) {
          amount = first_payment_splitted
        } else {
          amount = other_payment_splitter
        }

        // calcolo percentuale
        let percent_value = (amount * 100) / this.totalAmountNet

        // this.selectedPaymentMethod.payment_condition_items[index].value = Number(percent_value.toFixed(2))

        paymentRow.value = Number(percent_value.toFixed(2))

      }
      else {

        // percentuale rata
        let rate_value = this.selectedPaymentMethod.payment_condition_items[index].value

        paymentRow.value = rate_value

        // console.error("this.totalAmountNet",this.totalAmountNet);
        

        amount = (this.totalAmountNet / 100) * rate_value

      }

      paymentRow.net_total = Math.round((amount + Number.EPSILON) * 100) / 100;

      paymentRow.vat_total = Math.round(((paymentRow.net_total * 0.22) + Number.EPSILON) * 100) / 100;

      // this.selectedPaymentMethod.payment_condition_items[index].amount = amount


      payment_rows.push(paymentRow)

    }

    this.currentQuotation.payment_rows = payment_rows;

  }

  generatePaymentTable() {

    // console.error("generatePaymentTable",this.selectedPaymentMethod, this.currentQuotation);

    if (!this.currentQuotation.payment_method_id) return;

    this.quotationPaymentRows().clear();

    // let paymentRowsList: Array<QuotationPaymentRow> = []

    for (const iterator of this.currentQuotation.payment_rows) {
      this.addQuotationPaymentRow()
    }

    this.quotationPaymentRows().patchValue(this.currentQuotation.payment_rows)

  }

  get qpf() { return this.quotationPaymentForm.controls; }

  quotationPaymentFormIsValid(): Boolean {
    return this.quotationPaymentForm.valid
  }

  onPaymentMethodSelect(id: string) {

    let selectedPaymentId = id;

    if (!selectedPaymentId) return;

    this.quotationPaymentRows().clear();

    this.quotationPaymentForm.controls['payment_method_id'].setValue(selectedPaymentId);

    this.currentQuotation.payment_method_id = selectedPaymentId;

    this.selectPayment(selectedPaymentId);

    // this.quotationPaymentForm.controls['payment_method_id'].setValue(this.selectedPaymentMethod.id);

    // console.log("selectedPaymentMethod", this.selectedPaymentMethod);


    this.generatePaymentTable();

  }

  async onQuotationPaymentSubmit() {

    this.quotationPaymentForm.markAllAsTouched();

    // console.log(this.quotationPaymentForm);

    if (this.quotationPaymentForm.invalid) {
      console.warn("form not valid");

      return;
    }

    // this.selectedPaymentMethod = this.quotationConfirmForm.value;

    this.currentQuotation.payment_method_id = this.quotationPaymentForm.value.payment_method_id;

    this.currentQuotation.note = this.quotationPaymentForm.value.note;

    this.currentQuotation.payment_rows = this.quotationPaymentForm.value.payment_rows;


    // console.log("this.currentQuotation.payment_rows", this.currentQuotation.payment_rows);
    // console.log("this.currentQuotation", this.currentQuotation);


    this.saveFormValue('quotationPaymentForm', this.quotationPaymentForm)

    //await this.quotationService.insert(quotation).toPromise();

    //   this.goToPreviewSelect();

      this.goToCompanySelect();

  }

  addPaymentRow(description: string, value: number, days: number){

    /*
      payment_condition_id: [null, Validators.required],
      payment_condition_item_id: [null, Validators.required],
      payment_condition_type_id: [null, Validators.required],
      description: [null],
      note: [null],
      days: [null, [Validators.required, Validators.min(0)]],
      value: [null, [Validators.required, Validators.min(0)]],
      net_total: [null, [Validators.required, Validators.min(0)]],
      // vat_total: [null, [Validators.required, Validators.min(0)]],
      vat_total: [null],

    */

    if(!value) return;

    // console.log("addPaymentRow");

    //calcolo nuove rate con for di this.currentQuotation.payment_rows e poi push del nuovo

    let removed_value = Number(value) / (this.currentQuotation.payment_rows.length > 2 ? this.currentQuotation.payment_rows.length - 2: this.currentQuotation.payment_rows.length); 

    for(let i=0;i<this.currentQuotation.payment_rows.length;i++) {

      if(this.currentQuotation.payment_rows.length > 2) {

        if(i<2) continue;

      }

      let row = this.currentQuotation.payment_rows[i];

      row.value = Number(row.value) - removed_value;

      // console.log("row.value POST",row.value);

      let new_amount= (this.totalAmountNet / 100) * row.value;

      row.net_total = Math.round((new_amount + Number.EPSILON) * 100) / 100;

      row.vat_total = Math.round(((row.net_total * 0.22) + Number.EPSILON) * 100) / 100;

    }

    let qpr = new QuotationPaymentRow();

    qpr.payment_condition_id = this.selectedPaymentMethod?.id;
    // qpr.payment_condition_item_id = this.selectedPaymentMethod?.id;
    qpr.description = description;
    qpr.days = days;
    qpr.value = value;

    let new_amount= (this.totalAmountNet / 100) * value;
    let net_total = Math.round((new_amount + Number.EPSILON) * 100) / 100;
    let vat_total = Math.round(((net_total * 0.22) + Number.EPSILON) * 100) / 100;

    qpr.net_total = net_total;
    qpr.vat_total = vat_total;
    
    this.currentQuotation.payment_rows.push(qpr);

    this.generatePaymentTable();

  }

  removePaymentRow($event,index){
    // console.log("$event",$event.value);
    
    this.quotationPaymentRows().removeAt(index);
    
    this.currentQuotation.payment_rows = this.quotationPaymentForm.value.payment_rows;

    let added_value = Number($event.value.value) / (this.currentQuotation.payment_rows.length > 2 ? this.currentQuotation.payment_rows.length -2: this.currentQuotation.payment_rows.length); 

    // console.log("added_value",added_value);

    for(let i=0;i<this.currentQuotation.payment_rows.length;i++){

      if(this.currentQuotation.payment_rows.length > 2) {

        if(i<2) continue;

      }

      let row = this.currentQuotation.payment_rows[i];
      // console.log("row.value PRE",row.value);

      row.value = added_value + Number(row.value);

      // console.log("row.value POST",row.value);

      let new_amount= (this.totalAmountNet / 100) * row.value;

      row.net_total = Math.round((new_amount + Number.EPSILON) * 100) / 100;

      row.vat_total = Math.round(((row.net_total * 0.22) + Number.EPSILON) * 100) / 100;

    }

    this.generatePaymentTable();

    //spalmare percentuale rimossa sulle righe rimaste
  }

  //#endregion pagamenti

  //#region fee

  public quotationFeeRows(): FormArray {
    return this.quotationFeesForm.get("fees") as FormArray
  }

  public quotationFeeSelectedType() {
    return this.quotationFeesForm.get("selected_type")
  }

  newQuotationFeeRow(): FormGroup {

    return this.formBuilder.group({
      name: [null],
      description: [null],
      note: [null],
      type: [],
      discount: [null],
      value: [null, [Validators.required, Validators.min(0)]],
      apply_discount: [false]
    })

  }

  addQuotationFeeRow(fg: FormGroup = null) {
    if(!fg)
      this.quotationFeeRows().push(this.newQuotationFeeRow());
    else
      this.quotationFeeRows().push(fg);
  }

  get qfsf() { return this.quotationFeesForm.controls; }

  quotationFeesFormIsValid(): Boolean {
    return this.quotationFeesForm.valid
  }

  async onQuotationFeesSubmit() {

    this.quotationFeesForm.markAllAsTouched();

    // console.log(this.quotationFeesForm);

    if (this.quotationFeesForm.invalid) {
      console.warn("form not valid");

      return;
    }

    let selected_type = this.quotationFeeSelectedType().value;

    let selected_fee: CustomFee = this.quotationFeesForm.value.fees.find(f => f.type == selected_type);

    //trasformo in una QuotationFeeRow avendo usato una CustomFee per generare il form
    let qFR = new QuotationFeeRow();

    qFR.fee_type = selected_fee.type;
    qFR.name = selected_fee.name;
    qFR.description = selected_fee.description;
    qFR.note = selected_fee.note;
    qFR.discount = selected_fee.discount;
    qFR.value = selected_fee.value;

    this.currentQuotation.fee_row = qFR;

    this.saveFormValue('quotationFeesForm', this.quotationFeesForm)

    this.generateLogisticTable();

    this.goToLogisticSelect();

  }

  getFeeTotal(): number {

    let total = 0;

    // calcolo canoni
    // prendo il canone selezionato
    let selected_type = this.quotationFeeSelectedType().value;

    // console.log("selected_type",selected_type);
    

    let customFee: CustomFee = this.quotationFeeRows().value.find(f => f.type == selected_type);//this.quotationFeesList.find(f => f.selected == true);

    if (customFee) {

      total += customFee.value;

    }

    return total;

  }


  async generateFeesTable() {

    // console.log("this.quotationFeeForm",this.quotationFeesForm.value);
    

    // console.log("generateFeesTable",this.currentQuotation.products);
    let firstYearValue: number = 0;

    this.quotationFeesList = []
    this.quotationFeeRows().clear()

    //prima mi calcolo tutti i canoni e poi ne aggiungo uno per ogni tipo (in questo caso primo anno e 1+4)
    const values = Object.values(QuotationFeeRowEnum);

    for (let i = 0; i < values.length; i++) {

      // console.log(value);

      let value = values[i];


      switch (value) {

        case QuotationFeeRowEnum.FIRST_YEAR:

          let firstYearFee = new CustomFee();

          firstYearFee.name = "Canone Primo Anno";
          firstYearFee.description = "";//"Comprende varie voci.";
          firstYearFee.discount = 0;
          firstYearFee.value = 0;
          firstYearFee.type = QuotationFeeRowEnum.FIRST_YEAR;
          // firstYearFee.selected = true;

          for (let i = 0; i < this.currentQuotation.products.length; i++) {

            // id del prodotto
            let productId = this.currentQuotation.products[i].product_id
            // qta del prodotto
            let productQty = this.currentQuotation.products[i].qty;
            // prodotto
            let product = this.availableProducts.find(p => p.id === productId)
            // accessori obbligatori
            let accessories_required = this.currentQuotation.products[i].accessories_required;
            // accessori opzionali
            let accessories = this.currentQuotation.products[i].accessories;

            // aggiungo i canoni dei prodotti calcolando solo quelli validi per il primo anno e facendo attenzione a multiply_by_qty
            if (product?.productFeeAssociations?.length > 0) {

              for (let i = 0; i < product.productFeeAssociations?.length; i++) {

                let ass: ProductFeeAssociation = product.productFeeAssociations[i];

                if (ass.fee_id && !ass.fee) {

                  // console.log("1",ass.fee);

                  ass.fee = await this.feeService.getById(ass.fee_id).toPromise()

                  // console.log("2", ass.fee);

                }

                // console.log("ass",ass, ass.fee?.year > 1);

                if (ass.fee?.year > 1) continue;

                if(ass.description) firstYearFee.description += "- " + ass.description + "<br>";

                if (ass.fee?.multiply_by_qty == true) {

                  // console.log(ass.value, productQty,ass.value * productQty);

                  firstYearFee.value += (Number(ass.value * productQty));

                }
                else {

                  // console.log(ass.value);

                  firstYearFee.value += Number(ass.value);

                }

              }

            }

            // aggiungo i fee degli accessori obbligatori
            if (accessories_required) {

              for (let i = 0; i < accessories_required.length; i++) {
                // seleziono l'id dell'accessorio
                const accId = accessories_required[i].accessory_id;
                // console.log("accId",accId,accessories_required[i]);
                
                //accessorio
                let accessory = this.availableProducts.find(p => p.id === accId)
                // console.log("this.availableProducts",this.availableProducts);
                
                // aggiungo i canoni dei accessori
                for (let i = 0; i < accessory?.productFeeAssociations?.length; i++) {


                  let ass: ProductFeeAssociation = accessory.productFeeAssociations[i];

                  if (ass.fee_id && !ass.fee) {

                    // console.log("1",ass.fee);

                    ass.fee = await this.feeService.getById(ass.fee_id).toPromise()

                    // console.log("2",ass.fee);

                  }

                  if (ass.fee?.year > 1) continue;

                  if(ass.description) firstYearFee.description += "- " + ass.description + "<br>";

                  if (ass.fee?.multiply_by_qty == true) {


                    firstYearFee.value += Number(ass.value) * Number(productQty);

                  }
                  else {

                    firstYearFee.value += Number(ass.value);

                  }

                }

              }
            }

            // aggiungo i fee degli accessori opzionali
            if (accessories) {

              for (let i = 0; i < accessories.length; i++) {
                // seleziono l'id dell'accessorio
                const accId = accessories[i].accessory_id;

                //accessorio
                let accessory = this.availableProducts.find(p => p.id === accId)

                // aggiungo i canoni dei accessori
                for (let i = 0; i < accessory?.productFeeAssociations?.length; i++) {

                  let ass: ProductFeeAssociation = accessory.productFeeAssociations[i];

                  if (ass.fee_id && !ass.fee) {

                    // console.log("1",ass.fee);

                    ass.fee = await this.feeService.getById(ass.fee_id).toPromise()

                    // console.log("2",ass.fee);

                  }

                  if (ass.fee?.year > 1) continue;

                  if(ass.description) firstYearFee.description += "- " + ass.description + "<br>";

                  if (ass.fee?.multiply_by_qty == true) {

                    firstYearFee.value += Number(ass.value) * Number(productQty);

                  }
                  else {

                    firstYearFee.value += Number(ass.value);

                  }

                }
              }
            }

          }

          //mi salvo il valore del canone del primo anno in modo da poterlo usare per lo sconto
          firstYearValue = firstYearFee.value;          

          if(firstYearFee.description.endsWith("<br>")) firstYearFee.description=firstYearFee.description.substring(0,firstYearFee.description.length-4)

          this.quotationFeesList = this.quotationFeesList.concat(firstYearFee);

          // console.log("this.quotationFeesList",this.quotationFeesList);

          break;

        case QuotationFeeRowEnum.FIVE_YEARS:

          let fiveYearFee = new CustomFee();

          fiveYearFee.name = "Canone 5 anni (1+4)";
          fiveYearFee.description = "";//"Comprende varie voci.";
          fiveYearFee.discount = 0;
          fiveYearFee.value = 0;
          fiveYearFee.type = QuotationFeeRowEnum.FIVE_YEARS;

          for (let i = 0; i < this.currentQuotation.products.length; i++) {

            // id del prodotto
            let productId = this.currentQuotation.products[i].product_id
            // qta del prodotto
            let productQty = this.currentQuotation.products[i].qty;
            // prodotto
            let product = this.availableProducts.find(p => p.id === productId)
            // accessori obbligatori
            let accessories_required = this.currentQuotation.products[i].accessories_required;
            // accessori opzionali
            let accessories = this.currentQuotation.products[i].accessories;

            // aggiungo i canoni dei prodotti calcolando solo quelli validi dal primo al quinto anno
            if (product?.productFeeAssociations?.length > 0) {

              for (let i = 0; i < product.productFeeAssociations.length; i++) {

                // console.log("qui");

                let ass: ProductFeeAssociation = product.productFeeAssociations[i];

                if (ass.fee_id && !ass.fee) {

                  // console.log("1",ass.fee);

                  ass.fee = await this.feeService.getById(ass.fee_id).toPromise()

                  // console.log("2",ass.fee);

                }

                // console.log("ass",ass, ass.fee?.year > 1);

                if (ass.fee?.year > 5) continue;

                if(ass.description) fiveYearFee.description += "- " + ass.description + "<br>";

                if (ass.fee?.multiply_by_qty == true) {

                  // console.log(ass.value, productQty,ass.value * productQty);

                  fiveYearFee.value += (Number(ass.value * productQty));

                }
                else {

                  // console.log(ass.value);

                  fiveYearFee.value += Number(ass.value);

                }

              }

            }

            // aggiungo i fee degli accessori obbligatori
            if (accessories_required) {

              for (let i = 0; i < accessories_required.length; i++) {
                // seleziono l'id dell'accessorio
                const accId = accessories_required[i].accessory_id;

                //accessorio
                let accessory = this.availableProducts.find(p => p.id === accId)

                // aggiungo i canoni dei accessori
                for (let i = 0; i < accessory?.productFeeAssociations?.length; i++) {

                  let ass: ProductFeeAssociation = accessory.productFeeAssociations[i];

                  if (ass.fee_id && !ass.fee) {

                    // console.log("1",ass.fee);

                    ass.fee = await this.feeService.getById(ass.fee_id).toPromise()

                    // console.log("2",ass.fee);

                  }

                  if (ass.fee?.year > 5) continue;

                  if(ass.description) fiveYearFee.description += "- " + ass.description + "<br>";

                  if (ass.fee?.multiply_by_qty == true) {


                    fiveYearFee.value += Number(ass.value) * Number(productQty);

                  }
                  else {

                    fiveYearFee.value += Number(ass.value);

                  }

                }

              }
            }

            // aggiungo i fee degli accessori opzionali
            if (accessories) {

              for (let i = 0; i < accessories.length; i++) {
                // seleziono l'id dell'accessorio
                const accId = accessories[i].accessory_id;

                //accessorio
                let accessory = this.availableProducts.find(p => p.id === accId)

                // aggiungo i canoni dei accessori
                for (let i = 0; i < accessory?.productFeeAssociations?.length; i++) {

                  let ass: ProductFeeAssociation = accessory.productFeeAssociations[i];

                  if (ass.fee_id && !ass.fee) {

                    // console.log("1",ass.fee);

                    ass.fee = await this.feeService.getById(ass.fee_id).toPromise()

                    // console.log("2",ass.fee);

                  }

                  if (ass.fee?.year > 5) continue;

                  if(ass.description) fiveYearFee.description += "- " + ass.description + "<br>";

                  if (ass.fee?.multiply_by_qty == true) {

                    fiveYearFee.value += Number(ass.value) * Number(productQty);

                  }
                  else {

                    fiveYearFee.value += Number(ass.value);

                  }

                }
              }
            }

          }

          fiveYearFee.value = firstYearValue + (fiveYearFee.value * 4);

          if(fiveYearFee.description.endsWith("<br>")) fiveYearFee.description= fiveYearFee.description.substring(0,fiveYearFee.description.length-4)

          this.quotationFeesList = this.quotationFeesList.concat(fiveYearFee)

          break;

      }

    }


    // popolo il form
    // console.log("quotationFeesList", this.quotationFeesList);

    for (const iterator of this.quotationFeesList) {
      this.addQuotationFeeRow();
    }

    this.quotationFeeRows().patchValue(this.quotationFeesList);

    let selected_type = this.quotationFeesForm.controls['selected_type'].value;

    if (selected_type == null) {
      this.quotationFeeSelectedType().patchValue(QuotationFeeRowEnum.FIRST_YEAR);
    }
    //

    setTimeout(() => {
      this.updateTotalAmount();
    }, 100);
  }

  async restoreFeesTable() {

    // console.log("restoreFeesTable");
    

    this.quotationFeeRows().clear();

    if (JSON.parse(localStorage.getItem('quotationFeesForm'))) {

      let storedObject: any = JSON.parse(localStorage.getItem('quotationFeesForm'));

      for(let i=0; i<storedObject.fees.length; i++){

        let fee=storedObject.fees[i];

        let fg : FormGroup = this.formBuilder.group({
          name: [fee.name],
          description: [fee.description],
          note: [fee.note],
          type: [fee.type],
          discount: [fee.discount],
          value: [fee.value, [Validators.required, Validators.min(0)]],
          apply_discount: [fee.apply_discount]
        });

        this.addQuotationFeeRow(fg);

      }


    }
    //

    setTimeout(() => {
      this.updateTotalAmount();
    }, 100);
  }

  selectFee(value) {

    // this.updateTotalAmount()

  }

  //#endregion fee

  //#region logistic

  get qlf() { return this.quotationLogisticForm.controls; }

  quotationLogisticFormIsValid(): Boolean {
    return this.quotationLogisticForm.valid && this.totalAmountNet > 0
  }

  async generateLogisticTable() {    

    this.quotationLogisticForm.controls["extimated_delivery_date"].setValue(this.extimated_delivery_date);
    this.quotationLogisticForm.controls["extimated_delivery_days"].setValue(this.extimated_delivery_days);
    this.quotationLogisticForm.controls["include_installation_hours"].setValue(this.include_installation_hours);
    this.quotationLogisticForm.controls["include_installation_hours_cost"].setValue(this.include_installation_hours_cost);
    this.quotationLogisticForm.controls["include_training_hours"].setValue(this.include_training_hours);
    this.quotationLogisticForm.controls["include_training_hours_cost"].setValue(this.include_training_hours_cost);
    this.quotationLogisticForm.controls["shipment_cost_euros"].setValue(this.shipment_cost_euros);

    if(this.include_installation_hours <= 0) {
      this.quotationLogisticForm.controls["installationByNightChecked"].setValue(false);
      this.quotationLogisticForm.controls["installationPreHolidayChecked"].setValue(false);
      this.quotationLogisticForm.controls["installationHolidayChecked"].setValue(false);
    }
    // this.quotationLogisticForm.setValue({
    //   extimated_delivery_days:this.extimated_delivery_days,
    //   include_installation_hours:this.include_installation_hours,
    //   installationChecked: [true],
    //   include_training_hours:this.include_training_hours,
    //   trainingChecked: [true],
    //   shipment_cost_euros:this.shipment_cost_euros,
    //   shipmentChecked: [true],
    // }, { emitEvent : false });

  }

  async onQuotationLogisticSubmit() {

    this.quotationLogisticForm.markAllAsTouched();

    // console.log(this.quotationLogisticForm);

    if (this.quotationLogisticForm.invalid) {
      console.warn("form not valid");

      return;
    }

    let logistic_row = new QuotationLogisticRow();

    logistic_row.extimated_delivery_date = new Date(this.quotationLogisticForm.value.extimated_delivery_date);
    logistic_row.extimated_delivery_days = this.quotationLogisticForm.value.extimated_delivery_days;
    logistic_row.include_installation_hours = this.quotationLogisticForm.value.installationChecked ? this.quotationLogisticForm.value.include_installation_hours : -1;
    logistic_row.include_installation_hours_cost = this.quotationLogisticForm.value.installationChecked ? this.quotationLogisticForm.value.include_installation_hours_cost : -1;
    logistic_row.installation_by_night_checked = this.quotationLogisticForm.value.installationChecked && this.quotationLogisticForm.value.include_installation_hours > 0 ? this.quotationLogisticForm.value.installationByNightChecked : false;
    logistic_row.installation_preholiday_checked = this.quotationLogisticForm.value.installationChecked && this.quotationLogisticForm.value.include_installation_hours > 0 ? this.quotationLogisticForm.value.installationPreHolidayChecked : false;
    logistic_row.installation_holiday_checked = this.quotationLogisticForm.value.installationChecked && this.quotationLogisticForm.value.include_installation_hours > 0 ? this.quotationLogisticForm.value.installationHolidayChecked : false;
    logistic_row.include_training_hours = this.quotationLogisticForm.value.trainingChecked ? this.quotationLogisticForm.value.include_training_hours : -1;
    logistic_row.include_training_hours_cost = this.quotationLogisticForm.value.trainingChecked ? this.quotationLogisticForm.value.include_training_hours_cost : -1;
    logistic_row.shipment_cost_euros = this.quotationLogisticForm.value.shipmentChecked ? this.quotationLogisticForm.value.shipment_cost_euros : -1;

    this.currentQuotation.logistic_row = logistic_row;

    // console.log("this.currentQuotation.logistic_row",this.currentQuotation.logistic_row);


    // console.log("this.currentQuotation", this.currentQuotation);

    this.saveFormValue('quotationLogisticForm', this.quotationLogisticForm)

    this.goToDiscountSelect()

  }

  //#endregion

  //#region select price list


  get qplf() { return this.quotatioPriceListForm.controls; }

  quotationPriceListFormIsValid(): Boolean {
    return this.quotatioPriceListForm.valid
  }

  async onPriceListSelect($event: any) {

    let selectedId = $event.detail.value;
    if (!selectedId) return
    // console.log("selectedId", selectedId);

    // this.form.controls['price_list_id'].setValue(selectedId);

    await this.productPriceListService.getProductPriceListByPriceListId(selectedId);
    await this.productPriceListService.getProductBasePriceListByPriceListId();

  }

  async onQuotationPriceListSubmit() {

    this.quotatioPriceListForm.markAllAsTouched();

    // console.log(this.quotatioPriceListForm);

    if (this.quotatioPriceListForm.invalid) {
      console.warn("form not valid");

      return;
    }

    // this.selectedPaymentMethod = this.quotationConfirmForm.value;

    // this.currentQuotation.price_list_id = this.quotationPaymentForm.value.price_list_id;



    // console.log("this.currentQuotation", this.currentQuotation);

    this.saveFormValue('quotatioPriceListForm', this.quotatioPriceListForm)

    //await this.quotationService.insert(quotation).toPromise();

    if(this.currentQuotation?.products?.length == 0 || (this.currentQuotation?.products == undefined && this.quotationProducts().length == 0)) {

      this.addProductToQuotation();

    }

    this.goToProductsSelect();

  }

  //#endregion

  //#region final discount

  get qdf() { return this.quotationDiscountForm.controls; }

  quotationDiscountFormIsValid(): Boolean {
    return this.quotationDiscountForm.valid && this.totalAmountNet > 0
  }

  async onQuotationDiscountSubmit() {

    this.quotationDiscountForm.markAllAsTouched();

    // console.log(this.quotationDiscountForm);

    if (this.quotationDiscountForm.invalid) {
      console.warn("form not valid");

      return;
    }


    // console.log("this.currentQuotation", this.currentQuotation);


    this.saveFormValue('quotationDiscountForm', this.quotationDiscountForm)

    this.goToSummary();


  }

  //#endregion

  //#region summary

  async onQuotationSummarySubmit() {

    // console.log("this.currentQuotation", this.currentQuotation);

    this.goToPaymentSelect()

    // reset form payment

    this.selectedPaymentMethod = null;

    this.quotationPaymentForm.reset();

    this.quotationPaymentRows().clear();

    // this.generatePaymentTable();

  }

  //#endregion

  //#region shopTypeForm

  get stf() { return this.shopTypeForm.controls; }

  shopTypeFormIsValid(): Boolean {
    return this.shopTypeForm.valid
  }

  async selectShop(shopId: string) {


    this.shopTypeForm.controls["shop_type_id"].setValue(shopId)
    // console.log("shop_type_id",shopId);
    

    // load products
    await this.quotationService.getAvailableProducts().toPromise().then(data => {
      // console.log("productService.getAll", data);    
      // prodotti non associati ad alcuna tipologia di negozio
      let notAssociatedProducts = data.filter(r => r.shop_type_association == null)
      let associatedProducts = data.filter(r => r?.shop_type_association?.includes(shopId))
      this.availableProducts = notAssociatedProducts.concat(associatedProducts);

      // console.log(this.availableProducts.length);


      this.currentQuotation.shop_type_id = this.shopTypeForm.value.shop_type_id;


      //this.goToCompanySelect();

    })

    
  }

  async onShopTypeFormSubmit(shopId) {
    console.log(shopId);

    this.shopTypeForm.controls['shop_type_id'].setValue(shopId);

    this.shopTypeForm.markAllAsTouched();

    // console.log(this.shopTypeForm);

    if (this.shopTypeForm.invalid) {
      console.warn("form not valid");
      return;
    }

    // console.log("The button's id is: " + shopId); this
    this.currentQuotation.shop_type_id = this.shopTypeForm.value.shop_type_id;

    this.saveFormValue("shopTypeForm", this.shopTypeForm)

    await this.selectShop(shopId)



  }

  //#endregion shopTypeForm

  //#region select company

  get cf() { return this.customerForm.controls; }

  customerFormIsValid(): Boolean {
    return this.customerForm.valid
  }

  async onCustomerSubmit(essentialInfos: boolean) {

    // console.log("onCustomerSubmit",this.selectedCompany);
    
    if(this.selectedCompany.id) {

      this.customerForm.markAllAsTouched();
  
      // console.log(this.customerForm);
  
      if (this.customerForm.invalid) {
        console.warn("form not valid");
        return;
      }

    }


    this.setShipmentDistance();

    this.currentQuotation.company = this.selectedCompany;

    this.currentQuotation.company_id = this.selectedCompany.id;

    this.currentQuotation.user_id = this.authService.currentUserValue.id;

    // console.log("this.currentQuotation", this.currentQuotation);

    this.saveFormValue('customerForm', this.customerForm)

    if(!essentialInfos) {

      this.goToPreviewSelect();
    }
    else {
      this.goToPriceListSelect();
    }

  }

  companySelectEvent($event: any) {
    // console.log("companySelectEvent", $event);
    this.selectedCompany = <Company>$event

    if(!this.selectedCompany) return;

    this.customerForm.controls['company_id'].setValue(this.selectedCompany.id);
    this.customerForm.controls['user_id'].setValue(this.authService.currentUserValue.id);


    this.checkCompanyDocumentOnGoingStatus().then(async result => {

      // console.log("checkCompanyDocumentOnGoingStatus", result);


      if (result && result.length > 0) {
        // ha documenti in sospeso

        const modal = await this.modalCtrl.create({
          component: CompanyDocumentListComponent,
          componentProps: {
            quotations: result
          }
        });
        modal.present();
      }
    })

  }

  companySaveEvent($event: any) {
    // console.log("companySaveEvent", $event);
    this.selectedCompany = <Company>$event
    
    this.customerForm.controls['company_id'].setValue(this.selectedCompany.id);
    this.customerForm.controls['user_id'].setValue(this.authService.currentUserValue.id);
  }

  //#endregion select company

  //#region preview

  get qpcf() { return this.previewConfirmForm.controls; }

  quotationPreviewConfirmFormIsValid(): Boolean {
    return this.previewConfirmForm.valid && this.totalAmountNet > 0
  }

  async onQuotationPreviewConfirmSubmit() {

    this.previewConfirmForm.markAllAsTouched();

    console.log(this.previewConfirmForm);

    if (this.previewConfirmForm.invalid) {
      console.warn("form not valid");

      return;
    }

    console.log("this.currentQuotation", this.currentQuotation);

    this.saveFormValue('previewConfirmForm', this.previewConfirmForm)

    // 

    try {

      // salvo la quotazione 
      localStorage.setItem("quotation", JSON.stringify(this.currentQuotation))


      let { id, number } = await this.quotationService.insert(this.currentQuotation).toPromise();

      console.log("quotationId", id);
      console.log("quotationNumber", number);


      // Geolocalizzo l'utente
      if (!this.currentPostion) {
        this.currentPostion = await this.locationService.getCurrentPosition()
      }
      await this.locationService.saveUserCurrentPosition('quotation_insert', this.currentPostion, id).toPromise()



      const alert = await this.alertController.create({
        header: `La richiesta di quotazione è stata inviata con successo, il suo numero è ${number}.`,
        buttons: ['OK'],
        backdropDismiss: false
      });

      await alert.present();

      this.removeStoredQuotation();

      //reset form

      this.router.navigate(['/quotation'], { queryParams: { hl: id, reload:true } });

    } catch (error) {

      const alert = await this.alertController.create({
        header: 'Non è possibile inviare la quotazione a causa di un errore',
        buttons: ['OK'],
        backdropDismiss: false
      });

      await alert.present();

    } finally {
      this.hideLoading()
    }



  }

  //#endregion

  //#region navigation

  goToActivityTypeSelect() {
    this.router.navigate(['/quotation-wizard/activity-type-select'], {});
  }

  goToCompanySelect() {
    this.router.navigate(['/quotation-wizard/company-select'], {});
  }

  goToCompanyEssential() {
    this.router.navigate(['/quotation-wizard/company-essential'], {});
  }

  goToPriceListSelect() {
    this.router.navigate(['/quotation-wizard/price-list-select'], {});
  }

  goToProductsSelect() {
    this.router.navigate(['/quotation-wizard/products-select'], {});
  }

  goToFeeSelect() {
    this.router.navigate(['/quotation-wizard/fees-select'], {});
  }

  goToConfirmSelect() {
    this.router.navigate(['/quotation-wizard/confirm-select'], {});
  }

  goToDiscountSelect() {
    this.router.navigate(['/quotation-wizard/discount-select'], {});
  }

  goToSummary() {
    this.router.navigate(['/quotation-wizard/summary'], {});
  }

  goToLogisticSelect() {
    this.router.navigate(['/quotation-wizard/logistics-select'], {});
  }

  goToPaymentSelect() {
    this.router.navigate(['/quotation-wizard/payment-select'], {});
  }

  goToCompanyComplete() {
    this.router.navigate(['/quotation-wizard/company-complete'], {});
  }

  goToPreviewSelect() {
    this.router.navigate(['/quotation-wizard/preview-select'], {});
  }

  //#endregion navigation

  //#region loading
  public loading: any;

  public async showLoading(message: string = null) {
    this.loading = await this.loadingCtrl.create({
      message: message ?? 'Loading...',
      backdropDismiss: false
    });

    this.loading.present()

    // setTimeout(() => {
    //   this.hideLoading()
    // }, 2000);
  }

  public async hideLoading() {
    this.loading?.dismiss()
  }

  //#endregion loading

  // async restoreSavedQuotation() {

  // this.currentQuotation = JSON.parse(localStorage.getItem("quotation"))

  // console.log("currentQuotation", this.currentQuotation);


  // if (!this.currentQuotation) return;


  // this.shopTypeForm.controls['shop_type_id'].setValue(this.currentQuotation.shop_type_id);


  // if (this.currentQuotation.company_id) {
  //   this.selectedCompany = await this.companyService.getById(this.currentQuotation.company_id).toPromise();
  //   this.customerForm.controls['company_id'].setValue(this.selectedCompany.id);
  //   this.customerForm.patchValue(this.currentQuotation);
  // }


  // if (this.currentQuotation?.products && this.currentQuotation.products.length > 0) {

  //   console.log(this.currentQuotation.products);

  //   for (const prod of this.currentQuotation.products) {


  //     let formAccessories : Array<any> = []

  //     for (const acc of prod.accessories) {


  //       let accControl: FormGroup = this.formBuilder.group({
  //         accessory_id: [acc.accessory_id, Validators.required],
  //         description: [acc.description],
  //         qty: [acc.qty, [Validators.required, Validators.min(1)]],
  //         price: [acc.price],
  //         note: [acc.note],
  //         discount: [acc.discount, [Validators.min(0)]]
  //       });

  //       //   accControl.controls["accessory_id"].setValue(acc.accessory_id);
  //       //   accControl.controls["description"].setValue(acc.description);
  //       //   accControl.controls["qty"].setValue(acc.qty);
  //       //   accControl.controls["price"].setValue(acc.price);
  //       //   // accControl.controls["note"].setValue(acc.note);
  //       //   accControl.controls["discount"].setValue(acc.discount);

  //       formAccessories.push(accControl)

  //     }

  //     let formAccessoriesRequired : Array<any> = []

  //     for (const acc of prod.accessories_required) {


  //       let accControl: FormGroup = this.formBuilder.group({
  //         accessory_id: [acc.accessory_id, Validators.required],
  //         description: [acc.description],
  //         qty: [acc.qty, [Validators.required, Validators.min(1)]],
  //         price: [acc.price],
  //         note: [acc.note],
  //         discount: [acc.discount, [Validators.min(0)]]
  //       });

  //     //   accControl.controls["accessory_id"].setValue(acc.accessory_id);
  //     //   accControl.controls["description"].setValue(acc.description);
  //     //   accControl.controls["qty"].setValue(acc.qty);
  //     //   accControl.controls["price"].setValue(acc.price);
  //     //   // accControl.controls["note"].setValue(acc.note);
  //     //   accControl.controls["discount"].setValue(acc.discount);


  //       formAccessoriesRequired.push(accControl)

  //     }

  //     let productControl: FormGroup = this.formBuilder.group({
  //       product_id: [prod.product_id, Validators.required],
  //       description: [prod.description],
  //       qty: [prod.qty, [Validators.required, Validators.min(1)]],
  //       accessories: this.formBuilder.array(formAccessories),
  //       accessories_required: this.formBuilder.array(formAccessoriesRequired),
  //       price: [prod.price],
  //       note: [prod.note],
  //       discount: [prod.discount, [Validators.min(0)]]
  //     })

  //     // productControl.controls["product_id"].setValue(prod.product_id);
  //     // productControl.controls["description"].setValue(prod.description);
  //     // productControl.controls["qty"].setValue(prod.qty);

  //     // productControl.controls["accessories"].setValue(prod.accessories);
  //     // productControl.controls["accessories_required"].setValue(prod.accessories_required);
  //     // productControl.controls["price"].setValue(prod.price);
  //     // productControl.controls["note"].setValue(prod.);
  //     // productControl.controls["discount"].setValue(prod.discount);
  //     // fb.controls["accessory_id"].disable()

  //     // console.log("add control",prod, prod.product_id, productControl);



  //     console.log(productControl);


  //     this.quotationProducts().push(productControl);

  //   }

  //   // this.quotationProducts().patchValue(this.currentQuotation.products)


  //   // this.quotationForm.setValue({
  //   //   products : this.currentQuotation.products
  //   // });

  //   // console.log(this.quotationForm.value.products);

  // }


  // // this.quotationForm.patchValue(this.currentQuotation.products);

  // this.quotationConfirmForm.patchValue(this.currentQuotation);


  // this.generatePaymentTable();


  // this.generateFeesTable();



  /*
  public customerForm: FormGroup;  
public quotationForm: FormGroup;
public quotationConfirmForm: FormGroup;
public policyForm: FormGroup;
  */

  // }










  // restoreQuotationFeesForm(){

  //   console.log("restoreQuotationFeesForm", this.currentQuotation);



  // }




}
