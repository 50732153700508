import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Address } from '../class/address.class';
import { Coordinate } from '../class/coordinate.class';
import { add } from 'date-fns';


@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(private http: HttpClient) {

  }

  getAll() {

    return this.http.get<Array<Address>>(`${environment.api_site}/address`);

  }

  getById(id: string) {

    return this.http.get<Address>(`${environment.api_site}/address/${id}`);

  }

  insert(data: Address) {

    return this.http.post<Address>(`${environment.api_site}/address/`, data);

  }

  update(data: Address) {

    return this.http.put<Address>(`${environment.api_site}/address/${data.id}`, data);

  }


  async autocompleteByAddress(address: string) : Promise<Array<any>> {

    // console.log("autocompleteByAddress", address);


      var gMapsService = new google.maps.places.AutocompleteService();

      return new Promise((resolve, reject) => {

      gMapsService.getPlacePredictions({
        input: address,
        componentRestrictions: {
          country: 'IT'
        }
      },
        async(predictions) => {

          
          // console.log("predictions", predictions);
          
          
          if (predictions != null) {
            
            // console.log(predictions);
            let autocompleteItems :Array<any> = []

            for (const prediction of predictions) {
              
              let latlng: any = await this.geoCode(prediction.description);
              const place = {
                title: prediction.structured_formatting.main_text,
                address: prediction.description,
                lat: latlng.lat,
                lng: latlng.lng
              }
  
              autocompleteItems.push(place);
            }

            // predictions.forEach(async (prediction) => {
              
            // })
            
            // console.log(autocompleteItems);
            
            resolve(autocompleteItems)
            
            // return autocompleteItems

          }


          // console.log(autocompleteItems);
          

        });

    });


  }


  public geoCode(address: any) {
    let latlng = { lat: 0, lng: 0 }

    return new Promise((resolve, reject) => {

      let geocoder = new google.maps.Geocoder()

      geocoder.geocode({ 'address': address }, (results) => {

        latlng.lat = results[0].geometry.location.lat()

        latlng.lng = results[0].geometry.location.lng()

        resolve(latlng)

      })
    })
  }


  async reverseGeocodeByAddress(address: string): Promise<Address> {

    console.log("reverseGeocodeByAddress", address);


    var geocoder = new google.maps.Geocoder();

    if (address !== undefined) {



      return new Promise(async (resolve, reject) => {

        // if(!address.includes("italia")) address = address + ", italia"

        await geocoder.geocode({ address: address }, (result, status) => {

          if (status !== 'OK') {
            resolve(null)
          }

          // console.log(status);

          // console.log(result);

          let foundedAddress = new Address();

          if (result[0]) {

            // foundedAddress.number = result[0].address_components['0'].long_name

            // foundedAddress.street =  result[0].address_components['1'].long_name

            // foundedAddress.city =  result[0].address_components['2'].long_name

            // foundedAddress.province =  result[0].address_components['4'].short_name

            // foundedAddress.nation =  result[0].address_components['6'].short_name

            // foundedAddress.postal_code =  result[0].address_components['7'].long_name

            //foundedAddress.lat_lon = coord

            foundedAddress.number = this.extractDataFromGR(result, "street_number")

            foundedAddress.street = this.extractDataFromGR(result, "route")

            foundedAddress.city = this.extractDataFromGR(result, "administrative_area_level_3")

            foundedAddress.province = this.extractDataFromGR(result, "administrative_area_level_2")

            foundedAddress.nation = this.extractDataFromGR(result, "country")

            foundedAddress.postal_code = this.extractDataFromGR(result, "postal_code")

            foundedAddress.region = this.extractDataFromGR(result, "administrative_area_level_1")?.toLowerCase()

            // foundedAddress.lat_lon = coord

            console.log(foundedAddress);

            resolve(foundedAddress)

          }



        });

      });

    }

  }

  async reverseGeocodeByCoordinate(coord: Coordinate): Promise<Address> {


    var geocoder = new google.maps.Geocoder();

    if (coord !== undefined) {

      var LatLng = new google.maps.LatLng(coord.lat, coord.lng);

      return new Promise(async (resolve, reject) => {

        await geocoder.geocode({ location: LatLng }, (result, status) => {

          if (status !== 'OK') {
            resolve(null)
          }

          // console.log(status);

          // console.log(result);

          let foundedAddress = new Address();

          if (result[0]) {

            foundedAddress.number = this.extractDataFromGR(result, "street_number")

            foundedAddress.street = this.extractDataFromGR(result, "route")

            foundedAddress.city = this.extractDataFromGR(result, "locality")

            foundedAddress.province = this.extractDataFromGR(result, "administrative_area_level_2")

            foundedAddress.nation = this.extractDataFromGR(result, "country")

            foundedAddress.region = this.extractDataFromGR(result, "administrative_area_level_1")?.toLowerCase()

            foundedAddress.postal_code = this.extractDataFromGR(result, "postal_code")

            foundedAddress.lat_lon = coord

            // console.log(foundedAddress);


            resolve(foundedAddress)

          }



        });

      });

    }
  }


  extractDataFromGR(data: google.maps.GeocoderResult[], name: string): string {

    for (const item of data) {

      let address_components = item.address_components;

      for (const ac of address_components) {

        let type = ac.types[0];

        if (type === name) {
          return ac.short_name
        }

      }

    }

    return '';

  }

}
