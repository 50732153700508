import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'quotation-product-accessory-array',
  templateUrl: './quotation-product-accessory-array.component.html',
  styleUrls: ['./quotation-product-accessory-array.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class QuotationProductAccessoryArrayComponent implements OnDestroy {

  private internalFormArray: FormArray;

  @Input()
  set formArray(formArray: FormArray) {
    this.internalFormArray = formArray;

    if (this.subscriptions.formArrayChanges) {
      this.subscriptions.formArrayChanges.unsubscribe();
    }
    // this will work around the change detection on push limitations
    this.subscriptions.formArrayChanges = this.formArray.valueChanges.subscribe(
      result => {
        this.changeDetectorRef.detectChanges();
      }
    );
  }
  get formArray() {
    return this.internalFormArray;
  }


  // @Input()
  // public showAddBtn : boolean = false;

  

  // @Input()
  // public dataRequired: Array<Product> = [];


  // @Output() 
  // add = new EventEmitter<FormArray>();

  private subscriptions: { [key: string]: Subscription } = {};

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder) {
      
    }


    
    ngOnDestroy() {
      Object.keys(this.subscriptions).forEach(sk =>
        this.subscriptions[sk].unsubscribe()
      );
    }
  
    remove(formGroup: FormGroup) {
      this.formArray.removeAt(this.formArray.controls.indexOf(formGroup));
    }
    

}
