import { Component, HostListener, Input } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it'
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  @Input()
  public reduced: boolean = true;
  
  // per l'integrazione delle icon fontawesome4
  // segui https://medium.com/@iamserverr/font-awesome-with-ionic-5-angular-3fcc335cd391

  public appPages = [];

  anonymousPages = [  
    { title: 'Login', url: '/login', icon: 'sign-in' }
  ];

  loggedInPages = [  
    { title: 'Home', url: '/home', icon: 'home' },
    // { title: 'Dashboard', url: '/dashboard', icon: 'gauge-simple-high' },
    { title: 'Preventivi', url: '/quotation', icon: 'inbox' },
    { title: 'Prodotti', url: '/product', icon: 'boxes-stacked' },
    { title: 'Aziende', url: '/company', icon: 'users' },
    // { title: 'Aziende CRM', url: '/company', params : {list: 'crm'}, icon: 'users' },
    // { title : 'Crea Preventivo', url: '/quotation-wizard', icon : 'file-contract'}
  ];








  constructor(
    public authService : AuthService
  ) {

    this.loadGoogleMapsScript();

    registerLocaleData(localeIt, 'it');
    
    this.authService.currentUser.subscribe(data=> {
      // console.log("currentUser.subscribe", data);
      

      if(data !== null){ // user logged
        this.appPages = this.loggedInPages;
      } else {
        this.appPages = this.anonymousPages;
      }
      
    })

  }


  public loadGoogleMapsScript() : Promise<boolean> {

    return new Promise((resolve,reject)=> {    

      console.log('preparing to load...')
      let node = document.createElement('script');
      node.src = `https://maps.googleapis.com/maps/api/js?key=${environment.gmapsKey}&libraries=places,geometry`;
      node.type = 'text/javascript';
      node.async = true;
      node.charset = 'utf-8';
      node.onload = ()=>{
        resolve(true)
      }
      node.onerror = () => {
        console.error('Error occurred while loading googleapis');
        reject(false)
      };
      document.getElementsByTagName('head')[0].appendChild(node);

    });

  }


}
