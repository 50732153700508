// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item, ion-select {
  --background: white;
  --padding:0px;
  --inner-padding-end: 0px;
}

p:has(fa-icon) {
  color: var(--ion-color-primary);
  font-size: 26px;
  margin: 0px;
  margin-bottom: 5px;
}
p:has(fa-icon) fa-icon {
  font-size: 20px;
  margin-right: 5px;
}

ion-select {
  margin-top: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/form/quotation/payment/quotation-payment/quotation-payment.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,aAAA;EACA,wBAAA;AACJ;;AAEA;EACI,+BAAA;EAEA,eAAA;EAEA,WAAA;EAEA,kBAAA;AAFJ;AAII;EACI,eAAA;EACA,iBAAA;AAFR;;AAMA;EACI,0BAAA;AAHJ","sourcesContent":["ion-item, ion-select{\n    --background: white;\n    --padding:0px;\n    --inner-padding-end: 0px;\n\n}\np:has(fa-icon){\n    color:var(--ion-color-primary);\n\n    font-size: 26px;\n\n    margin: 0px;\n\n    margin-bottom: 5px;\n\n    fa-icon{\n        font-size: 20px;\n        margin-right: 5px;\n    }\n}\n\nion-select{\n    margin-top: 0px !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
