// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content::part(scroll) {
  background: white;
}

ion-list {
  background: white;
}

ion-item {
  border-radius: 10px;
  margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal/select-item/modal-select.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,kBAAA;AACJ","sourcesContent":["ion-content::part(scroll){\n    background: white;\n}\n\nion-list{\n    background: white;\n}\n\nion-item{\n    border-radius: 10px;\n    margin-bottom: 5px;    \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
