import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Company } from '../class/company.class';
import { Supplier } from '../class/supplier.class';
import { Fee } from '../class/fee.class';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeeService {

  constructor(private http: HttpClient) { 
    
  }


  getAll() {
    
    return this.http.get<Array<Fee>>(`${environment.api_site}/fee`);    

  }

  getById(id: string): Observable<Fee> {
    
    return this.http.get<Fee>(`${environment.api_site}/fee/${id}`);    

  }

  insert(data: Fee) {
    
    return this.http.post<Fee>(`${environment.api_site}/fee/`, data);    

  }

  update(data: Fee) {
    
    return this.http.put<Fee>(`${environment.api_site}/fee/${data.id}`, data);    

  }
}
