import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import * as moment from 'moment'

import { Geolocation, Geoposition } from '@awesome-cordova-plugins/geolocation/ngx';
import { Capacitor } from '@capacitor/core';
import { Coordinate } from '../class/coordinate.class';

@Injectable({
  providedIn: 'root'
})
export class LocationService {


  constructor(
    private geolocation: Geolocation,
    private http: HttpClient) { 

  }


  // xxsaveUserCurrentPosition(status: string, quotation_id : string = null) : Promise<Boolean> {

  //   console.log("saveUserCurrentPosition");
    

  //   // // verifica che utente ha dato il permesso 
  //   // let permissionRequest : GeolocationPermissionType 

  //   // permissionReques
  //   var geolocationOptions = {
  //     enableHighAccuracy: true,
  //     timeout: 5000,
  //     maximumAge: 0
  //   };

  //   return new Promise(async (resolve, reject) => {

  //     this.geolocation.getCurrentPosition(geolocationOptions).then(async data => {
  //       console.log(data.coords);     
        
  //       let body = {
  //         latitude : data.coords.latitude,
  //         longitude : data.coords.longitude,
  //         accuracy : data.coords.accuracy,
  //         altitude : data.coords.altitude,
  //         altitudeAccuracy : data.coords.altitudeAccuracy,
  //         heading : data.coords.heading,
  //         speed : data.coords.speed,
  //         status : status,
  //         quotation_id : quotation_id
  //       }
        
  //       await this.http.post(`${environment.api_site}/user/position`,body).toPromise();


  //       resolve(true);  
  //     }).catch(err => {
  //       console.error(err);
  //       reject(false);
  //     })

  //   })

  // }


  saveUserCurrentPosition(status: string, positionData : Geoposition, quotation_id : string = null) {

    // console.log("saveUserCurrentPosition");

    let body = {
      latitude : positionData.coords.latitude,
      longitude : positionData.coords.longitude,
      accuracy : positionData.coords.accuracy,
      altitude : positionData.coords.altitude,
      altitudeAccuracy : positionData.coords.altitudeAccuracy,
      heading : positionData.coords.heading,
      speed : positionData.coords.speed,
      status : status,
      quotation_id : quotation_id
    }
    
    return this.http.post(`${environment.api_site}/user/position`, body);

  }



    async getCurrentPosition() : Promise<Geoposition> {

      // console.log("saveUserCurrentPosition");
      
  
      // // verifica che utente ha dato il permesso 
      // let permissionRequest : GeolocationPermissionType 
  
      // permissionReques
      var geolocationOptions = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };
  
      return new Promise(async (resolve, reject) => {
  
        this.geolocation.getCurrentPosition(geolocationOptions).then(async data => {
          // console.log(data.coords);     


          
          // let body = {
          //   lat : data.coords.latitude,
          //   lng : data.coords.longitude,
          //   accuracy : data.coords.accuracy,
          //   altitude : data.coords.altitude,
          //   altitudeAccuracy : data.coords.altitudeAccuracy,
          //   heading : data.coords.heading,
          //   speed : data.coords.speed
          // }
          
  
          resolve(data);  

        }).catch(err => {
          console.error(err);
          reject(null);
        })
  
      })



  }

  

  getKilometersDistanceBetweenTwoCoordinates(coord: Coordinate = null): number {

    if(!coord || coord.lat == null || coord.lng == null) return -1;

    const myOffice = new google.maps.LatLng(44.8192898, 10.8821067); //parametrizzare le coordinate

    const shipmentPlace = new google.maps.LatLng(coord.lat, coord.lng);

    const distance = google.maps.geometry.spherical.computeDistanceBetween(myOffice, shipmentPlace);

    // console.log("distance", distance / 1000);

    return (Number(distance) / 1000) || -1;
    


  }

    

}
