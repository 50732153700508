import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { QuotationWizardService } from '../../../../../services/quotation-wizard.service';

@Component({
  selector: 'quotation-fee-array',
  templateUrl: './quotation-fee-array.component.html',
  styleUrls: ['./quotation-fee-array.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuotationFeeArrayComponent implements OnDestroy {

  private internalFormArray: FormArray;

  

  @Input()
  set formArray(formArray: FormArray) {
    this.internalFormArray = formArray;

    if (this.subscriptions.formArrayChanges) {
      this.subscriptions.formArrayChanges.unsubscribe();
    }
    // this will work around the change detection on push limitations
    this.subscriptions.formArrayChanges = this.formArray.valueChanges.subscribe(
      result => {
        this.changeDetectorRef.detectChanges();
      }
    );
  }
  get formArray() {
    return this.internalFormArray;
  }

  // @Output() 
  // add = new EventEmitter<FormArray>();

  private subscriptions: { [key: string]: Subscription } = {};

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder) {
      
    }

    ngOnDestroy() {
      Object.keys(this.subscriptions).forEach(sk =>
        this.subscriptions[sk].unsubscribe()
      );
    }

}
