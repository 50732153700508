import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActionSheetOptions, AlertController, ModalController } from '@ionic/angular';
import { Address, AddressType } from 'src/app/class/address.class';
import { Coordinate } from 'src/app/class/coordinate.class';
import { AddressService } from 'src/app/services/address.service';
import { LocationService } from 'src/app/services/location.service';
import { AddressFindByInputComponent } from '../modal/address-find-by-input/address-find-by-input.component';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnChanges {


  // display: any;
  // center: google.maps.LatLngLiteral = {
  //   lat: 24,
  //   lng: 12
  // };
  // zoom = 13;

  // markerOptions: google.maps.MarkerOptions = {
  //   draggable: false
  // };
  // markerPosition: google.maps.LatLngLiteral;


  public form: FormGroup;

  // @Input()
  // public data: Address;

  @Input()
  public type: AddressType;

  @Output()
  public addressEvent = new EventEmitter<Address>();
  
  @Input()
  public disableEssentialInfos: boolean = false;

  @Input()
  public edit: boolean = false;

  public title :string = "";

  @Input()
  public address : Address | null;

  @Input()
  public companyId : string | null;
  
  constructor(
    private modalCtrl : ModalController,
    private formBuilder: FormBuilder,
    private addressService : AddressService,
    private locationService : LocationService,
    private alertController: AlertController
    ) { 

      

    this.form = this.formBuilder.group({
      id: [],
      city:['', Validators.required],
      nation:['it', Validators.required],
      street:['', Validators.required],
      number:['', [Validators.required]],
      postal_code:['', [Validators.required, Validators.pattern(/^[0-9]{5}$/)]],
      province:['', Validators.required],
      region:['', Validators.required],
      lat_lon:[null],
      type:[null, Validators.required],
      company_id:[null]
    });
    
    this.form.statusChanges.subscribe(changes => {
      // console.log("changes", changes);
      // console.log(this.form);
      
      
    })

  }

  async ngOnChanges() {

    // console.log('ngonint',this.address);

    switch (this.type) {
      case AddressType.operational_headquarters:
          this.title = "Sede operativa";
        break; 
        case AddressType.registered_office:
          this.title = "Sede legale";
          break;     
      default:
        break;
    }

    this.form.controls['type'].setValue(this.type);

    if(this.address){
     this.formPatch();

    } 
    
    // else if (this.data?.id) {
    //   // load address by api
    //   this.address = await this.addressService.getById(this.data.id).toPromise();

    // } else {
    //   // nulla

    // }

  }

  get f() { return this.form.controls; }


  formPatch(){
    this.form.controls['id'].setValue(this.address.id);
    this.form.controls['city'].setValue(this.address.city);
    this.form.controls['nation'].setValue(this.address.nation);
    this.form.controls['street'].setValue(this.address.street);
    this.form.controls['number'].setValue(this.address.number);
    this.form.controls['postal_code'].setValue(this.address.postal_code);
    this.form.controls['province'].setValue(this.address.province);
    this.form.controls['region'].setValue(this.address.region);
    this.form.controls['lat_lon'].setValue(this.address.lat_lon);
    this.form.controls['company_id'].setValue(this.address.company_id || this.companyId);
    this.address.type = this.type
    this.form.controls['type'].setValue(this.address.type);

    // this.center = this.address.lat_lon
  }

  async formSync(){


    if(this.address.id){
      // edit  
      this.address = await this.addressService.update(this.address).toPromise();      

    } else {
      // insert  
      this.address = await this.addressService.insert(this.address).toPromise();

    }

    // console.log("formSync", this.address);
    

    this.addressEvent.emit(this.address);
  }

  async submit() : Promise<Boolean> {

    // console.log("address submit");
    

    this.form.markAllAsTouched();

    this.address = <Address>this.form.value;

    // console.log(this.address);

    if(this.form.invalid){
      return;
    }

    try {
      

      await this.formSync()


      return true

      
    } catch (error) {

      console.error(error);
      

      return false
      
    }
    
  }


  


  


  async askForResearch(){


    const modal = await this.modalCtrl.create({
      component: AddressFindByInputComponent
    });
    modal.present();

    let { data, role } = await modal.onWillDismiss();    

    //console.log(data, role);
    

    if (role === 'address') {  
      // console.log('address', data);

      this.address = data
      
      this.formPatch();


      // let foundedAddress = new Address();

      // // foundedAddress.number = result[0].address_components['0'].long_name

      // // foundedAddress.street =  result[0].address_components['1'].long_name

      // // foundedAddress.city =  result[0].address_components['2'].long_name

      // // foundedAddress.province =  result[0].address_components['4'].short_name

      // // foundedAddress.nation =  result[0].address_components['6'].short_name

      // // foundedAddress.postal_code =  result[0].address_components['7'].long_name

      // //foundedAddress.lat_lon = coord

      // foundedAddress.number = this.extractDataFromGR(result, "street_number")

      // foundedAddress.street = this.extractDataFromGR(result, "route")

      // foundedAddress.city = this.extractDataFromGR(result, "administrative_area_level_3")

      // foundedAddress.province = this.extractDataFromGR(result, "administrative_area_level_2")

      // foundedAddress.nation = this.extractDataFromGR(result, "country")

      // foundedAddress.postal_code = this.extractDataFromGR(result, "postal_code")
      

    }

    // let { data, role } = await modal.onWillDismiss();    

    // const alert = await this.alertController.create({
    //   header: 'Alert',
    //   subHeader: 'Trova indirizzo',
    //   message: 'Digita l\'indirizzo che vuoi inserire',      
    //   inputs : [{
    //     name : 'address',
    //     placeholder: 'Digita l\'indirizzo',
    //   }],
    //   buttons: [
    //     {
    //         text: 'Cancel',
    //         role: 'cancel',
    //         cssClass: 'secondary',
    //         handler: () => {
    //             console.log('Confirm Cancel');
    //         }
    //     }, 
    //     {
    //         text: 'Cerca',
    //         handler: async (data) => {
    //             console.log(data);

    //             let searchForAddress = data?.address;

    //             if(!searchForAddress) return;

    //             let foundedAddress =  await this.addressService.reverseGeocodeByAddress(searchForAddress)

    //             // console.log(foundedAddress);    

    //             this.address = foundedAddress

    //             this.formPatch();

    //             // this.formSync()

                
    
    //         }
    //     }
    // ]
    // });

    // await alert.present();

    // const result = await alert.onDidDismiss();

    // let searchForAddress = result?.data?.values?.address;

    // if(!searchForAddress) return;

    // let foundedAddress =  await this.addressService.reverseGeocodeByAddress(searchForAddress)

    // console.log(foundedAddress);    

    // this.address = foundedAddress

    // this.formPatch();

    // this.formSync()
  

    
  }


  setRegion($event : any){

    let selectedId = $event.detail.value;
    if (!selectedId) return
    console.log("selectedId", selectedId);


    this.form.controls['region'].setValue(selectedId);
  }


  public actionSheetRegionOptions: ActionSheetOptions = {
    header : 'Lista regioni',
    buttons: []    
  }

}
