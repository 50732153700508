import { Component, Input, Output, EventEmitter } from '@angular/core';
import type { OnInit } from '@angular/core';
import { ModalType } from './modal-type';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'modal-select',
  templateUrl: 'modal-select.component.html',
  styleUrls: ['./modal-select.component.scss']
})
export class ModalSelectComponent implements OnInit {
  @Input() items: ModalType[] = [];
  @Input() selectedItem: string;
  @Input() title = 'Select Items';

  filteredItems: ModalType[] = [];
  workingSelectedValue: string;

  constructor(public modalCtrl: ModalController){}

  ngOnInit() {
    this.filteredItems = [...this.items];
    this.workingSelectedValue = this.selectedItem;
  }

  trackItems(index: number, item: ModalType) {
    return item.value;
  }

  async cancel() {
    await this.modalCtrl.dismiss(null);
  }

  async confirm() {
    await this.modalCtrl.dismiss(this.workingSelectedValue);
  }

  searchbarInput(ev) {
    this.filterList(ev.target.value);
  }

  /**
   * Update the rendered view with
   * the provided search query. If no
   * query is provided, all data
   * will be rendered.
   */
  filterList(searchQuery: string | undefined) {
    /**
     * If no search query is defined,
     * return all options.
     */
    if (searchQuery === undefined) {
      this.filteredItems = [...this.items];
    } else {
      /**
       * Otherwise, normalize the search
       * query and check to see which items
       * contain the search query as a substring.
       */
      const normalizedQuery = searchQuery.toLowerCase();
      this.filteredItems = this.items.filter((item) => {
        return item.text.toLowerCase().includes(normalizedQuery);
      });
    }
  }

//   isChecked(value: string) {
//     return this.workingSelectedValues.find((item) => item === value);
//   }

  checkboxChange(ev) {
    const { checked, value } = ev.detail;

    // console.log("checkboxChange",value);
    
    
    this.workingSelectedValue = value;
  }
}