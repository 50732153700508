// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-col:has(form) {
  display: flex;
  flex-direction: column;
}

form {
  min-height: 350px;
  /* display: flex; */
  /* flex-direction: column; */
  padding: 20px;
  border: 2px solid var(--ion-color-primary);
  border-radius: 20px;
  height: 100%;
  background-color: white;
  color: var(--ion-color-primary);
}
form ion-text > .value {
  font-weight: bold;
  font-size: 24px;
}
form h2.old-price {
  -webkit-text-decoration: line-through var(--ion-color-secondary);
          text-decoration: line-through var(--ion-color-secondary);
  font-size: 18px !important;
  text-decoration-thickness: 3px;
}

form.selected-fee {
  background-color: var(--ion-color-primary);
  color: white;
  border: 2px solid white;
  box-shadow: 5px 5px 8px var(--ion-color-primary);
}
form.selected-fee .btn-discount {
  color: var(--ion-color-primary);
  --background: white;
}`, "",{"version":3,"sources":["webpack://./src/app/components/form/quotation/fee/quotation-fee/quotation-fee.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,mBAAA;EACA,4BAAA;EACA,aAAA;EACA,0CAAA;EACA,mBAAA;EACA,YAAA;EAOA,uBAAA;EACA,+BAAA;AALJ;AADI;EACI,iBAAA;EACA,eAAA;AAGR;AAGI;EACI,gEAAA;UAAA,wDAAA;EACA,0BAAA;EACA,8BAAA;AADR;;AAKA;EACI,0CAAA;EACA,YAAA;EACA,uBAAA;EAEA,gDAAA;AAHJ;AAKI;EACI,+BAAA;EACA,mBAAA;AAHR","sourcesContent":["ion-col:has(form) {\r\n    display: flex; \r\n    flex-direction: column;\r\n}\r\n\r\nform {\r\n    min-height: 350px;\r\n    /* display: flex; */\r\n    /* flex-direction: column; */\r\n    padding: 20px;\r\n    border: 2px solid var(--ion-color-primary);\r\n    border-radius: 20px;\r\n    height: 100%;\r\n\r\n    ion-text > .value {\r\n        font-weight: bold;\r\n        font-size: 24px;\r\n    }\r\n\r\n    background-color: white;\r\n    color: var(--ion-color-primary);\r\n\r\n    h2.old-price{\r\n        text-decoration: line-through var(--ion-color-secondary);\r\n        font-size: 18px !important;\r\n        text-decoration-thickness: 3px;  \r\n    }\r\n}\r\n\r\nform.selected-fee{\r\n    background-color: var(--ion-color-primary);\r\n    color: white;\r\n    border: 2px solid white;\r\n\r\n    box-shadow: 5px 5px 8px var(--ion-color-primary);\r\n\r\n    .btn-discount{\r\n        color:  var(--ion-color-primary);\r\n        --background: white;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
