// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item.selected {
  --background: var(--ion-color-primary);
}
ion-item.selected ion-label > h3, ion-item.selected ion-label > p {
  color: white !important;
}

ion-accordion::part(content) {
  padding: 5px;
}

ion-item {
  --padding-start:2px;
  --padding-end:2px;
  --border-radius:10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal/quotation-wizar-company/quotation-wizar-company.component.scss"],"names":[],"mappings":"AAAA;EACI,sCAAA;AACJ;AAAI;EACI,uBAAA;AAER;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,mBAAA;EACA,iBAAA;EACA,oBAAA;AACJ","sourcesContent":["ion-item.selected{\n    --background: var(--ion-color-primary);\n    ion-label > h3, ion-label > p{\n        color: white !important;\n    }\n}\n\nion-accordion::part(content){\n    padding: 5px;\n}\n\nion-item{\n    --padding-start:2px;\n    --padding-end:2px;\n    --border-radius:10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
