import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Company } from '../class/company.class';
import { StatDoc } from '../class/stats/stats-doc.class';
import { Quotation } from '../class/quotation.class';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpClient) { 
    
  }


  getStats() {

    
    return this.http.get<Array<StatDoc>>(`${environment.api_site}/document/stats`);    

  }

  getCompanyStatsOnGoing(vat_code : string = null ) {

    return this.http.get<Array<Quotation>>(`${environment.api_site}/document/${vat_code}/stats`);    

  }

  

  // getAll() {
    
  //   return this.http.get<Array<Company>>(`${environment.api_site}/company`);    

  // }

  // getById(id: string) {
    
  //   return this.http.get<Company>(`${environment.api_site}/company/${id}`);    

  // }

  // insert(data: Company) {
    
  //   return this.http.post<Company>(`${environment.api_site}/company/`, data);    

  // }

  // update(data: Company) {
    
  //   return this.http.put<Company>(`${environment.api_site}/company/${data.id}`, data);    

  // }
}
