import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Company } from '../../../class/company.class';
import { ZohoCompany } from '../../../class/Zoho/zoho-company.class';

@Component({
  selector: 'app-quotation-wizar-company',
  templateUrl: './quotation-wizar-company.component.html',
  styleUrls: ['./quotation-wizar-company.component.scss'],
})
export class QuotationWizardCompanyModal implements OnInit {
  @Input()
  public foundedCompany : Array<Company>;

  @Input()
  public foundedCrmCompany : Array<Company>;

  public selectedItem : Company | null;
  public selectedType : string | null;

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  modalCancel(){
    this.modalCtrl.dismiss(null, 'cancel');
  }

  modalConfirm(){

    //console.log(this.selectedItem, this.selectedType);
    

    if(!this.selectedItem) return

    // if(this.selectedType === 'Company')
      this.modalCtrl.dismiss(this.selectedItem);

      // if(this.selectedType === 'ZohoCompany')
      // this.modalCtrl.dismiss(this.selectedItem, 'ZohoCompany');


  }

}
