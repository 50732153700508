import { Component, OnInit } from '@angular/core';
import { SignatureStatus } from 'src/app/enum/you-sign-status.enum';
import { StatDoc } from 'src/app/class/stats/stats-doc.class';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'stat-document',
  templateUrl: './stat-document.component.html',
  styleUrls: ['./stat-document.component.scss'],
})
export class StatDocumentComponent  implements OnInit {

  public statTypes

  public statsList: Array<StatDoc> = []

  constructor(
    private readonly documentService : DocumentService
  ) {
    this.statTypes = Object.values(SignatureStatus);
   }

  ngOnInit() {

    this.documentService.getStats().toPromise().then(data => {
      // console.log("data",data);

      for (const s of this.statTypes) {
        // console.log(String(s));

        let stat = new StatDoc();

        stat.status = s;

        stat.qty = data.find(d => d.status == s)?.qty || 0
        
        this.statsList.push(stat);
      }
      

    })

  }

}
