import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AddressComponent } from './components/address/address.component';
import { CompanyComponent } from './components/company/company.component';
import { QuotationWizardCompanyModal } from './components/modal/quotation-wizar-company/quotation-wizar-company.component';
import { SwiperModule } from 'swiper/angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { QuotationProductComponent } from './components/form/quotation/product/quotation-product/quotation-product.component';
import { QuotationProductArrayComponent } from './components/form/quotation/product/quotation-product-array/quotation-product-array.component';
import { QuotationProductAccessoryArrayComponent } from './components/form/quotation/product-accessory/quotation-product-accessory-array/quotation-product-accessory-array.component';
import { QuotationProductAccessoryComponent } from './components/form/quotation/product-accessory/quotation-product-accessory/quotation-product-accessory.component';
import { QuotationProductAccessoryRequiredComponent } from './components/form/quotation/product-accessory/quotation-product-accessory-required/quotation-product-accessory-required.component';
import { QuotationProductAccessoryRequiredArrayComponent } from './components/form/quotation/product-accessory/quotation-product-accessory-required-array/quotation-product-accessory-required-array.component';
import { SignaturePadComponent } from './components/signature-pad/signature-pad.component';
import { NewsComponent } from './components/news/news.component';

import { GoogleMapsModule } from '@angular/google-maps';
import { QuotationFooterComponent } from './components/quotation-footer/quotation-footer.component';
import { StatDocumentComponent } from './components/stats/stat-document/stat-document.component';

import { QuotationPaymentArrayComponent } from './components/form/quotation/payment/quotation-payment-array/quotation-payment-array.component';
import { QuotationPaymentComponent } from './components/form/quotation/payment/quotation-payment/quotation-payment.component';

import { QuotationFeeArrayComponent } from './components/form/quotation/fee/quotation-fee-array/quotation-fee-array.component';
import { QuotationFeeComponent } from './components/form/quotation/fee/quotation-fee/quotation-fee.component';
import { AddressFindByInputComponent } from './components/modal/address-find-by-input/address-find-by-input.component';

import { MaskitoModule } from '@maskito/angular';
import { CompanyDocumentListComponent } from './components/modal/company-document-list/company-document-list.component';
import { PopoverDiscountComponent } from './components/pop-over/popover-discount/popover-discount.component';
import { ModalSelectComponent } from './components/modal/select-item/modal-select.component';
import { TableComponent } from './components/table/table.component';
import { CompanyEssentialComponent } from './components/company-essential/company-essential.component';

@NgModule({  
  imports: 
  [ 
    FormsModule,
    CommonModule,
    FontAwesomeModule,
    SwiperModule,
    NgxDatatableModule,
    GoogleMapsModule,
    MaskitoModule
  ],
  declarations: [
    TableComponent,
    CompanyComponent,
    CompanyEssentialComponent,
    AddressComponent,
    QuotationFooterComponent,
    QuotationWizardCompanyModal,
    QuotationProductComponent,
    QuotationProductArrayComponent,
    QuotationProductAccessoryComponent,
    QuotationProductAccessoryArrayComponent,
    QuotationProductAccessoryRequiredComponent,
    QuotationProductAccessoryRequiredArrayComponent,
    QuotationPaymentComponent,
    QuotationPaymentArrayComponent,
    QuotationFeeArrayComponent,
    QuotationFeeComponent,
    SignaturePadComponent,
    NewsComponent,
    StatDocumentComponent,
    AddressFindByInputComponent,
    CompanyDocumentListComponent,
    PopoverDiscountComponent,
    ModalSelectComponent
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    GoogleMapsModule,
    MaskitoModule,
    NgxDatatableModule,
    TableComponent,
    CompanyComponent,
    CompanyEssentialComponent,
    AddressComponent,
    FontAwesomeModule,
    CommonModule,
    QuotationFooterComponent,
    QuotationProductComponent,
    QuotationProductArrayComponent,
    QuotationProductAccessoryComponent,
    QuotationProductAccessoryArrayComponent,
    QuotationProductAccessoryRequiredComponent,
    QuotationProductAccessoryRequiredArrayComponent,
    QuotationPaymentComponent,
    QuotationPaymentArrayComponent,
    QuotationFeeArrayComponent,
    QuotationFeeComponent,
    SignaturePadComponent,
    NewsComponent,
    StatDocumentComponent,
    AddressFindByInputComponent,
    CompanyDocumentListComponent,
    PopoverDiscountComponent,
    ModalSelectComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]

})
export class SharedModule {}
