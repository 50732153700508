import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { DiscountCampaign } from '../../../../../class/discount-campaign.class';
import { DiscountList } from '../../../../../class/Discount/discount-list.class';
import { Discount } from '../../../../../class/Discount/discount.class';
import { ProductPriceList } from '../../../../../class/product-price-list.class';
import { Product } from '../../../../../class/product.class';
import { QuotationService } from '../../../../../services/quotation.service';
import { PriceHelper } from '../../../../../utility/price-helper.class';
import { ProductPriceListService } from 'src/app/services/product-price-list.service';
import { PopoverDiscountComponent } from 'src/app/components/pop-over/popover-discount/popover-discount.component';
import { ModalSelectComponent } from '../../../../modal/select-item/modal-select.component';

@Component({
  selector: 'quotation-product-accessory',
  templateUrl: './quotation-product-accessory.component.html',
  styleUrls: ['./quotation-product-accessory.component.scss'],
  // changeDetection: ChangeDetectionStrategy.Default
})
export class QuotationProductAccessoryComponent {
  

  @Output() 
  remove = new EventEmitter<FormGroup>();
  
  @Input() 
  public formGroup: FormGroup;
  
  @Input() 
  public data: Array<Product> = [];

  @Input()
  public discounts: Array<DiscountList> = [];

  @Input()
  public discountCampaing : Array<DiscountCampaign> = [];

  public max_discount : number = 0

  // usata per mostrare il controllo sconto
  public show_discount : boolean = false;

  // @Input()
  // public dataRequired: Array<Product> = [];


  get f() { return this.formGroup.controls; }

  get showDiscount(){
    // console.log("this.data.",this.data,this.f.accessory_id?.value);

    // console.log("this.data.find(p => p.id === this.f.accessory_id?.value)?.not_discountable",!this.data.find(p => p.id === this.f.accessory_id?.value)?.not_discountable);
    
    
    return !this.data.find(p => p.id === this.f.accessory_id?.value)?.not_discountable;
  }
  constructor(
    private productPriceListService : ProductPriceListService,
    public alertController : AlertController,
    private popoverController: PopoverController,
    public modalCtrl: ModalController
  ){

  }

  
  

  async removeAccessory(event:Event){

    event.stopPropagation();

    const alert = await this.alertController.create({
      header: 'Vuoi rimuovere la riga?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
           
          },
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {
            
            this.remove.emit(this.formGroup);
          },
        },
      ],
    });

    await alert.present();
    
  }

  onProductSelect(selectedId : any){

    // console.log($event);

    this.formGroup.controls['accessory_id'].setValue(selectedId);

    

    let accessory = this.data.find(p => p.id === selectedId);

    // console.log("accessory", accessory);

    // console.log("data", this.data);
    

    this.formGroup.controls['description'].setValue(accessory.description_short);

    //this.productPriceListService.productPriceList.find(l => l.product_id === item.product_id)

    // let accessoryPriceList : ProductPriceList = accessory.product_price_lists.find(l => l.price_list.price_list_type === 'sale')

    let accessoryPriceList : ProductPriceList = this.productPriceListService.productPriceList.find(l => l.product_id === accessory.id)

    console.log("this.discountCampaing",this.discountCampaing);
    

    // trovo se esiste una campagna sconti
    let campaingFound = PriceHelper.GetDiscountCampaingAvaliable(this.discountCampaing, accessory)

    // calcolo dello sconto da scontistiche listino
    this.max_discount = PriceHelper.GetMaxDiscountAvaliable(this.discounts, accessory);

    // console.log("max_discount",this.max_discount,campaingFound);
    this.formGroup.controls['max_discount'].setValue(this.max_discount);
    


    this.show_discount = !accessory.not_discountable;
    
    // prezzo default da listino
    let price = (Number(accessoryPriceList?.price) || Number(accessory?.price) || 0)

    // se prezzo fisso presente in camapagna
    if(campaingFound){
      this.max_discount = null;
    }

    if(campaingFound?.forfait){ // devo sottrarre il prezzo
      price = price - campaingFound.forfait;       
    }

    if(campaingFound?.price){ // devo impostare il prezzo
      price = campaingFound.price;       
    }


   
    this.formGroup.controls['price'].setValue(price);


    if(this.max_discount){
      this.formGroup.controls["discount"].enable();
      this.formGroup.controls['discount'].addValidators([Validators.max(this.max_discount)])
    } else {
      this.formGroup.controls['discount'].disable();
    }

    
      
    //   this.selectedProduct = data;

    //   this.formGroup.controls['description'].setValue(this.selectedProduct.description_short);

    //   this.formGroup.controls['price'].setValue(productPriceList);
    
  }


  updateQuantity(event: Event,symbol : string) {

    event.stopPropagation();

    let prevQty = Number(this.formGroup.controls["qty"].value) || 1

    if(symbol == '-'){

      prevQty -=1

      if(prevQty < 1) prevQty = 1

    }

    if(symbol == '+'){
      prevQty +=1
    }

    this.formGroup.controls["qty"].setValue(prevQty)

  }

  // updateDiscount(symbol : string) {

  //   let prevQty = Number(this.formGroup.controls["discount"].value) || 0

  //   if(symbol == '-'){

  //     prevQty -=1

  //     if(prevQty < 0) prevQty = 0

  //   }

  //   if(symbol == '+'){
  //     prevQty +=1
  //   }

  //   this.formGroup.controls["discount"].setValue(prevQty)

  // }

  async showPopoverDiscount(event: Event) {

    event.stopPropagation();

    const popover = await this.popoverController.create({
      component: PopoverDiscountComponent,
      componentProps : {
        discount : this.formGroup.controls["discount"].value || 0,
        max_discount : this.formGroup.controls["max_discount"].value
      },
      event: event,
      translucent: true,
    });

    await popover.present();

    const { data } = await popover.onDidDismiss();

    console.log(data);

    if(data?.data?.discount){      
      this.formGroup.controls["discount"].setValue(data.data?.discount)
    } else {
      this.formGroup.controls["discount"].setValue(0)
    }
    
  }

  async selectAccessory(){

    let dataMapped = this.data.map((x)=> ({
      text: x.description_short,
      value: x.id
    }));

    const modal = await this.modalCtrl.create({
      component: ModalSelectComponent,
      componentProps: {
        title: "Accessori",
        items: dataMapped,
        selectedItem: this.formGroup.value?.accessory_id
      },
      
    });

    await modal.present();

    let {data} = await modal.onWillDismiss();

    let selectedId = data;

    // console.log("selectedId",selectedId);

    if(selectedId){

      this.formGroup.controls["qty"].setValue(1);

      this.onProductSelect(selectedId)

    }
    

  }

}
