import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover-discount',
  templateUrl: './popover-discount.component.html',
  styleUrls: ['./popover-discount.component.scss'],
})
export class PopoverDiscountComponent  implements OnInit {

  public discount : number = 0;

  public max_discount : number = 50;

  constructor(private popoverController: PopoverController) { }

  ngOnInit() {}

  updateDiscount(symbol : string) {

    let prevQty = Number(this.discount) || 0

    if(symbol == '-'){

      prevQty -=1

      if(prevQty < 0) prevQty = 0

    }

    if(symbol == '+'){
      prevQty +=1
    }

    this.discount = prevQty

  }


  close(){
    this.popoverController.dismiss({
      data : {
        discount : this.discount
      }
    })
  }

}
