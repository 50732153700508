// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-input {
  margin-top: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/pop-over/popover-discount/popover-discount.component.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;AACJ","sourcesContent":["ion-input{\n    margin-top: 0px !important;\n}\n\n// .popover-content::part(content) {\n\n//     width: auto;\n// }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
