import { DiscountCampaign } from "../class/discount-campaign.class";
import { DiscountList } from "../class/Discount/discount-list.class";
import { Discount } from "../class/Discount/discount.class";
import { Product } from "../class/product.class";


export class PriceHelper{


    public static SubtractDiscount(total : number , discount : number) : number {

        return total - ((total / 100) * discount)

    }


    ///
    public static GetMaxDiscountAvaliable(discountList : Array<DiscountList>, product : Product) : number|null {

        // console.log("GetMaxDiscountAvaliable", discountList);
        

        if(discountList.length === 0) return null;

        if(!product) throw "unable to calculate discount for unknow product";

        // estrazione di tutte le righe sconti

        let discountRows : Array<Discount> = [];

        for (let dl of discountList) {         
            discountRows = discountRows.concat(dl.discount_rows)
        }

        // console.log("discountRows", discountRows);  

        // righe sconto per prodotto
        let discountOfProduct : Discount = discountRows.find(d => d.product_id === product.id);
        // console.log("sconto prodotto", discountOfProduct);
        
        if(discountOfProduct) return discountOfProduct.max_discount

        // righe sconto per categoria prodotti
        let discountOfCategory : Discount = discountRows.find(d => d.product_category_id === product.product_category_id);
        // console.log("sconto categoria", discountOfCategory);
        if(discountOfCategory) return discountOfCategory.max_discount

        
        // se non ho trovato nulla ritorno null
        return null;


    }

    public static GetDiscountCampaingAvaliable(discountCampaingList : Array<DiscountCampaign>, product : Product) : any {

        // console.log("GetDiscountCampaingAvaliable", discountCampaingList);        

        if(!discountCampaingList || discountCampaingList?.length === 0) return null;

        if(!product) throw "unable to calculate discount for unknow product";

        // estrazione di tutte le righe sconti
        let forfaitFound : number | null = null;

        let priceFound : number | null = null;

        for (let campaing of discountCampaingList) {

            for (let prod of campaing.products) {         
            
                if(prod.id === product.id && campaing.forfait > forfaitFound ){
                    forfaitFound = campaing.forfait;
                }

                if(prod.id === product.id && campaing.price > priceFound ){
                    priceFound = campaing.price;
                }

            }

        }
        
        // se non ho trovato nulla ritorno null
        return { 
            price : priceFound , // il prezzo da sottrarre
            forfait : forfaitFound // il prezzo finale
        };

    }

    

}