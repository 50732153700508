import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Product } from '../class/product.class';
import { ProductPriceList } from '../class/product-price-list.class';
import { PriceList } from '../class/price-list.class';

@Injectable({
  providedIn: 'root'
})
export class ProductPriceListService {

  public productBasePriceList : Array<ProductPriceList> = []

  public productPriceList : Array<ProductPriceList> = []

  public priceList : Array<PriceList> = []

  constructor(private http: HttpClient) { 

  }


  async getPriceList() : Promise<Array<PriceList>> {

    let params = new HttpParams()

    params.append("includeHidden", false)
    
    this.priceList = await this.http.get<Array<PriceList>>(`${environment.api_site}/price-list/`, { params }).toPromise();   
    
    return this.priceList

  }
 

  async getProductPriceListByPriceListId(priceListId: string) : Promise<Array<ProductPriceList>> {

    let params = new HttpParams()
    
    this.productPriceList = await this.http.get<Array<ProductPriceList>>(`${environment.api_site}/product-price-list/price-list/${priceListId}`, { params }).toPromise();   
    
    return this.productPriceList

  }

  async getProductBasePriceListByPriceListId() : Promise<Array<ProductPriceList>> {

    let params = new HttpParams()
    
    this.productBasePriceList = await this.http.get<Array<ProductPriceList>>(`${environment.api_site}/product-price-list/price-list/base`, { params }).toPromise();   
    
    return this.productBasePriceList

  }

  
  // async getAgentProductPriceList() : Promise<Array<ProductPriceList>> {

  //   let params = new HttpParams()
    
  //   this.productPriceList = await this.http.get<Array<ProductPriceList>>(`${environment.api_site}/product-price-list/agent/`, { params }).toPromise();   
    
  //   return this.productPriceList

  // }

}
