import { Component, OnInit } from '@angular/core';
import { News } from '../../class/news.class';
import { AuthService } from '../../services/auth.service';
import { NewsService } from '../../services/news.service.ts';

@Component({
  selector: 'news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {

  public newsList : Array<News> = [];

  constructor(
    private newsService : NewsService,
    private authService : AuthService) { }

  async ngOnInit() {
    
    this.newsList = await this.newsService.getAll().toPromise()

    // console.log(this.newsList);
    

  }

  async ionViewWillEnter(){

  }


  async markAsRead(news : News, index : number) {
    // console.log("markAsRead", news);    
    
    await this.newsService.setReaded(news.id).toPromise()

    this.newsList[index].readed = true;

    this.newsList[index].tab_open = !this.newsList[index].tab_open;

  }
  

}
