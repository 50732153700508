import { Base } from "./base.class"
import { Company } from "./company.class";
import { Coordinate } from "./coordinate.class";

export enum AddressType {
    registered_office = 'registered_office',
    operational_headquarters = 'operational_headquarters'
}

export class Address extends Base {

    city: string | null;

    nation: string | null;

    street: string | null;

    number: string | null;

    postal_code: string | null;

    province: string | null;

    region: string | null;

    lat_lon: Coordinate | null;

    type: AddressType | null;

    company_id : string | null;

    company : Company | null;


    public ToAddressString() : string {
        return `${this.street} ${this.number}, ${this.city}, ${this.postal_code} ${this.province}`
    }

}