import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TaxBreak } from '../class/tax_break.class';



@Injectable({
  providedIn: 'root'
})
export class TaxBreakService {

  public taxBreakList : Array<TaxBreak> = []

  constructor(private http: HttpClient) { }
 

  
  async getTaxBreakList() : Promise<Array<TaxBreak>> {

    let params = new HttpParams()
    
    this.taxBreakList = await this.http.get<Array<TaxBreak>>(`${environment.api_site}/tax-break/`, { params }).toPromise();   

    // console.log("getTaxBreakList", this.taxBreakList);
    
    
    return this.taxBreakList

  }


}