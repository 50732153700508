import { Location } from '@angular/common';
import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonInput, Platform } from '@ionic/angular';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnChanges {
  public ColumnMode = ColumnMode

  public smallDevice = false;
  // public companies$ : Observable<Array<Company>>;

  @ViewChild('searchbar') searchbar!: IonInput;

  @Input()
  public data: Array<any> | null;

  // @Input()
  public data_filtered: Array<any> | null;

  @Input()
  public headers: Array<any> | null;

  @Input()
  public cols: Array<string>;

  @Input()
  public readonly: boolean = false;

  @Input()
  public edit: boolean = true;

  @Input()
  public delete: boolean = true;

  @Input()
  public download: boolean = false;

  @Output()
  public selectedEvent = new EventEmitter<any>();

  @Output()
  public editEvent = new EventEmitter<any>();

  @Output()
  public deleteEvent = new EventEmitter<any>();
  
  @Output()
  public viewEvent = new EventEmitter<any>();

  @Output()
  public dlEvent = new EventEmitter<any>();

  placeholder: string = "Cerca..."

  constructor(
    private location: Location,
    private router: Router,
    private platform: Platform
  ) { }

  ngOnInit() {

    // console.log("ngOnInit");
    

    setTimeout(()=>{

      let placeholder = "Cerca"

      this.cols.forEach((element,idx) => {
        if(!element.includes('.')) {        

          placeholder += " " + this.headers[idx] +","

        }
      });


      if(placeholder.endsWith(',')) {

        placeholder = placeholder.substring(0,placeholder.length-1);

      } 

      placeholder += "..."


      this.placeholder = placeholder

    },100);
    

    this.smallDevice = this.platform.width() <  768;
    

  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("ngOnChanges");
    

    if(changes && this.data) {
      this.data_filtered = structuredClone(this.data);
    }
  }

  ionViewWillEnter() {

    // console.log("ionViewWillEnter");

  }

  filterData(event){

    const input = event.target.value.toLowerCase();
    this.data_filtered = this.data.filter((x) => Object
    .entries(x)
    .map(([key, value]) => {      
      if(this.cols.filter(x => !x.includes('.')).includes(key)) {
        return value?.toString().toLowerCase().indexOf(input) > -1
      }
    })
    .some(v => v));
  }


  // viewItem(item: any){
  //   console.log("viewItem", item);    
  //   this.selectedEvent.emit(item);
  // }

  editItem(item: any){
    // console.log("editItem", item.id);
    this.editEvent.emit(item);
  }

  deleteItem(item: any){
    // console.log("deleteItem", item.id);
    this.deleteEvent.emit(item);
  }

  viewItem(item: any){
    // console.log("viewItem", item.id);
    this.viewEvent.emit(item);
  }

  downloadItem(item: any){
    // console.log("downloadItem", item.id);
    this.dlEvent.emit(item);
  }

  add() {
    this.router.navigate([this.router.url,'add'])
  }

}
