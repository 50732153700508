import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError, switchMap, filter, take } from 'rxjs/operators';
import {
  Router
} from '@angular/router';
import { LoadingController, ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { TokenService } from '../services/token.service';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {


  constructor(
    private router: Router,
    private tokenService: TokenService,
    private authService: AuthService,
    public toastController: ToastController,
    public loadingController: LoadingController) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError( err => {

      if(this.loadingController) {
        this.loadingController.dismiss();
      }

      // console.error(err);
      // console.error(err.status);

      // if ([401, 403].includes(err.status) && this.authService.currentUser) {
      //   // auto logout if 401 or 403 response returned from api
      //   // this.authService.logout();
      // }

      const error = (err && err.error && err.error.messages) || err.statusText;

      //console.log(err && err.error && err.error.messages);

      let errorText = error;

      if(Array.isArray(error)){
        errorText = error.join('<br>');
      }

      //console.error(errorText);

      if(!request.url.includes('/auth/') && ![401, 403].includes(err.status)){     

        this.toastController.create({
          message: errorText,
          duration: 10000,
          cssClass: 'toast-error',
          buttons: [          
            {
              text: 'Dismiss',
              role: 'cancel',
              handler: () => { 

              }
            }
          ]
        }).then(toast => {
          toast.present();
        })

      }
      
      if(request.url.includes('/auth/refresh-token') && [401].includes(err.status)) {

        this.authService.logout()

      }

      return throwError(err);
      
    }))
  }

}