import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Product } from '../../../../../class/product.class';

@Component({
  selector: 'quotation-product-accessory-required-array',
  templateUrl: './quotation-product-accessory-required-array.component.html',
  styleUrls: ['./quotation-product-accessory-required-array.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuotationProductAccessoryRequiredArrayComponent implements OnDestroy {

  private internalFormArray: FormArray;


  @Input()
  set formArray(formArray: FormArray) {
    this.internalFormArray = formArray;

    // console.log("formArray", formArray);
    

    if (this.subscriptions.formArrayChanges) {
      this.subscriptions.formArrayChanges.unsubscribe();
    }
    // this will work around the change detection on push limitations
    this.subscriptions.formArrayChanges = this.formArray.valueChanges.subscribe(
      result => {
        this.changeDetectorRef.detectChanges();
      }
    );
  }
  get formArray() {
    return this.internalFormArray;
  }


  // @Input()
  // public showAddBtn : boolean = false;

  // @Input()
  // public dataRequired: Array<Product> = [];


  private subscriptions: { [key: string]: Subscription } = {};

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder) {
      
    }


    
    ngOnDestroy() {
      Object.keys(this.subscriptions).forEach(sk =>
        this.subscriptions[sk].unsubscribe()
      );
    }
  
    remove(formGroup: FormGroup) {
      this.formArray.removeAt(this.formArray.controls.indexOf(formGroup));
    }

}
