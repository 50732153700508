import { Component, DoCheck, EventEmitter, Input, NO_ERRORS_SCHEMA, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertController, IonModal, LoadingController, ModalController } from '@ionic/angular';
import { AddressType, Address } from '../../class/address.class';
import { Company } from '../../class/company.class';
import { ZohoCompany } from '../../class/Zoho/zoho-company.class';
import { CompanyService } from '../../services/company.service';
import { ExtCrmService } from '../../services/ext-crm.service';
import { AddressComponent } from '../address/address.component';
import { OverlayEventDetail } from '@ionic/core/components';
import { QuotationWizardCompanyModal } from '../modal/quotation-wizar-company/quotation-wizar-company.component';
import { Coordinate } from '../../class/coordinate.class';
import { ProductPriceListService } from '../../services/product-price-list.service';
import { PriceList } from '../../class/price-list.class';
import * as moment from 'moment';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements  OnChanges {

  public moment = moment

  @ViewChild('address_registered_office') address_registered_office : AddressComponent;

  @ViewChild('address_operational_headquarters') address_operational_headquarters : AddressComponent;


  // lista delle aziende utilizzate per la ricerca
  private companies : Array<Company>;  
  public foundedCompany : Array<Company>;
  // lista delle aziende utilizzate per la ricerca da zoho
  // private zohoCompies : Array<ZohoCompany>;
  // public foundedZohoCompany : Array<ZohoCompany>;
  


  public form: FormGroup;

  private id: string;

  // public edit: boolean = false;
  public addressType = AddressType;

  @Input()
  public company: Company | null = null;

  @Input()
  public disableEssentialInfos: boolean = false;
  
  @Input()
  public edit: boolean = false;

  // abilita la ricerca dell'azienda
  @Input()
  public enableSearchCompany: boolean = false;

  @Output()
  public companySaveEvent = new EventEmitter<Company>();

  @Output()
  public companySelectEvent = new EventEmitter<Company>();


  public priceList: Array<PriceList> = [];

  private loading: any;


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private alertCtrl : AlertController,
    private modalCtrl : ModalController,
    private loadingCtrl : LoadingController,
    private companyService: CompanyService,
    private productPriceListService : ProductPriceListService,
    private extCrmService : ExtCrmService) {
  
      this.form = this.formBuilder.group({
        id: [],
        business_name: ['', Validators.required],
        vat_code: ['', [Validators.required, Validators.pattern(/^[0-9]{11}$/)]],
        fiscal_code: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        email_pec: ['', [Validators.required, Validators.email]],
        sdi : [null],
        phone: ['', Validators.required],
        mobile_phone: [''],
        description: [''],
        website: [''],
        address_operational_headquarters_id : [null],
        address_registered_office_id : [null],
        contact_name:  ['', Validators.required],
        contact_surname:  ['', Validators.required],
        contact_phone:  ['', Validators.required],
        price_list_id:  [null],
      }); 

    
  }

  async ngOnInit(){

    // console.log("qui");
    

    this.loading = await this.loadingCtrl.create({
      message: 'Caricamento dati in corso...',
      backdropDismiss: false
    })

    this.loading.present();

    if(this.enableSearchCompany){
      this.companies = await this.companyService.getAll().toPromise()

      // this.extCrmService.getZohoCompanies().toPromise().then(data =>{
      //   this.zohoCompies = data
      // })

    }


    this.priceList = await this.productPriceListService.getPriceList().then()

    await this.loading?.dismiss();
    // console.log("qui2");

  }  

  async ngOnChanges(changes: SimpleChanges) {

    // console.log("ngOnChanges",this.company);
    // // console.log(changes);
    

    if(this.company){

      this.form.patchValue(this.company);

    //   // console.log("this.form.",this.form.value);
      
  
    //   this.form.controls['id'].setValue(this.company.id);
    //   this.form.controls['business_name'].setValue(this.company.business_name);
    //   this.form.controls['vat_code'].setValue(this.company.vat_code);
    //   this.form.controls['fiscal_code'].setValue(this.company.fiscal_code);
    //   this.form.controls['email'].setValue(this.company.email);
    //   this.form.controls['email_pec'].setValue(this.company.email_pec);
    //   this.form.controls['phone'].setValue(this.company.phone);
    //   this.form.controls['mobile_phone'].setValue(this.company.mobile_phone);
    //   this.form.controls['description'].setValue(this.company.description);
    //   this.form.controls['website'].setValue(this.company.website);
    //   this.form.controls['sdi'].setValue(this.company.sdi);

    //   this.form.controls['address_operational_headquarters_id'].setValue(this.company.address_operational_headquarters_id);
    //   this.form.controls['address_registered_office_id'].setValue(this.company.address_registered_office_id);

    //   this.form.controls['contact_name'].setValue(this.company.contact_name);
    //   this.form.controls['contact_surname'].setValue(this.company.contact_surname);
    //   this.form.controls['contact_phone'].setValue(this.company.contact_phone);

    //   this.form.controls['price_list_id'].setValue(this.company.price_list_id);


    //   // if(this.company?.price_list_id){
    //   //   await this.productPriceListService.getProductPriceListByPriceListId(this.company.price_list_id);
    //   //   await this.productPriceListService.getProductBasePriceListByPriceListId();
    //   // }

    }


  }

  searchCompany($event){
    
    // console.log("searchCompany", $event);

    if(!this.enableSearchCompany) return 

    let searchValue = $event.target.value;
    
    // console.log("searchCompany", searchValue, this.companies, this.zohoCompies);

    if(!searchValue || searchValue.length < 2 ){
      this.foundedCompany = [];
      // this.foundedZohoCompany = [];
      return;
    }


    this.foundedCompany = this.companies?.filter((c) =>{ return c.business_name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) });

    // this.foundedZohoCompany = this.zohoCompies?.filter((c) =>{ return c.Business_Name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) });

    
    
  }

  async searchCompanyByVatCode($event){

    // console.log("searchCompanyByVatCode", $event);

    return;

    //DA VERIFICARE
    
    
    if(!this.enableSearchCompany) return 

    let searchValue = $event.target.value;

    // verifica piva
    // let regexp = new RegExp('/^[0-9]{11}/')

    // let test = regexp.test(searchValue);

    
    let controlIsValid = this.form.controls['vat_code']?.valid

    //console.log(this.foundedCompany);
    if(controlIsValid){

      const alert = await this.alertCtrl.create({
        header: "Vuoi che cerco i dati aziendali online?",
        buttons: [
          {
            text: 'Si',
            handler: async (value: any) => {

              let openApiCompany = await this.companyService.searchByVatCode(searchValue).toPromise()
              console.log("openApiCompany", openApiCompany);

              this.company = new Company()

              this.company.business_name = openApiCompany.denominazione;
              this.company.vat_code = openApiCompany.piva;
              this.company.fiscal_code = openApiCompany.cf;
              this.company.sdi = openApiCompany.codice_destinatario;

              // let coordinate : Coordinate = {
              //   lat : openApiCompany?.gps[0],
              //   lng : openApiCompany?.gps[1],
              // }

              let address = new Address()
              address.city = openApiCompany?.comune;
              address.nation = 'it';
              address.street = openApiCompany?.via;
              address.number = openApiCompany?.civico;
              address.postal_code = openApiCompany?.cap;
              address.province = openApiCompany?.provincia;
              // address.lat_lon = coordinate;

              this.company.address_registered_office = address
              this.company.address_operational_headquarters = address


              this.form.controls['id'].setValue(this.company.id);
              this.form.controls['business_name'].setValue(this.company.business_name);
              this.form.controls['vat_code'].setValue(this.company.vat_code);
              this.form.controls['fiscal_code'].setValue(this.company.fiscal_code);
              this.form.controls['email'].setValue(this.company.email);
              this.form.controls['email_pec'].setValue(this.company.email_pec);
              this.form.controls['phone'].setValue(this.company.phone);
              this.form.controls['description'].setValue(this.company.description);
              this.form.controls['website'].setValue(this.company.website);
              this.form.controls['sdi'].setValue(this.company.sdi);


              // this.form.controls['address_operational_headquarters_id'].patchValue({
              //   city: openApiCompany?.comune,
              //   nation: 'it',
              //   street: openApiCompany?.via,
              //   number: openApiCompany?.civico,
              //   postal_code: openApiCompany?.cap,
              //   province: openApiCompany?.provincia,
              //   lat_lon: coordinate
              // });

              // this.form.controls['address_registered_office_id'].patchValue({
              //   city: openApiCompany?.comune,
              //   nation: 'it',
              //   street: openApiCompany?.via,
              //   number: openApiCompany?.civico,
              //   postal_code: openApiCompany?.cap,
              //   province: openApiCompany?.provincia,
              //   lat_lon: coordinate
              // });


            }
          },
          {
            text: 'No',
            handler: (value: any) => {
              
            }
          }
        ],
        backdropDismiss: false
      });

      await alert.present();

      
      
    }
  
  }


  get f() { return this.form.controls; }

  async submit() {


    this.form.markAllAsTouched();

    let aohSaved = true;
    let aroSaved = true;

      await this.address_operational_headquarters.submit();
  
      await this.address_registered_office.submit();

    //console.log(this.company);

    if(this.form.invalid || !aohSaved || !aroSaved){
      return false;
    }


    try {

        this.company = <Company>this.form.value;

        if(this.company.id){
          // edit
        
          this.company = await this.companyService.update(this.company).toPromise();
        
        } else {
          // insert
        
          this.company = await this.companyService.insert(this.company).toPromise();
        
        }

      // this.router.navigate(['/company'])

      this.edit = false;

      this.companySaveEvent.emit(this.company);

      return true;
      
    } catch (error) {

      console.error(error);
      
      throw error
      
    }

    
    

  }

  eventAddressRegistered(address: Address, addressType : AddressType){

    console.log("eventAddressRegistered", address, addressType);
    

    switch (addressType) {
      case AddressType.operational_headquarters:
        
        this.form.controls['address_operational_headquarters_id'].setValue(address.id);

        break; 
      case AddressType.registered_office:

        this.form.controls['address_registered_office_id'].setValue(address.id);
        
        break;    
      default:
        break;
    }

  }


  /* Modali selezione azienda */

  async openModal() {
    const modal = await this.modalCtrl.create({
      component: QuotationWizardCompanyModal,
      componentProps : {
        foundedCompany : this.foundedCompany.filter(c => c.crm_id == null),
        foundedCrmCompany : this.foundedCompany.filter(c => c.crm_id != null)
      }
    });
    modal.present();

    let { data } = await modal.onWillDismiss();    

    //console.log(data, role);
    

    // if (role === 'ZohoCompany') {  
      
    //   data = <ZohoCompany>data;

    //   this.company = new Company();

    //   // this.form.controls['id'].setValue(data.id);
    //   // this.form.controls['business_name'].setValue(data.Business_Name);
    //   // this.form.controls['vat_code'].setValue(data.Vat_Code);
    //   // this.form.controls['fiscal_code'].setValue(data.Fiscal_Code);
    //   // this.form.controls['phone'].setValue(data.Phone);

    //   this.company.id = data.id;
    //   this.company.business_name = data.Business_Name;
    //   this.company.vat_code = data.Vat_Code;
    //   this.company.fiscal_code = data.Fiscal_Code;
    //   this.company.phone = data.Phone;


    //   // sede
    //   let aoh = new Address();

    //   aoh.city = data.Billing_City
    //   aoh.nation = data.Billing_Country
    //   aoh.street = data.Billing_Street
    //   aoh.postal_code = data.Billing_Zip_Code
    //   aoh.province = data.Billing_Province
    //   aoh.type = AddressType.operational_headquarters

    //   this.company.address_operational_headquarters = aoh
    //   // spedizione
    //   let aro = new Address();

    //   aro.city = data.Shipping_City
    //   aro.nation = data.Shipping_Country
    //   aro.street = data.Shipping_Street
    //   aro.postal_code = data.Shipping_Zip_Code
    //   aro.province = data.Shipping_Province
    //   aro.type = AddressType.registered_office

    //   this.company.address_registered_office = aro




    // }
      
    // if (role === 'Company') { 

      data = <Company>data;

      //console.log(data);
      

      this.company = new Company();

      // this.form.controls['id'].setValue(data.id);
      // this.form.controls['business_name'].setValue(data.business_name);
      // this.form.controls['vat_code'].setValue(data.vat_code);
      // this.form.controls['fiscal_code'].setValue(data.fiscal_code);
      // this.form.controls['email'].setValue(data.email);
      // this.form.controls['email_pec'].setValue(data.email_pec);
      // this.form.controls['phone'].setValue(data.phone);
      // this.form.controls['description'].setValue(data.description);
      // this.form.controls['website'].setValue(data.website);

      // this.company.id = data.id;
      // this.company.business_name = data.business_name;
      // this.company.vat_code = data.vat_code;
      // this.company.fiscal_code = data.fiscal_code;
      // this.company.email = data.email;
      // this.company.email_pec = data.email_pec;
      // this.company.phone = data.phone;
      // this.company.description = data.description;
      // this.company.website = data.website;

      this.company = data;
      
        
      
    // }


    //console.log(this.company);
    
    if(this.company){

      this.form.patchValue(this.company)

      // this.form.controls['id'].setValue(this.company.id);
      // this.form.controls['business_name'].setValue(this.company.business_name);
      // this.form.controls['vat_code'].setValue(this.company.vat_code);
      // this.form.controls['fiscal_code'].setValue(this.company.fiscal_code);
      // this.form.controls['email'].setValue(this.company.email);
      // this.form.controls['email_pec'].setValue(this.company.email_pec);
      // this.form.controls['phone'].setValue(this.company.phone);
      // this.form.controls['description'].setValue(this.company.description);
      // this.form.controls['website'].setValue(this.company.website);

      // this.form.controls['address_operational_headquarters_id'].setValue(this.company.address_operational_headquarters_id);
      // this.form.controls['address_registered_office_id'].setValue(this.company.address_registered_office_id);

    }

    // console.log("this.company",this.company)

    this.companySelectEvent.emit(this.company);

  }
  

  clearForm(){
    this.company = null;

    this.foundedCompany = [];
    // this.foundedZohoCompany = [];

    this.form.reset();
  }


  phoneChangeEvent($event) {
    let phone = $event.detail.value.toString()
    console.log("phoneChangeEvent" , phone);

    if(!phone.includes('0039') && !phone.includes('+39')){
      phone = phone.replace('+','')
      this.form.controls['mobile_phone'].patchValue(`+39${phone}`, { emitEvent: false })
    }
  }

  contactPhoneChangeEvent($event) {
    let phone = $event.detail.value.toString()
    console.log("phoneChangeEvent" , phone);

    if(!phone.includes('0039') && !phone.includes('+39')){
      phone = phone.replace('+','')
      this.form.controls['contact_phone'].patchValue(`+39${phone}`, { emitEvent: false })
    }
  }

}
