import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Product } from '../../../../../class/product.class';
import { PopoverDiscountComponent } from 'src/app/components/pop-over/popover-discount/popover-discount.component';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'quotation-product-accessory-required',
  templateUrl: './quotation-product-accessory-required.component.html',
  styleUrls: ['./quotation-product-accessory-required.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuotationProductAccessoryRequiredComponent {

  @Output() 
  remove = new EventEmitter<FormGroup>();
  
  @Input() 
  public formGroup: FormGroup;
  
  @Input() 
  public data: Array<Product> = [];

  // usata per mostrare il controllo sconto
  // public show_discount : boolean = false;


  public max_discount : number = 0

  // @Input()
  // public dataRequired: Array<Product> = [];

  constructor(private popoverController: PopoverController){}


  get f() { return this.formGroup.controls; }

  get showDiscount(){
    // console.log("this.data.",this.data,this.f.accessory_id?.value);

    // console.log("this.data.find(p => p.id === this.f.accessory_id?.value)?.not_discountable",!this.data.find(p => p.id === this.f.accessory_id?.value)?.not_discountable);
    
    
    return !this.data.find(p => p.id === this.f.accessory_id?.value)?.not_discountable;
  }

  // public productForm : FormGroup = new FormGroup({
  //   id : new FormControl("", {
  //     validators: Validators.required,
  //     nonNullable: true,
  //   })
  // });

  

  // removeAccessory(){
  //   this.remove.emit(this.formGroup);
  // }

  // onProductSelect($event : any){

  //   console.log($event);

  //   let selectedId = $event.detail.value;
  //   this.formGroup.controls['accessory_id'].setValue(selectedId);
    
  // }

    
  updateQuantity(event: Event, symbol : string) {

    let prevQty = Number(this.formGroup.controls["qty"].value) || 1

    if(symbol == '-'){

      prevQty -=1

      if(prevQty < 1) prevQty = 1

    }

    if(symbol == '+'){
      prevQty +=1
    }

    this.formGroup.controls["qty"].setValue(prevQty)

  }

  // updateDiscount(symbol : string) {

  //   let prevQty = Number(this.formGroup.controls["discount"].value) || 0

  //   if(symbol == '-'){

  //     prevQty -=1

  //     if(prevQty < 0) prevQty = 0

  //   }

  //   if(symbol == '+'){
  //     prevQty +=1
  //   }

  //   this.formGroup.controls["discount"].setValue(prevQty)

  // }

  async showPopoverDiscount(event: Event) {

    event.stopPropagation();

    const popover = await this.popoverController.create({
      component: PopoverDiscountComponent,
      componentProps : {
        discount : this.formGroup.controls["discount"].value || 0,
        max_discount : this.formGroup.controls["max_discount"].value
      },
      event: event,
      translucent: true,
    });

    await popover.present();

    const { data } = await popover.onDidDismiss();

    console.log(data);

    if(data?.data?.discount){      
      this.formGroup.controls["discount"].setValue(data.data?.discount)
    } else {
      this.formGroup.controls["discount"].setValue(0)
    }
    
  }

}
