import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DiscountCampaign } from '../class/discount-campaign.class';
import { DiscountList } from '../class/Discount/discount-list.class';
import { PaymentCondition } from '../class/Payment/payment-condition.class';
import { ProductAccessory } from '../class/product-accessory.class';
import { Product } from '../class/product.class';
import { Quotation } from '../class/quotation.class';
import { ShopType } from '../class/shop-type.class';
import * as moment from 'moment';
import { PaymentConditionType } from '../class/Payment/payment-condition-type.class';

@Injectable({
  providedIn: 'root'
})
export class QuotationService {

  constructor(private http: HttpClient) { 

  }
  

  getList() : Observable<Quotation[]> {
    // console.log("getAvailableProducts")
    return this.http.get<Quotation[]>(`${environment.api_site}/quotation/list`)
  }

  getById(id : string) : Observable<Quotation> {
    // console.log("getAvailableProducts")
    return this.http.get<Quotation>(`${environment.api_site}/quotation/${id}`)
  }

  insert(data: any): Observable<any> {
    // console.log("QuotationService", data);
    return this.http.post<any>(`${environment.api_site}/quotation/`, data);    

  }

  getAvailableProducts() : Observable<Product[]> {
    // console.log("getAvailableProducts")
    return this.http.get<Product[]>(`${environment.api_site}/quotation/available-products`)
  }

  getAvailableProductAccesory(productId : string) : Observable<Product[]> {
    // console.log("getAvailableProductAccesory")
    return this.http.get<Product[]>(`${environment.api_site}/quotation/available-product-accessory/${productId}`)
  }

  

  getAvailablePaymentMethods() : Observable<PaymentCondition[]> {
    // console.log("getAllPaymentsConditions")
    return this.http.get<PaymentCondition[]>(`${environment.api_site}/quotation/available-payment-method`)
  }


  getAvailablePaymentTypes() : Observable<PaymentConditionType[]> {
    // console.log("getAllPaymentsConditions")
    return this.http.get<PaymentConditionType[]>(`${environment.api_site}/quotation/available-payment-type`)
  }

  getAvailableDiscounts() : Observable<DiscountList[]> {
    // console.log("getAvailableDiscounts")
    return this.http.get<DiscountList[]>(`${environment.api_site}/quotation/available-discounts`)
  }


  getAvailableDiscountCampaing() : Observable<DiscountCampaign[]> {
    // console.log("getAvailableDiscounts")
    return this.http.get<DiscountCampaign[]>(`${environment.api_site}/quotation/available-discounts-campaign`)
  }


  getShopTypes() : Observable<ShopType[]> {
    // console.log("getAvailableDiscounts")
    return this.http.get<ShopType[]>(`${environment.api_site}/quotation/shop-type`)
  }


  downloadPdf(quotation : Quotation) : Promise<any> {

    return new Promise((resolve, reject) => {

      
      this.http.get(`${environment.api_site}/quotation/${quotation.id}/pdf`, {  responseType : 'blob' }).subscribe( 
        (response: any) =>{ 
          
          let dataType = response.type; 
          let binaryData = []; 
          binaryData.push(response); 
          let downloadLink = document.createElement('a'); 
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: "application/pdf"})); 
          
          downloadLink.setAttribute('download',`${quotation.number}_${moment().format("YYYY-MM-DDTHH:mm")}.pdf`); 
          // downloadLink.setAttribute('download',`pdf.pdf`); 
          document.body.appendChild(downloadLink); 
          downloadLink.click(); 
        
          resolve(true);
          
        });


      })
  }


  previewPdf(quotation : Quotation) : Promise<any> {

    return new Promise((resolve, reject) => {

      
      this.http.post(`${environment.api_site}/quotation/preview-pdf`, quotation, {  responseType : 'blob' }).subscribe( 
        (response: any) =>{ 
          
          let dataType = response.type; 
          let binaryData = []; 
          binaryData.push(response); 
          let downloadLink = document.createElement('a'); 
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: "application/pdf"})); 
          
          // downloadLink.setAttribute('download',`${moment().format("YYYY-MM-DDTHH:mm")}.pdf`); 
          // downloadLink.setAttribute('download',`pdf.pdf`); 
          // document.body.appendChild(downloadLink); 
          // downloadLink.click(); 
          // window.open(downloadLink.href)
        
          resolve(downloadLink.href);
          
        });


      })
  }

  TEST_firma(data: any): Promise<any> {
    // console.log("QuotationService", data);
    return this.http.post<any>(`${environment.api_site}/quotation/${data.id}/firma`, {}).toPromise();    

  }

  TEST_email(data: any): Promise<any> {
    // console.log("QuotationService", data);
    return this.http.post<any>(`${environment.api_site}/quotation/${data.id}/email`, {}).toPromise();    

  }


}
