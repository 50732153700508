import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Company } from '../class/company.class';
import { ZohoCompany } from '../class/Zoho/zoho-company.class';
import { ZohoContact } from '../class/Zoho/zoho-contact.class';
import { ZohoLead } from '../class/Zoho/zoho-lead.class';

@Injectable({
  providedIn: 'root'
})
export class ExtCrmService {

  constructor(private http: HttpClient) { 
    
   }

  getZohoCompanies() : Observable<Array<ZohoCompany>> {
    
    return this.http.get<Array<ZohoCompany>>(`${environment.api_site}/external-crm/zoho-companies`);    

  }

  syncZohoCompanies() : Observable<Boolean> {
    
    return this.http.get<Boolean>(`${environment.api_site}/external-crm/sync-zoho-companies`);    

  }

  getZohoContacts() : Observable<Array<ZohoContact>> {
    
    return this.http.get<Array<ZohoContact>>(`${environment.api_site}/external-crm/zoho-contacts`);    

  }

  getZohoLeads() : Observable<Array<ZohoLead>>{
    
    return this.http.get<Array<ZohoLead>>(`${environment.api_site}/external-crm/zoho-leads`);    

  }

  // getFicProducts() {
    
  //   return this.http.get<Array<Company>>(`${environment.api_site}/external-crm/ficloud-products`);    

  // }


  
}
