import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
})
export class SignaturePadComponent implements OnInit {

  @ViewChild('canvasPad', {static: false})
  canvasPad: ElementRef<HTMLCanvasElement>;//  = {} as ElementRef<HTMLCanvasElement>;

  @Input()
  signature : string;

  @Output() 
  signatureEnd = new EventEmitter<string>();

  signaturePad : SignaturePad;

  constructor() { }

  ngOnInit() {

    

  }

  ngAfterViewInit(){

    this.signaturePad = new SignaturePad(this.canvasPad.nativeElement, {     
      // penColor: "rgb(66, 133, 244)"
    });

    

    this.signaturePad.addEventListener("resize",()=>{
      console.log("signaturePad resize");
      
      const ratio =  Math.max(window.devicePixelRatio || 1, 1);
      this.canvasPad.nativeElement.width = this.canvasPad.nativeElement.offsetWidth * ratio;
      this.canvasPad.nativeElement.height = this.canvasPad.nativeElement.offsetHeight * ratio;
      this.canvasPad.nativeElement.getContext("2d").scale(ratio, ratio);
      this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
    });

    if(this.signature){
      this.signaturePad.fromDataURL(this.signature);
      this.signatureEnd.emit(this.signature);
    }
    // this.signaturePad.addEventListener("afterUpdateStroke", () => {
    //   console.log("signaturePad afterUpdateStroke");
      
    //   let base64Image = this.signaturePad.toDataURL('image/png');
    //   this.signatureEnd.emit(base64Image);
    // }, { once: true });

  }



  confirmSignature(){

    let base64Image = this.signaturePad.toDataURL('image/png');
      this.signatureEnd.emit(base64Image);
    

  }

  clearSignature(){

    this.signatureEnd.emit("");

    this.signaturePad.clear();

    console.log(this.signaturePad);

    return false;
    

  }

}
