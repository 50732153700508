import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { ProductPriceListService } from '../../../../../services/product-price-list.service';
import { ProductService } from '../../../../../services/product.service';
import { QuotationService } from '../../../../../services/quotation.service';
import { TaxBreakService } from '../../../../../services/tax-break.service';
import { PaymentConditionType } from '../../../../../class/Payment/payment-condition-type.class';
import { QuotationWizardService } from '../../../../../services/quotation-wizard.service';
import * as moment from 'moment';

@Component({
  selector: 'quotation-payment',
  templateUrl: './quotation-payment.component.html',
  styleUrls: ['./quotation-payment.component.scss'],
})
export class QuotationPaymentComponent  implements OnInit {

  @Input()
  index : number;

  @Output() 
  remove = new EventEmitter<FormGroup>();
  
  // _formGroup: FormGroup;
  @Input() formGroup: FormGroup;
  // set  (value : FormGroup){
  //   this._formGroup = value

  //   // console.log("set form ", this._formGroup.controls);
    

  //   if(this._formGroup.controls?.product_id?.value){
      
  //     // this.populateAvailableProductAccessory().then(data => {
  //     //   console.log(data);
        
  //     // })
  //   }
  // }
  // get formGroup() : FormGroup{
  //   return this._formGroup
  // }

  get f() { return this.formGroup.controls; }

  
  public paymentConditionTypes: Array<PaymentConditionType> = [];
  
  constructor(
    private formBuilder: FormBuilder,
    private quotationService : QuotationService,
    private quotationWizardService : QuotationWizardService,
    private alertController : AlertController,
    private productService : ProductService,
    private productPriceListService : ProductPriceListService,
    private taxBreakService : TaxBreakService,    
    private modalCtrl : ModalController) { 

      // this.formGroup = this.formBuilder.group({
        //   product_id: [null, Validators.required],
      //   qty : [1, [Validators.required, Validators.min(1)]],
      //   accessories : this.formBuilder.array([])
      // })

      

  }
  
  async ngOnInit() {
    
    this.paymentConditionTypes = await this.quotationService.getAvailablePaymentTypes().toPromise();
    
    
    
  }
  
  getPaymentDate(days: number): Date{
    return moment(this.quotationWizardService.quotationLogisticForm.get("extimated_delivery_date").value).add({'days':days}).toDate();
  }
    
  onPaymentTypeSelect($event) {

    let selectedId = $event.detail.value;
    // if (!selectedId) return
    // console.log("onPaymentTypeSelect", selectedId);

    this.formGroup.controls['payment_condition_type_id'].setValue(selectedId);

    // console.log("index",this.index);
    
    if(this.index == 0) {

      let payment_rows = this.quotationWizardService.quotationPaymentForm.get('payment_rows') as FormArray;

      // console.log("payment_rows",payment_rows);

      for (let i = 0; i < payment_rows.length; i++) {
  
        (<FormGroup>payment_rows.controls[i]).controls['payment_condition_type_id'].setValue(selectedId)
      }

    }

  }

  async removePaymentRow() {

    const alert = await this.alertController.create({
      header: 'Vuoi rimuovere la riga?',
      buttons: [
        {
          text: 'ANNULLA',
          role: 'cancel',
          handler: () => {

          },
        },
        {
          text: 'ELIMINA',
          role: 'confirm',
          handler: () => {

            this.remove.emit(this.formGroup);
          },
        },
      ],
    });

    await alert.present();


  }
    

}
