import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LocationService } from './location.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly CURRENT_USER = "USER";

  private currentUserSubject: BehaviorSubject<any | null>;
  public currentUser: Observable<any | null>;



  constructor(
    private router: Router,
    private http: HttpClient,    
    private tokenService : TokenService
    ) {

    this.currentUserSubject = new BehaviorSubject<any | null>(null);
    this.currentUser = this.currentUserSubject.asObservable();


  }

  public get currentUserValue(): any | null {

    //console.log("AuthService get currentUserValue");        

    if(this.currentUserSubject.value === null){
      let storedUser = localStorage.getItem(this.CURRENT_USER);
      this.currentUserSubject.next(JSON.parse(storedUser));
    }

    return this.currentUserSubject.value;

  }

  public set currentUserValue(user: any | null) {

    //console.log("AuthService set currentUserValue");

    this.currentUserSubject.next(user);

    if(user){

      localStorage.setItem(this.CURRENT_USER, JSON.stringify(user));

    } else {

      localStorage.removeItem(this.CURRENT_USER);

    }
    

  }

 

  login(email: string, password: string) {

    
    return this.http.post<any>(`${environment.api_site}/auth/sign-in`, { email, password })
    .pipe(
      switchMap(async (tokens: {user, accessToken, refreshToken }) => {
   
        this.tokenService.saveToken(tokens.accessToken);
        this.tokenService.saveRefreshToken(tokens.refreshToken);
        
        //this.currentUserSubject.next({});

        this.currentUserValue = tokens.user;

        // this.tokenService.startRefreshTokenTimer();

        

        return from(Promise.all([]));

      })

    );

  }

  requestRecovery(email: string) {
    
    return this.http.post<any>(`${environment.api_site}/auth/request-recovery`, { email });

  }

  setNewPassword(recoveryToken: string, password: string) {
    
    return this.http.post<any>(`${environment.api_site}/auth/recovery/${recoveryToken}`, { password });

  }


  logout() {

    let refresh_token = this.tokenService.getRefreshToken();


    if(refresh_token){
      this.http.delete<any>(`${environment.api_site}/auth/logout/${refresh_token}`, {}).subscribe();
    }


    this.currentUserValue = null;

    localStorage.clear();

    this.router.navigate(['/login']);

  }





  accountRecoveryPasswordRequest(email: string) {
    return this.http.post<any>(`${environment.api_site}/auth/request-recovery`, { email });
  }

  accountChangePassworRequest(token: string,email: string, password: string) {
		return this.http.post<any>(`${environment.api_site}/auth/recovery`, { token, email, password });
  }

  

  isInRole(rolesAllowed: Array<any>) {       

    if(this.currentUserValue && rolesAllowed.length == 0) return true;

    let result =  this.currentUserValue && rolesAllowed.includes(this.currentUserValue.role);
    
    return result;
  }

}
