import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-alert-text-full-screen',
  templateUrl: './alert-text-full-screen.component.html',
  styleUrls: ['./alert-text-full-screen.component.scss'],
})
export class AlertTextFullScreenComponent  implements OnInit {

  public title : string
  public text : string


  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  modalCancel(){
    this.modalCtrl.dismiss(null, 'cancel');
  }

  moveMap(event: google.maps.MapMouseEvent) {
    // if (event.latLng != null)
    //   this.center = (event.latLng.toJSON());
    //   this.markerPositions = [];
    //   this.markerPosition this.center);

    // console.log(this.center);

  }

}
