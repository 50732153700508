import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Company } from '../class/company.class';
import { Supplier } from '../class/supplier.class';
import { GeneralSetting } from '../class/general-setting.class';

@Injectable({
  providedIn: 'root'
})
export class GeneralSettingService {

  constructor(private http: HttpClient) { 
    
  }


  getAll() {
    
    return this.http.get<Array<GeneralSetting>>(`${environment.api_site}/general-setting`);    

  }

  async getByCode(code: string) {
    
    let setting: GeneralSetting= await this.http.get<GeneralSetting>(`${environment.api_site}/general-setting/code/${code}`).toPromise();

    // console.log(setting, setting.value);

    return setting?.value;

  }
}
