import { SignatureStatus } from "../enum/you-sign-status.enum";
import { PaymentCondition } from "./Payment/payment-condition.class";
import { Fee } from "./fee.class";
import { User } from "./user.class";

export class Quotation {

    id: string;

    shop_type_id : string
    
    company_id: string;

    number: number;

    company : any;

    contact_name: string;

    contact_surname: string;

    note: string;

    user_id : string

    user : User

    replaced_by_document: string;

    products : QuotationProduct[];

    payment_method_id: string;

    payment_condition : PaymentCondition

    signature_customer: string;

    signature_seller: string;

    created_at : Date;

    net_total : number;

    vat_total : number;

    // fee_rows : QuotationFeeRow[]
    fee_row : QuotationFeeRow;

    logistic_row : QuotationLogisticRow;

    payment_rows : QuotationPaymentRow[];

    admin_confirm_payment : Date;

    signature_request_status: string;
    require_admin_confirm : boolean

    public static GetStatus(status: string): string{

        let r = "";

        switch(status) {

            case SignatureStatus.DRAFT:
                r="Generato";
                break;
            case SignatureStatus.ONGOING:
                r="In attesa di firma";
                break;
            case SignatureStatus.DONE:
                r="Completato";
                break;
            case SignatureStatus.DELETED:
                r="Eliminato";
                break;
            case SignatureStatus.EXPIRED:
                r="Richiesta di firma scaduta";
                break;
            case SignatureStatus.CANCELED:
                r="Cancellato";
                break;
            case SignatureStatus.APPROVAL:
                r="Approvato";
                break;
            case SignatureStatus.REJECTED:
                r="Respinto";
                break;
            default:
                r=status;
                break;

        }

        return r;

    }

}

export class QuotationLogisticRow {

    extimated_delivery_date: Date;

    extimated_delivery_days: number;

    include_training_hours: number;

    include_training_hours_cost: number;

    include_installation_hours: number;

    include_installation_hours_cost: number;

    shipment_cost_euros: number;

    installation_by_night_checked: boolean;

    installation_preholiday_checked: boolean;

    installation_holiday_checked: boolean;

}



export class QuotationFeeRow {

    // fee : Fee

    // fee_id: string;
    fee_type: QuotationFeeRowEnum;
    
    name: string;

    description: string;

    note: string;

    year: number;

    discount: number;

    value: number;
    
    // document_row : Quotation    
    
    // document_row_id: string;
}

export class QuotationProduct {

    product_id: string;

    qty: number;

    price : number;

    discount : number;

    discount_list_id: string;

    discount_campaing_id: string;

    note: string;


    description: string

    accessories : QuotationAccessories[] = [];
    accessories_required : QuotationAccessories[] = [];
}

export class QuotationAccessories {

    accessory_id: string;

    description: string

    qty: number;

    price : number;

    discount : number;

    max_discount : number;

    discount_list_id: string;

    discount_campaing_id: string;

    note: string;

}

export class QuotationPaymentRow {


    payment_condition_id: string;

    payment_condition_item_id: string;

    payment_condition_type_id: string;

    description : string

    days: number

    value : number

    note: string;
    
    net_total: number;
    
    vat_total: number;

}

export enum QuotationFeeRowEnum {
    FIRST_YEAR = "first_year",
    FIVE_YEARS = "five_years"
}