

export enum SignatureStatus {
    DRAFT = "draft",
    ONGOING = "ongoing",
    DONE = "done",
    DELETED = "deleted",
    EXPIRED = "expired",
    CANCELED = "canceled",
    APPROVAL = "approval",
    REJECTED = "rejected"
}