import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',    
    loadChildren: () => import('./pages/auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'recovery/:token',    
    loadChildren: () => import('./pages/auth/login/login.module').then( m => m.LoginPageModule)
  }, 
  {
    path: 'company',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/company/company.module').then( m => m.CompanyPageModule)
  }, 
  {
    path: 'product',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/product/product.module').then( m => m.ProductPageModule)
  },
  {
    path: 'quotation-wizard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/quotation-wizard/quotation-wizard.module').then( m => m.QuotationWizardPageModule)
  },
  // {
  //   path: 'quotation-wizard/:id',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('./pages/quotation-wizard/quotation-wizard.module').then( m => m.QuotationWizardPageModule)
  //   // loadChildren: () => import('./pages/quotations/quotations.module').then( m => m.QuotationsPageModule)
  // }, 
  {
    path: 'quotation',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/quotations/quotations.module').then( m => m.QuotationsPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes) //, { preloadingStrategy: PreloadAllModules }
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
