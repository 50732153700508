// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-label.desc-short {
  white-space: break-spaces;
}

ion-item.total-row {
  width: 100%;
  padding-left: 0px;
}
ion-item.total-row ion-label {
  font-weight: bold;
  font-size: 24px !important;
}

ion-grid {
  padding-inline: 0px;
}
ion-grid ion-row {
  align-items: center;
}
ion-grid ion-col {
  display: flex;
  align-items: center;
}
ion-grid ion-col.ion-col-trash {
  display: grid;
  justify-content: center;
  align-items: center;
}
ion-grid ion-col.ion-col-numpad {
  justify-content: space-evenly;
  display: flex;
  align-items: center;
}
ion-grid ion-col.ion-col-numpad h2 {
  font-size: 24px;
  font-weight: bold;
}
ion-grid ion-col.ion-col-numpad .btn-qty {
  max-width: 45px;
  height: 45px;
  --border-radius: 10px;
}

div.container {
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/app/components/form/quotation/product-accessory/quotation-product-accessory-required/quotation-product-accessory-required.component.scss"],"names":[],"mappings":"AAIA;EACI,yBAAA;AAHJ;;AAMA;EACI,WAAA;EAEA,iBAAA;AAJJ;AAMI;EACI,iBAAA;EACA,0BAAA;AAJR;;AAQA;EACI,mBAAA;AALJ;AAOI;EAGI,mBAAA;AAPR;AAUI;EAEI,aAAA;EAIA,mBAAA;AAZR;AAgBI;EAMQ,aAAA;EAEA,uBAAA;EAEA,mBAAA;AArBZ;AAyBI;EAII,6BAAA;EAEI,aAAA;EAIA,mBAAA;AA9BZ;AAqCQ;EAEI,eAAA;EACA,iBAAA;AApCZ;AAuCQ;EAEI,eAAA;EACA,YAAA;EAEA,qBAAA;AAvCZ;;AA8CA;EACI,gBAAA;AA3CJ","sourcesContent":["// p{\n//     color: var(--ion-color-primary);\n// }\n\nion-label.desc-short{\n    white-space: break-spaces;\n}\n\nion-item.total-row {\n    width: 100%;\n\n    padding-left: 0px;\n\n    ion-label{\n        font-weight: bold;\n        font-size: 24px !important;\n    }\n} \n\nion-grid{\n    padding-inline: 0px;\n\n    ion-row{\n        // padding: 0px 20px;\n\n        align-items: center;\n    }\n\n    ion-col{\n\n        display: flex;\n\n        // flex-direction: column;\n\n        align-items: center;\n\n    }\n\n    ion-col.ion-col-trash{\n\n        // display: flex;\n\n        // justify-content: space-evenly;\n        \n            display: grid;\n    \n            justify-content: center;\n\n            align-items: center;\n\n    }\n\n    ion-col.ion-col-numpad{\n\n        // display: flex;\n\n        justify-content: space-evenly;\n        \n            display: flex;\n    \n            // justify-content: end;\n\n            align-items: center;\n        \n            // .container-qty{\n            \n        // }\n\n\n        h2{\n            // margin: 10px auto;\n            font-size: 24px;\n            font-weight: bold;\n        }\n\n        .btn-qty{\n\n            max-width:45px;\n            height:45px;\n\n            --border-radius: 10px;\n        }\n\n\n    }   \n}\n\ndiv.container{\n    text-align:left\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
