import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Quotation } from '../../../class/quotation.class';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-document-list',
  templateUrl: './company-document-list.component.html',
  styleUrls: ['./company-document-list.component.scss'],
})
export class CompanyDocumentListComponent  implements OnInit {

  public quotations : Array<Quotation>;

  constructor(
    private modalCtrl: ModalController,
    private router: Router) {
      
    }

  ngOnInit() {}

  modalCancel(){
    this.modalCtrl.dismiss(null, 'cancel');
  }


  selectedEvent($event : Quotation){
    // console.log("selectedEvent", $event);    
    this.router.navigate(['/quotation-wizard',$event.id],{ queryParams: { edit : false } });
    this.modalCancel();
  }

}
