import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Company } from '../class/company.class';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient) { 
    
  }


  searchByVatCode(vat_code: string) {
    
    return this.http.get<any>(`${environment.api_site}/company/search/vat/${vat_code}`);    

  }

  getAll() {
    
    return this.http.get<Array<Company>>(`${environment.api_site}/company`);    

  }

  getById(id: string) {
    
    return this.http.get<Company>(`${environment.api_site}/company/${id}`);    

  }

  insert(data: Company) {
    
    return this.http.post<Company>(`${environment.api_site}/company/`, data);    

  }

  update(data: Company) {
    
    return this.http.put<Company>(`${environment.api_site}/company/${data.id}`, data);    

  }
}
