import { Component, ChangeDetectionStrategy, Output, Input, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, FormArray, Validators } from "@angular/forms";
import { AlertController, ModalController, PopoverController } from "@ionic/angular";
import { Company } from "../../../../../class/company.class";
import { DiscountCampaign } from "../../../../../class/discount-campaign.class";
import { DiscountList } from "../../../../../class/Discount/discount-list.class";
import { Discount } from "../../../../../class/Discount/discount.class";
import { ProductAccessory } from "../../../../../class/product-accessory.class";
import { ProductPriceList } from "../../../../../class/product-price-list.class";
import { Product } from "../../../../../class/product.class";
import { ProductService } from "../../../../../services/product.service";
import { QuotationService } from "../../../../../services/quotation.service";
import { PriceHelper } from "../../../../../utility/price-helper.class";
import { ProductPriceListService } from "src/app/services/product-price-list.service";
import { TaxBreakService } from "../../../../../services/tax-break.service";
import { TaxBreak } from "../../../../../class/tax_break.class";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { AlertTextFullScreenComponent } from "../../../../modal/alert-text-full-screen/alert-text-full-screen.component";
import { MaskitoElementPredicateAsync, MaskitoOptions } from "@maskito/core";

import { maskitoNumberOptionsGenerator } from '@maskito/kit';
import { PopoverDiscountComponent } from "src/app/components/pop-over/popover-discount/popover-discount.component";
import { ModalSelectComponent } from "../../../../modal/select-item/modal-select.component";

@Component({
  selector: 'quotation-product',
  templateUrl: './quotation-product.component.html',
  styleUrls: ['./quotation-product.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuotationProductComponent {

  @Output()
  remove = new EventEmitter<FormGroup>();

  _formGroup: FormGroup;
  @Input()
  set formGroup(value: FormGroup) {
    this._formGroup = value

    // console.log("set form ", this._formGroup.controls);


    if (this._formGroup.controls?.product_id?.value) {

      this.setProduct(this._formGroup.controls?.product_id?.value)

      // this.productService.getQuotationById(this.customer.id, this._formGroup.controls?.product_id?.value).toPromise().then(data =>{
      //   this.selectedProduct = data
      // })

      // this.populateAvailableProductAccessory().then(data => {
        // console.log(data);

      // })
      // this.onProductSelect(this._formGroup.controls?.product_id?.value);
    }
  }
  get formGroup(): FormGroup {
    return this._formGroup
  }

  @Input()
  public data: Array<Product> = [];

  @Input()
  public discounts: Array<DiscountList> = [];


  @Input()
  public discountCampaing: Array<DiscountCampaign> = [];

  public max_discount : number = 0

  @Input()
  public customer: Company



  // usata per mostrare il controllo sconto
  public show_discount: boolean = false;

  // prezzo listino base
  public base_price : Number  = 0;

  public selectedProduct: Product;

  public availableProductAccessory: Array<Product> = [];

  // serve solo per il caricamento
  // private requiredAccessoryIds : Array<string> = []
  public requiredProductAccessory: Array<Product> = [];


  public taxBreakProductList: Array<TaxBreak> = []

  public ColumnMode = ColumnMode;

  get f() { return this.formGroup.controls; }

  constructor(
    private formBuilder: FormBuilder,
    private quotationService: QuotationService,
    private alertController: AlertController,
    private productService: ProductService,
    private productPriceListService: ProductPriceListService,
    private taxBreakService: TaxBreakService,
    private popoverController: PopoverController,
    private modalCtrl: ModalController) {


  }

  ngOnInit(){
  }

  async selectProduct(){

    // console.log("this.data",this.data);
    

    let dataMapped = this.data.map((x)=> ({
      text: x.description_short,
      value: x.id
    }));

    const modal = await this.modalCtrl.create({
      component: ModalSelectComponent,
      componentProps: {
        title: "Prodotti",
        items: dataMapped,
        selectedItem: this.selectedProduct?.id
      },
      
    });

    await modal.present();

    let {data} = await modal.onWillDismiss();

    let selectedId = data;

    // console.log("selectedId",selectedId);

    if(selectedId){

      this.formGroup.controls["qty"].setValue(1);

      this.onProductSelect(selectedId)

    }
    

  }
  
  async setProduct(selectedId:string){

    if(!selectedId) return;

    this.formGroup.controls['product_id'].setValue(selectedId);

    let productPriceList = this.productPriceListService.productPriceList.find(l => l.product_id === selectedId)

    if (!productPriceList) {
      console.error("prodotto senza listino");
    }

    this.selectedProduct = await this.productService.getById(selectedId).toPromise() //await this.productService.getProductById(this.customer.id, selectedId).toPromise() <-- da usare se voglio prendere l'ultimo prezzo venduto

    // console.log("productService.getQuotationById", this.selectedProduct);

    this.formGroup.controls['description'].setValue(this.selectedProduct.description_short);

    let price = productPriceList?.price || this.selectedProduct.price || 0;
    
    
    // trovo se esiste una campagna sconti
    let campaingFound = PriceHelper.GetDiscountCampaingAvaliable(this.discountCampaing, this.selectedProduct)
    
    // console.log("campaingFound", campaingFound);
    // se prezzo fisso presente in camapagna
    if (campaingFound) {
      this.max_discount = null;
    }
    
    if (campaingFound?.forfait) { // devo sottrarre il prezzo
      price = price - campaingFound.forfait;
    }
    
    if (campaingFound?.price) { // devo impostare il prezzo
      price = campaingFound.price;
    }

    // console.log('product price', price);    
    
    this.formGroup.controls['price'].setValue(price);

    // calcolo dello sconto da scontistiche listino
    this.max_discount = PriceHelper.GetMaxDiscountAvaliable(this.discounts, this.selectedProduct);

    this.show_discount = !this.selectedProduct.not_discountable;

    // console.log("this.max_discount",this.max_discount, this.selectedProduct);
    
    
    if (this.max_discount) {
      this.formGroup.controls["discount"].enable();
      this.formGroup.controls['discount'].addValidators([Validators.min(0), Validators.max(this.max_discount)])
    } else {
      this.formGroup.controls["discount"].disable();
    }

    let productBasePriceList = this.productPriceListService.productBasePriceList.find(l => l.product_id === selectedId)
    // console.log(this.productPriceListService.productBasePriceList);
    
    this.base_price = productBasePriceList?.price || 0;
    
    await this.populateAvailableProductAccessory(); 

  }




  async onProductSelect(selectedId: any) {

    // console.log($event);


    // console.log("this.formGroup", this.formGroup);


    // let selectedId = $event.detail.value;
    if (!selectedId) return
    // console.log("selectedId", selectedId);

    await this.setProduct(selectedId);  
    
    // console.log('product price', price);
    
    // verifico se esistono delle agevolazioni
    
    let taxBreakProductListAll =  this.taxBreakService.taxBreakList.filter(t => t.products.includes(selectedId));
    // console.log("taxBreakProductListAll", taxBreakProductListAll);
    
    let taxBreakProductListByRegion =  this.taxBreakService.taxBreakList.filter(t => !taxBreakProductListAll.map(r => r.id).includes(t.id) && t.products.includes(selectedId) && t.regions.includes(this.customer?.address_operational_headquarters?.region));
    // console.log("taxBreakProductListByRegion", taxBreakProductListByRegion);
    
    
    this.taxBreakProductList = taxBreakProductListAll.concat(taxBreakProductListByRegion);
    
    // this.taxBreakProductList = this.taxBreakService.taxBreakList.filter(t => t.products.includes(selectedId) && t.regions.includes(this.customer?.address_operational_headquarters?.region));
    
    if(this.taxBreakProductList.length > 0){
      
      for (const tb of this.taxBreakProductList) {
        await this.showTaxBreak(tb);
      }
      
    }
    
    this.clearAccessory();
    this.clearAccessoryRequired();

    this.checkForRequiredAccessory(this.selectedProduct.accessories_associated); 
    
  }




  async populateAvailableProductAccessory() {
    
    // console.log("populateAvailableProductAccessory", this.selectedProduct);

    if(!this.selectedProduct?.id) return;

    this.availableProductAccessory = [];

    let dataAccessories = await this.quotationService.getAvailableProductAccesory(this.selectedProduct?.id).toPromise()
    // console.log("quotationService.getAvailableProductAccesory",dataAccessories, this.selectedProduct.product_not_compatible);

    for (let p = 0; p < this.selectedProduct.accessories_associated.length; p++) {

      // aggiungo gli accessori uno per uno saltando quelli non compatibili
      for (let i = 0; i < this.selectedProduct.accessories_associated[p].product.length; i++) {


        let accessory = dataAccessories.find(a => a.id === this.selectedProduct.accessories_associated[p].product[i]?.id)

        if(!accessory) continue;

        // console.log("accessory",accessory);
        

        // riassocio l'accessorio di product con la lista caricata
        this.selectedProduct.accessories_associated[p].product[i] = accessory


        if (!this.selectedProduct?.product_not_compatible) {

          this.availableProductAccessory.push(accessory)

        } else {

          // se l'accessorio non è tra quelli da escludere lo aggiungo
          if (!this.selectedProduct.product_not_compatible.includes(accessory.id)) {

            this.availableProductAccessory.push(accessory)

          }

        }


      }


    }

    // console.log("availableProductAccessory",this.availableProductAccessory);
    

  }

  async checkForRequiredAccessory(accessory: ProductAccessory[]) {

    this.requiredProductAccessory = []
    // this.clearAccessoryRequired();


    let requiredAccessory = accessory.filter(v => v.required === true);

    // console.log("requiredAccessory", requiredAccessory);


    if (requiredAccessory.length > 0) {
      for (let a of requiredAccessory) {
        let products = a.product.filter(p => this.availableProductAccessory.some(i => i.id === p.id))
        this.requiredProductAccessory = [...products]
      }
    }

    // console.log("requiredProductAccessory", this.requiredProductAccessory);

    // aggiungo solo gli accessori richiesti non ancora inseriti

    // lista degli accessori obbligatori già aggiunt
    let accessoryRequiredAlreadyAdded: Array<any> = this.formGroup.controls?.accessories_required.value
    // console.log("accessoryRequiredAlreadyAdded", accessoryRequiredAlreadyAdded);
    let accessoryRequiredAlreadyAddedIds = accessoryRequiredAlreadyAdded.map(a => a.accessory_id)
    // console.log("accessoryRequiredAlreadyAddedIds", accessoryRequiredAlreadyAddedIds);
    
    // console.log("this.requiredProductAccessory", this.requiredProductAccessory);


    for (let prodAcc of this.requiredProductAccessory) {
      // console.log(prodAcc);

      // evito di aggiungere un accessorio richiesto se già aggiunto
      if (accessoryRequiredAlreadyAddedIds.includes(prodAcc.id)) continue;

      // if(this.availableProductAccessory.)

      // console.log("aggiungo l'accessorio richiesto");


      //let accessoryPriceList = await this.productPriceListService.getAgentProductPriceListById(p.id).toPromise();
      let accessoryPriceList = this.productPriceListService.productPriceList.find(l => l.product_id === prodAcc.id)
      // console.log("productAccPriceList", accessoryPriceList);

      //let accessoryPriceList : ProductPriceList = p.product_price_lists.find(l => l.price_list.price_list_type === 'sale')
      

      // trovo se esiste una campagna sconti
      let campaingFound = PriceHelper.GetDiscountCampaingAvaliable(this.discountCampaing, prodAcc)

      // calcolo dello sconto da scontistiche listino
      let max_discount = PriceHelper.GetMaxDiscountAvaliable(this.discounts, prodAcc);


      // prezzo default da listino
      let price = accessoryPriceList?.price || prodAcc?.price || 0;

      // console.log('accessory price 1', price, accessoryPriceList, prodAcc);


      // se prezzo fisso presente in camapagna
      // if (campaingFound) {
      //   max_discount = null;
      // }

      if (campaingFound?.forfait) { // devo sottrarre il prezzo
        
        price = price - campaingFound.forfait;
        
        max_discount = null;
      }

      if (campaingFound?.price) { // devo impostare il prezzo
        price = campaingFound.price;
        
        max_discount = null;
      }

      // console.log('accessory price 2', price);
      

      this.addAccessoryRequiredToProduct(prodAcc.id, prodAcc.description_short, 1, price, max_discount)
    }

    // this.formGroup.patchValue({

    // })


  }

  quotationAccessories(): FormArray {
    return this.formGroup.get("accessories") as FormArray
  }

  newAccessory(): FormGroup {
    return this.formBuilder.group({
      accessory_id: [null, Validators.required],
      description: [null],
      qty: [1, [Validators.required, Validators.min(1)]],
      price: [null],
      note: [null],
      discount: [null, [Validators.min(0)]],
      max_discount: [null]
    })
  }

  addAccessoryToProduct($event) {
    this.quotationAccessories().push(this.newAccessory());
  }

  clearAccessory() {
    this.quotationAccessories().clear()
  }

  async removeProduct() {

    const alert = await this.alertController.create({
      header: 'Vuoi rimuovere la riga?',
      buttons: [
        {
          text: 'ANNULLA',
          role: 'cancel',
          handler: () => {

          },
        },
        {
          text: 'ELIMINA',
          role: 'confirm',
          handler: () => {

            this.remove.emit(this.formGroup);
          },
        },
      ],
    });

    await alert.present();


  }


  // REQUIRED accessory

  quotationAccessoriesRequired(): FormArray {
    return this.formGroup.get("accessories_required") as FormArray
    // return this.formGroup.controls["accessories_required"] as FormArray
  }

  // newAccessoryRequired(id : string, description : string, qty : number = 1) : FormGroup {

  //   console.log(this.formBuilder);


  //   return this.formBuilder.group({
  //     accessory_id: [{value: id, disabled: true}, Validators.required],
  //     description: [{value: description, disabled: true}, Validators.required],
  //     qty : [{value: qty, disabled: true}, [Validators.required, Validators.min(1)]],  
  //   })

  // }

  newAccessoryRequired(id: string, description: string, qty: number = 1, price: number = 0, max_discount: number = 0): FormGroup {

    // console.log(this.formBuilder);



    let fb = this.formBuilder.group({
      accessory_id: ['', Validators.required],
      description: ['', Validators.required],
      qty: [1, [Validators.required, Validators.min(1)]],
      price: [null],
      note: [null],
      discount: [null, [Validators.min(0)]],
      max_discount: [null]
    })


    fb.controls["accessory_id"].setValue(id)
    // fb.controls["accessory_id"].disable()

    fb.controls["description"].setValue(description)
    // fb.controls["description"].disable()

    fb.controls["qty"].setValue(qty)

    fb.controls["price"].setValue(price.toString())
    // fb.controls["qty"].disable()

    if (max_discount) {
      fb.controls["discount"].enable();
      fb.controls["discount"].addValidators([Validators.max(max_discount)]);
    } else {
      fb.controls["discount"].disable();
    }

    fb.controls["max_discount"].setValue(max_discount)

    return fb

  }

  addAccessoryRequiredToProduct(id: string, description: string, qty: number = 1, price: number = 0, max_discount: number = 0) {   

    this.quotationAccessoriesRequired().push(this.newAccessoryRequired(id, description, qty, price, max_discount), { emitEvent: true });

  }



  clearAccessoryRequired() {
    this.quotationAccessoriesRequired().clear()
  }


  // END REQUIRED accessory



  async showTaxBreak($event: TaxBreak) {

    const modal = await this.modalCtrl.create({
      component: AlertTextFullScreenComponent,
      componentProps: {
        title: $event.title,
        text: $event.text
      }
    });
    modal.present();

  }

  updateQuantity(event,symbol : string) {

    let prevQty = Number(this.formGroup.controls["qty"].value) || 1

    if(symbol == '-'){

      prevQty -=1

      if(prevQty < 1) prevQty = 1

    }

    if(symbol == '+'){
      prevQty +=1
    }

    this.formGroup.controls["qty"].setValue(prevQty)

    this.updateRequiredAccessoriesQty();
  }


  updateRequiredAccessoriesQty(){

    let productQty = Number(this.formGroup.controls['qty'].value)

    // console.log("productQty",productQty);
    

    for (let i = 0; i < this.quotationAccessoriesRequired().controls.length; i++) {

      // console.log((this.quotationAccessoriesRequired().controls[i] as FormGroup));
      
      (this.quotationAccessoriesRequired().controls[i] as FormGroup)?.controls['qty'].setValue(productQty)
      
    }

  }


  updateDiscount(symbol : string) {

    let prevQty = Number(this.formGroup.controls["discount"].value) || 0

    if(symbol == '-'){

      prevQty -=1

      if(prevQty < 0) prevQty = 0

    }

    if(symbol == '+'){
      prevQty +=1
    }

    this.formGroup.controls["discount"].setValue(prevQty)

  }

  // readonly currencyMask: MaskitoOptions = maskitoNumberOptionsGenerator({
  //   decimalZeroPadding: true,
  //   precision: 2,
  //   decimalSeparator: '.',
  //   min: 0,
  //   prefix: '€',
  // });

  // readonly maskPredicate: MaskitoElementPredicateAsync = async (el) => (el as HTMLIonInputElement).getInputElement();

  get total_row_value(): number {

    let total : number = 0;

    let product : number = 0

    let product_accessories : number = 0; //l = this.formGroup.value?.price 

    for (let i = 0; i < this.quotationAccessoriesRequired().controls.length; i++) {
      
      let formAcc = this.quotationAccessoriesRequired().controls[i] as FormGroup;

      let accPrice = Number(formAcc.value?.price || 0) - ((Number(formAcc.value?.price || 0) / 100) * Number(formAcc.value?.discount || 0))

      product_accessories += accPrice * Number(formAcc.value?.qty || 1);
      
    }

    for (let i = 0; i < this.quotationAccessories().controls.length; i++) {
      
      let formAcc = this.quotationAccessories().controls[i] as FormGroup;

      let accPrice = Number(formAcc.value?.price || 0) - ((Number(formAcc.value?.price || 0) / 100) * Number(formAcc.value?.discount || 0))

      product_accessories += accPrice * Number(formAcc.value?.qty || 1);
      
    }


    // console.log(this.formGroup);
    // console.log(this.quotationAccessoriesRequired());
    

    let p_price = Number(this.formGroup.value?.price || 0) - ((Number(this.formGroup.value?.price || 0) / 100) * Number(this.formGroup.value?.discount || 0))

    let p_qty = Number(this.formGroup.value?.qty)
    
    
    
    product = p_price * p_qty
    
    // console.log(p_price, p_qty , product);

    total = product + product_accessories

    // console.log(total);
    

    if(!total) return 0

    return total
  }

  

  async showPopoverDiscount(event: Event) {

    event.stopPropagation();

    // console.log({
    //   discount : this.formGroup.controls["discount"].value || 0,
    //   max_discount : this.max_discount
    // });
    

    const popover = await this.popoverController.create({
      component: PopoverDiscountComponent,
      componentProps : {
        discount : this.formGroup.controls["discount"].value || 0,
        max_discount : this.max_discount
      },
      event: event,
      translucent: true,
    });

    await popover.present();

    const { data } = await popover.onDidDismiss();

    // console.log(data);
    // console.log(data.data?.discount);

    if(data?.data?.discount){      
      this.formGroup.controls["discount"].setValue(data.data?.discount)
    } else {
      this.formGroup.controls["discount"].setValue(0)
    }
    
  }

}
