// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `canvas {
  border: 2px solid black;
  width: 400px;
  height: 200px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/signature-pad/signature-pad.component.scss"],"names":[],"mappings":"AAEA;EACI,uBAAA;EACA,YAAA;EACA,aAAA;AADJ","sourcesContent":["\r\n\r\ncanvas {\r\n    border: 2px solid black;\r\n    width: 400px;\r\n    height: 200px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
