import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'quotation-payment-array',
  templateUrl: './quotation-payment-array.component.html',
  styleUrls: ['./quotation-payment-array.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuotationPaymentArrayComponent  implements OnInit {

  private internalFormArray: FormArray;

  @Input()
  set formArray(formArray: FormArray) {
    this.internalFormArray = formArray;

    if (this.subscriptions.formArrayChanges) {
      this.subscriptions.formArrayChanges.unsubscribe();
    }
    // this will work around the change detection on push limitations
    this.subscriptions.formArrayChanges = this.formArray.valueChanges.subscribe(
      result => {
        this.changeDetectorRef.detectChanges();
      }
    );
  }
  get formArray() {
    return this.internalFormArray;
  }

  @Output() 
  add = new EventEmitter<FormArray>();

  private subscriptions: { [key: string]: Subscription } = {};

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder) {
      
    }
  ngOnInit(): void {
    
    
  }

    ngOnDestroy() {
      Object.keys(this.subscriptions).forEach(sk =>
        this.subscriptions[sk].unsubscribe()
      );
    }
  
    remove(formGroup: FormGroup) {
      this.formArray.removeAt(this.formArray.controls.indexOf(formGroup));
    }

}
