import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Product } from '../class/product.class';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) { 

  }

  getAll() {

    let params = new HttpParams()
    
    return this.http.get<Array<Product>>(`${environment.api_site}/product`, { params });    

  }

  getById(id: string) {

    let params = new HttpParams()
    
    return this.http.get<Product>(`${environment.api_site}/product/${id}`, { params });    

  }

  
  getProductById(customer_id: string, id: string) {

    let params = new HttpParams()
    
    return this.http.get<Product>(`${environment.api_site}/product/quotation/${customer_id}/${id}`, { params });    

  }

}
