import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LocationService } from '../../../services/location.service';
import { Coordinate } from '../../../class/coordinate.class';
import { AddressService } from '../../../services/address.service';
import { Address } from '../../../class/address.class';
import { Geoposition } from '@awesome-cordova-plugins/geolocation';

@Component({
  selector: 'app-address-find-by-input',
  templateUrl: './address-find-by-input.component.html',
  styleUrls: ['./address-find-by-input.component.scss'],
})
export class AddressFindByInputComponent  implements OnInit {

  display: any;
  center: google.maps.LatLngLiteral = {
    lat: 24,
    lng: 12
  };
  zoom = 13;

  public markerOptions: google.maps.MarkerOptions = {
    draggable: false
  };
  markerPosition: google.maps.LatLngLiteral;


  public address : Address

  public address_string : string

  public foundedPlaces : Array<any> = []


  constructor(
    private modalCtrl: ModalController,
    private locationService : LocationService,
    private addressService : AddressService
    ) { }

  ngOnInit() {

    // console.log(this.address);
    

    if(this.address){
      this.address_string = `${this.address.street} ${this.address.number}, ${this.address.city} , ${this.address.postal_code}, ${this.address.nation}`
    
    }

  }

  modalCancel(){
    this.modalCtrl.dismiss(null, 'cancel');
  }


  async searchAddress($event){

    let text = $event.target.value

    // console.log("searchAddress", text);
    
    this.foundedPlaces = await this.addressService.autocompleteByAddress(text)


  }

  async setAddress(place : any){
    // console.log("setAddress", place);

    let coordinate : Coordinate = { lat : place.lat , lng : place.lng}
    let foundedAddress = await this.addressService.reverseGeocodeByCoordinate(coordinate);    

    this.address = foundedAddress

    this.center = this.address.lat_lon

    this.foundedPlaces = []
  }



  async fillAddressFiledByReverseGeocoding(){
    //addressService 

    let geoposition : Geoposition = await this.locationService.getCurrentPosition();

    let coordinate : Coordinate = {
      lat : geoposition.coords.latitude,
      lng : geoposition.coords.longitude
    }

    let foundedAddress = await this.addressService.reverseGeocodeByCoordinate(coordinate);

    

    this.address = foundedAddress

    this.center = this.address.lat_lon

    // console.log(this.address);

    //this.address_string = `${this.address.street} ${this.address.number}, ${this.address.city} , ${this.address.postal_code}, ${this.address.nation}`
    


  }


  async closeModal(){

    await this.modalCtrl.dismiss(this.address, 'address');

  }

}
