import { Address } from "./address.class";
import { Base } from "./base.class";

export class Company extends Base{

    crm_id: string;

    business_name: string | null;
    vat_code: string | null;
    fiscal_code: string | null;
    email: string | null;
    email_pec: string | null;
    phone: string | null;
        
    mobile_phone : string | null;

    description: string | null;
    website: string | null;
    sdi: string | null;


    
    address_registered_office: Address | null;
    address_operational_headquarters: Address | null;

    address_registered_office_id: string | null;
    address_operational_headquarters_id: string | null;


    contact_name: string;
    contact_surname: string;
    contact_phone: string;

    price_list_id : string;
}